import * as React from 'react';
import { useEffect, useContext } from 'react';
import HelpPage from '../help/HelpPage';
import adminHelp from '../help/pages/cpk/Admin.md'
import facilityBottlerHelp from '../help/pages/cpk/FacilityBottler.md'
import corpBottlerHelp from '../help/pages/cpk/CorpBottler.md'
import RoleContext from '../auth/RoleContext'
import { ROLES } from '../auth/role'
import RemoveLine from '../help/pages/cpk/remove-line.png'
import AddLine from '../help/pages/cpk/add-line.png'
import EnteredOnDescending from '../help/pages/cpk/entered-on-descending.png'

export default function CPKHelpPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const isFacilityBottler = roleDetails.hasRole([ROLES.BOTTLER])
  const isCorpBottler = roleDetails.hasRole([ROLES.BOTTLER_CORP])
  const isAdmin = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])

  const helpFile = isFacilityBottler ? facilityBottlerHelp : isCorpBottler ? corpBottlerHelp : isAdmin ? adminHelp : undefined
  const [markdown, setMarkdown] = React.useState('')

  useEffect(() => {
    (async () => {
      const mdResponse = await fetch(helpFile)
      const mdText = await mdResponse.text()
      setMarkdown(mdText)
    })()
  }, [])

  const processMarkdown = (markdown) => {
    return markdown
      .replace(/src="remove-line.png"/g, `src="${RemoveLine}"`)
      .replace(/src="add-line.png"/g, `src="${AddLine}"`)
      .replace(/src="entered-on-descending.png"/g, `src="${EnteredOnDescending}"`)
  }
  return (
    <HelpPage
      processMarkdown={processMarkdown}
      pageName="Process Capability Submission Help"
      markdown={markdown}
      {...props}
    />
  )
}
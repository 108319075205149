
export default {
  ALERT: 'ALERT',
  AUTH_AUTHORIZE: 'AUTH_AUTHORIZE',
  AUTH_SESSION_EXTEND: 'AUTH_SESSION_EXTEND',
  TIMER_STOPPED: 'TIMER_STOPPED',
  TIMER_STARTED: 'TIMER_STARTED',
  ON_NAVIGATE: 'ON_NAVIGATE',
  ON_INITIALIZED: 'ON_INITIALIZED',
  ON_PAGE_LOADED: 'ON_PAGE_LOADED',
  ON_PAGE_LOADING: 'ON_PAGE_LOADING'
}

import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils'
import {MaterialReactTable} from 'material-react-table'
import { Box, Grid, Paper, IconButton} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';

export default function ListManufacturerProfiles(props = {}) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  const roleDetails = useContext(RoleContext)


  const listColumns = useMemo(() => [
    {
      accessorKey: 'manufacturerId',
      header: 'Facility ID',
      size: 50,
      editable: false,
      filterVariant: 'text',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'Manufacturer.manufacturerName',
      header: 'Facility Name',
      size: 150,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'address',
      header: 'Address',
      editable: false,
      filterVariant: 'text',
      grow: true,
      Cell: ({row}) => (
        <Fragment>{row.original.Manufacturer.manufacturerAddr},&nbsp;
        {row.original.Manufacturer.manufacturerCity},&nbsp;
        {row.original.Manufacturer.manufacturerState}
        </Fragment>
      )
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      columnDefType: 'display', 
      size: 10,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem' }}>
        <Tooltip arrow placement="left" title="Edit">
          <Link to={ "/manufacturer/profile/edit/" +row.original.id }>
          <IconButton>
            <EditIcon />
          </IconButton>
          </Link>
        </Tooltip>
        </Box>
      )
    }
  ])

  const getManufacturerProfilesList = async() => {
    props.startLoading()

    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.manufacturerProfile}/search`

    const search = {}
    if (roleDetails.hasRole([ROLES.BOTTLER])) {
      search['byMyKoId'] = true
    }

    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setDataRows(results)
    }
      
    props.stopLoading()
  }

  

  useEffect(() => {
    if (isMounted()) {
      getManufacturerProfilesList()
    }
  }, [])

  

//  <AddRecordDialog title={"Add Employee User"} content={addRecordForm} open={props.showAddRecord} hideDialog={hideAddRecord} createRecord={addAssessment} buttonLoading={buttonLoading}/>
  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>            
            <MaterialReactTable
              layoutMode='grid'
              columns={listColumns}
              data={dataRows}
              enableColumnActions={false}
              enableColumnFilters={props.showFilters}
              initialState={{ showColumnFilters: true }}
              enablePagination={true}
              enableSorting={true}
              enableBottomToolbar={true}
              enableEditing={false}
              muiTableContainerProps={{ sx: { minHeight: 300 } }}
              renderTopToolbar={false}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}
import { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, IconButton, Paper, Stack, TextField } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Unstable_Grid2'
import { Link } from 'react-router-dom';
import { ApiGateway } from '../config/config.js'
import axios from 'axios'
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js';
import { getRequestConfig } from '../auth/auth.js';
import { QUALITY_FS, ENV_SAFETY, titles, SELF_ASMNT, BU_TYPES, ASMT_STATUS } from '../utils/constants'
import { useContext } from 'react';

export default function ListAssessments({ myList=false, mfrProfileId = undefined, ownershipId = undefined, startLoading = undefined, stopLoading = undefined, showFilters = false }) {
    
    const roleDetails = useContext(RoleContext)

    const getAssessmentList = async(_search = {byMyKoId: true, mfrProfileId: false, ownershipId: false}, requestConfig) => {
      startLoading()
      
      const basePath = `${ApiGateway.assessments}/search`
      if (!requestConfig) requestConfig = await getRequestConfig()
      const search = {
        byMyKoId: _search.byMyKoId,
        status: myList ? [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE, ASMT_STATUS.COMPLETED, ASMT_STATUS.FINALIZED].join(',') : undefined
      }
      if (_search.mfrProfileId) {
        search.mfrProfileId = _search.mfrProfileId
      } else if (_search.ownershipId) {
        search.ownershipId = _search.ownershipId
      }
      
      const response = await axios.post(basePath, search, requestConfig)
      const results = response.data?.results

      const rows = []

      for (let i = 0; i < results.length; i++){
        const asmnt = results[i]
        const self = asmnt.SelfAssessment
        const startDate = new Date(asmnt.startDate)

        const header = {
          id: asmnt.id,
          assessmentId: asmnt.id,
          manufacturerId: asmnt.manufacturerId,
          mfrProfileId: asmnt.ManufacturerProfile.id,
          assessmentType: BU_TYPES[asmnt.assessmentType],
          assessmentTypePath: 'bu-assessment',
          filter: asmnt.filter ? JSON.parse(asmnt.filter) : [],
          ManufacturerProfile: asmnt.ManufacturerProfile,
          qseManager: [asmnt.QSEManager.firstName, asmnt.QSEManager.lastName].join(' '),
          assessor: asmnt.BuAssessmentAssessors.map((a) => a.Users.map((u) => [u.firstName, u.lastName].join(' '))),
          startDate: startDate.toLocaleDateString(),
          status: asmnt.status,
          statusComments: asmnt.statusComments,
          selfAsmnt: {
            id: self.selfAssessmentId,
            assessmentType: titles[SELF_ASMNT],
            assessmentTypePath: SELF_ASMNT,
            assessor: self.assessor,
            startDate: self.startDate,
            status: self.status
          }
        }
        rows.push(header)
      }
      setAssessmentRows(rows)
      stopLoading()
      return
    }

    //filterVariant: 'multi-select',
    //filterSelectOptions: Object.values(ASMT_STATUS), 

    const columns = [{ 
      accessorKey: 'assessmentId', 
      header: 'ID', 
      grow: false,
      size: 125,
      editable: false,
      sx: {
        whiteSpace: 'nowrap'
      },
      enableGrouping: false,
      Cell: ({cell, row}) => 
        { if (
          roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])
          ||
          (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && ![ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE, ASMT_STATUS.CANCELED].includes(row.original.status))
          ) {
          return (
            <Link to={`/assessments/group/${cell.getValue()}`}>
              {cell.getValue()}
              {/* <Button variant="text">{cell.getValue()}</Button> */}
            </Link>
            )
          } else
            return cell.getValue()
        }
    },
    {
      accessorKey: 'ManufacturerProfile.Manufacturer.Ownership.ownershipName', 
      header: 'Ownership',
      editable: false,
      enableGrouping: false,
      size: 150,
      grow: true,
      Cell: ({ cell, row }) => {
        return row.original.ManufacturerProfile?.Manufacturer?.Ownership?.ownershipName
      }
    },
    {
      accessorKey: 'ManufacturerProfile.Manufacturer.manufacturerName', 
      header: 'Facility',
      editable: false,
      enableGrouping: false,
      size: 150,
      grow: true,
      Cell: ({ cell, row }) => {
        return row.original.ManufacturerProfile?.Manufacturer?.manufacturerName
      }
    },
    { 
      accessorKey: 'assessmentType', 
      header: 'Type', 
      filterVariant: 'multi-select',
      filterSelectOptions: ["OU Assessment", "Technical Visit"],
      size: 120, 
      grow: false,
      editable: false,
      enableGrouping: false,
      enableSorting: false
    },
    { 
      accessorKey: 'assessor',
      header: 'Assessor(s)', 
      filterVariant: 'text',
      Cell: ({cell}) => {
        return (<span style={{textTransform: 'capitalize'}}>{cell.getValue().join(', ').toLowerCase()}</span>)
      },
      grow: true,
      editable: false,
      enableGrouping: false
    },
    { 
      accessorKey: 'startDate', 
      header: 'Start Date', 
      filterVariant: 'date',
      size: 100,
      grow: false,
      editable: false,
      enableGrouping: false
    },
    { 
      accessorKey: 'status', 
      header: 'Status',
      enableFilters: false,
      enableSorting: false,
      size: 80,
      grow: false,
      editable: false,
      enableGrouping: false,
    }
  ]

    const assessmentColumns = useMemo(() => columns, [])
    const [assessmentRows, setAssessmentRows] = useState([])    

    const initializePage = async () => {
      const requestConfig = await getRequestConfig()
      const promises = []
      if (mfrProfileId) {
        promises.push(getAssessmentList({ byMyKoId: myList, mfrProfileId }, requestConfig))
      } else if (ownershipId) {
        promises.push(getAssessmentList({ byMyKoId: myList, ownershipId }, requestConfig))
      } else {
        promises.push(getAssessmentList({ byMyKoId: true }, requestConfig))
      }

      startLoading()
      await Promise.all([
        ...promises
      ])
      stopLoading()
    }

    useEffect(() => {
      initializePage()
    }, [ownershipId, mfrProfileId])
//'200px 150px 110px 40px'
    const selfAssessmentListProps = {                        
      display: 'grid',
      padding: '0 1em 0 1em',
      marginTop: '1px !important',
      gridTemplateColumns: '40px 1.5fr 1.5fr 1fr',
      float: 'right',
      alignItems: 'baseline',
      width: '100%'
    }

    const ouAssessmentListProps = {                        
      display: 'grid',
      borderTop: '1px dashed grey',
      marginTop: '1px !important',
      padding: '0 1em 0 1em',
      gridTemplateColumns: '40px 500px',
      float: 'right',
      alignItems: 'baseline',
      width: '100%'
    }

    const table = useMaterialReactTable({
      layoutMode: 'grid',
      columns: assessmentColumns,
      data: assessmentRows,
      renderTopToolbar: false,
      enableColumnFilters: showFilters,
      enableGrouping: true,
      initialState: {
        showColumnFilters: true,
        expanded: roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) ? false : {0: true}
      },
      enablePagination: false,
      enableSorting: true,
      enableBottomToolbar: true,
      enableHiding: false,
      enableColumnActions: false,
      muiTableContainerProps:{ sx: { minHeight: 300 } },
      muiDetailPanelProps: ({ row, table }) => ({
        sx: {
          display: 'grid'
        },
      }),
      displayColumnDefOptions: {'mrt-row-expand': { size: 40, grow: false } },
      renderDetailPanel: ({row}) => 
        { 
          if (row.original.status === ASMT_STATUS.CANCELED) {
          return (
            <Grid container>
              <TextField disabled multiline fullWidth value={row.original.statusComments} variant='standard'></TextField>
            </Grid>
          )}
          else return (
          <Box component='div' display={'grid'}>
            <Box component='div' justifySelf='end' >
              <Stack spacing={1} >
                <Box component='span'
                  sx={selfAssessmentListProps}
                >
                  
                  <Tooltip arrow placement="right" title="Edit">
                    <Link to={ ["/assessments/edit", row.original.selfAsmnt.assessmentTypePath, row.original.mfrProfileId, row.original.selfAsmnt.id].join('/') }>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    </Link>
                  </Tooltip>

                  <span>{titles[SELF_ASMNT]}</span>
                  <span>{row.original.selfAsmnt.assessor ? row.original.selfAsmnt.assessor : 'Not Assigned'}</span>
                  <span>{row.original.selfAsmnt.status ? row.original.selfAsmnt.status : 'Not Started'}</span>
                </Box>
              { (!row.original.filter || row.original.filter.length === 0 ||
                  row.original.filter.some((c) => c.match(/[A-M]/))) && 
                <Box component='span'
                  sx={ouAssessmentListProps}
                >
                  
                  <Tooltip arrow placement="left" title="Edit">
                    <Link to={ ["/assessments/edit", QUALITY_FS, row.original.id].join('/') }>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    </Link>
                  </Tooltip>

                  <span>{titles[QUALITY_FS]}</span>
                </Box>
                }
                { (!row.original.filter || row.original.filter.length === 0 ||
                  row.original.filter.some((c) => c.match(/[N-Z]/))) && 
                <Box component='span'
                  sx={ouAssessmentListProps}
                >
                  
                  <Tooltip arrow placement="left" title="Edit">
                    <Link to={ ["/assessments/edit", ENV_SAFETY, row.original.id].join('/') }>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    </Link>
                  </Tooltip>

                  <span>{titles[ENV_SAFETY]}</span>
                </Box>
                }

              </Stack>
              </Box>
            </Box>
          )
          
        }
      }
    )

    return (
        <>
          <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto'}}>
              <Grid>
                <MaterialReactTable table={table} />
              </Grid>
          </Paper>
        </>
    )
}
import { useEffect, useState } from 'react'
import Page from '../layout/Page'
import { useMsal } from '@azure/msal-react'
import { Grid, Typography } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getAuthenticator } from '../auth/auth'
import { AzureAdClient } from '../config/config'
import { useRole } from "../auth/role"

export default function HomePage(props = {}) {
  const { hasAnyRole } = useRole()

  const [account, setAccount] = useState(getAuthenticator().getCurrentAccount())

  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="h4" gutterBottom>NAOU QSE Portal Home</Typography></Grid>
            <Grid item xs={12}><span>Name: {account?.name || 'null'}</span></Grid>
            <Grid item xs={12}><span>Username: {account?.username || 'null'}</span></Grid>
            <Grid item xs={12}><span>Email: {account?.idTokenClaims.mail || 'null'}</span></Grid>
            <Grid item xs={12}><span>Department: {account?.idTokenClaims.department || 'N/A'}</span></Grid>
            <Grid item xs={12}><span>NAOU QSE Portal Role: {roleCtx.role?.name || 'No roles'}</span></Grid>
            {!hasAnyRole() && <Grid item xs={12}>
              <Typography variant="h6" color="error">
                <br />
                <span>You don't have any roles assigned for this site.</span>
                <br />
                <span>Please contact your Coca-Cola sponsor to request the correct level of access.</span>
              </Typography>
            </Grid>}
          </Grid>
        </Page>
      )
      }
    </RoleContext.Consumer>
  )
}
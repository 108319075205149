export const QUALITY_FS = 'quality-and-food-safety-assessments'
export const ENV_SAFETY = 'environmental-and-safety-assessments'
export const SELF_ASMNT = 'self-assessments'
  
export const titles = {
  [QUALITY_FS]: "Quality and Food Safety",
  [ENV_SAFETY]: "Environmental and Safety",
  [SELF_ASMNT]: "Self Assessment"
}

export const BU_TYPES = {
  bu: "OU Assessment",
  technical: "Technical Visit"
}

export const ASMT_STATUS = {
  NEW: 'New',
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  FINALIZED: 'Finalized',
  CANCELED: 'Canceled'
}

export const CAP_STATUS = {
  NEW: 'New',
  ACTIVE: 'Active',
  APPROVAL: 'Approval',
  COMPLETED: 'Completed'
}

export const FACILITY_TYPES = {
  producingPlant: 'Producing Plant',
  copacker: 'Co-packer',
  cps: 'CPS',
  distributionCenter: 'Distribution Center',
  rtm: 'RTM',
  repacker: 'Re-packer'
}
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { FactCheck } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'

import Grid from '@mui/material/Unstable_Grid2'
import { checkRequiredError, handleTextChange, handleSelectChange, handleFieldChange } from '../form/Form'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import Category from '../form/Category'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getRequestConfig } from '../auth/auth.js';
import ChildItemTable from './self-assessment-components/ChildItemTable.js';
import dayjs from 'dayjs';
import FileListControl from '../form/FileListControl';
import { ASMT_STATUS } from '../utils/constants';
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';

export default function EditSelfAssessmentPage (props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)
  const [isLoading, setIsLoading] = useState(true)
  const [isDirty, setIsDirty] = useState(false) 

  const [selfAssessmentId, setSelfAssessmentId] = useState(params.selfAssessmentId)
  const [selfAssessment, setSelfAssessment] = useState()
  const [fieldErrors, setFieldErrors] = useState({})

  const [assessmentFiles, setAssessmentFiles] = useState({})

  const [editMode, setEditMode] = useState(false)
  const [openAssessment, setOpenAssessment] = useState()

  const childItemTypes = {
    QC_PLAN: 'quality-control-plan',
    QUALITY_ISSUE: 'quality-issue'
  }
  const [qcPlanRows, setQcPlanRows] = useState()
  const [qualityIssuesRows, setQualityIssuesRows] = useState()

  const getSelfAssessment = async() => {
    startLoading()
    const requestConfig = await getRequestConfig()

    const isSuccess = (response) => response && response.data && response.status >= 200 && response.status < 300
    const basePath = `${ApiGateway.assessments}/self/${selfAssessmentId}`      
    const responses = await Promise.all([
      axios.get(`${basePath}`, requestConfig),
      axios.get(`${basePath}/${childItemTypes.QC_PLAN}`, requestConfig),
      axios.get(`${basePath}/${childItemTypes.QUALITY_ISSUE}`, requestConfig)
    ])
    const assessmentResponse = responses[0]
    const qcResponse = responses[1]
    const qiResponse = responses[2]

    if(isSuccess(assessmentResponse)) {
      const _selfAssessment = assessmentResponse.data.result
      loadOpenAssessment(_selfAssessment.assessmentId, requestConfig)
      setSelfAssessment(_selfAssessment)
      setQcPlanRows(qcResponse && qcResponse.data ? qcResponse.data.results : [])
      setQualityIssuesRows(qiResponse && qiResponse.data ? qiResponse.data.results : [])
      
      loadAssessmentFiles(_selfAssessment)
    } else {
      console.log(assessmentResponse)
    }

    stopLoading()
  }

  const loadOpenAssessment = async (_assessmentId, requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }

    const basePath = `${ApiGateway.assessments}/search`
    const search = { 
        assessmentId: _assessmentId,
        status: [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].join(',')
      }
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setOpenAssessment(results[0])
    }
  }

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    if (
      roleDetails.hasRole([ROLES.BOTTLER])
      && openAssessment
      ) {
        return true
      }
    return false
  }

  const saveForm = async() => {
    startLoading()

    //check errors
    let errors=[]
    if (setError(selfAssessment.startDate, "startDate"))
      errors.push("Start Date is required")
    if (setError(selfAssessment.assessor, "assessor"))
      errors.push("Assessor is required")
    if (setError(selfAssessment.status, "status"))
      errors.push("Status is required")

    

    //raise alerts
    if (errors.length > 0){
      console.log(errors.join("\n"))
      console.log("Errors ", errors.join("\n"))
      stopLoading()
      return("Fix errors")
    }

    //call service
    const requestConfig = await getRequestConfig()

    const isSuccess = (response) => response && response.data && response.status >= 200 && response.status < 300
    await axios.post(`${ApiGateway.assessments}/self/${selfAssessmentId}`, selfAssessment, requestConfig)

    if (isSuccess) {
      setClean()
      setEditMode(false)
    }
    stopLoading()
  }

  const loadAssessmentFiles = async (_selfAssessment) => {
    const requestConfig = await getRequestConfig()

    const filesPath = `${ApiGateway.assessments}/self/${(_selfAssessment ?? selfAssessment).assessmentId}/files`
      const filesResponse = await axios.get(filesPath, requestConfig)
      if (filesResponse.data) {
        setAssessmentFiles(filesResponse.data)
      }
  }

  const saveChildItem = async (itemType, item) => {
    const itemTypePath = [itemType, item.id ?? ''].join('/')
    const path = `${ApiGateway.assessments}/self/${selfAssessmentId}/${itemTypePath}`
    const requestConfig = await getRequestConfig()

    const result = await axios.post(path, item ,requestConfig)
    return result.data
  }

  const createOrUpdateChildItem = async(childItemType, item, itemListSetter) => {
    try {
      const result = await saveChildItem(childItemType, item)
      itemListSetter(prev => {
          prev = prev && Array.isArray(prev) ? prev : []
          let isNew = true
          const res = prev.reduce((p, c) => {
            if (c.id === result.childItem.id) {
              isNew = false
              p.push(result.childItem)
            } else {
              p.push(c)
            }
            return p
          }, [])
          if (isNew) res.push(result.childItem)
          
          return res
      })
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const deleteChildItem = async (childItemType, item, itemListSetter) => {
    try {
      const itemTypePath = [childItemType, item.id ?? ''].join('/')
      const path = `${ApiGateway.assessments}/self/${selfAssessmentId}/${itemTypePath}`
      const requestConfig = await getRequestConfig()
      await axios.delete(path, requestConfig)
      itemListSetter(prev => {
          prev = prev && Array.isArray(prev) ? prev : []
          const res = prev.reduce((p, c) => {
            if (c.id !== item.id) {
              p.push(c)
            }
            return p
          }, [])
          
          return res
      })
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const setError = (value, id, errorChecker=checkRequiredError, errorObject=fieldErrors, errorSetter=setFieldErrors) => {
    let error = errorChecker(value)
    errorObject[id]=error
    errorSetter({...errorObject}, {id: error})
    return error
  }

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  
  const breadcrumb = <span><FactCheck/>Self Assessment</span>

  const onSave = () => {
    console.log("EditSelfAssessmentPage onSave")
    saveForm()
  }

  const onEdit = () => {
    setEditMode(true)
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleClose()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/my-assessments`)
    } else {
      navigate(-1)
    }
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    getSelfAssessment()
  }, [])

  // if(isLoading){
  //   return (
  //     <div className="App"></div>
  //   )
  // }

  return (
    <>
      { selfAssessment && (
      <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <FormViewCommandBar 
          onSave={onSave}
          onEdit={onEdit}
          onClose={handleClose}
          onCancel={confirmCancel}
          showCancel={isDirty}
          showClose={ !isDirty}
          enableSave={isDirty}
          showSave={ editMode && isEditable() }
          showEdit={ !editMode && isEditable() }
        />
      }>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleClose} yesLabel="Discard" noLabel="Stay on Page"/>
        
            <Paper sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
              <Stack spacing={1}>
                <Category category="AssessmentDetails" title="Assessment Details" hideCollapse={true}>
                  <Box >
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid xs={12} md={6}>
                        <Stack spacing={2} direction='row'>
                          <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>                
                            <DatePicker
                              format="MM/DD/YYYY" id='startDate'
                              label="Start Date"
                              disabled={!editMode}
                              defaultValue={(selfAssessment.startDate ? dayjs(selfAssessment.startDate) : undefined)} 
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    required: true,
                                    onBlur: (event) => {
                                      
                                    }
                                  },
                                }}
                                onChange={(value) => {
                                  handleFieldChange('startDate',value, selfAssessment, setSelfAssessment, setDirty)
                                }}
                              />
                              { 
                                editMode && !selfAssessment.startDate && 
                                <FormHelperText error={true}>This field is required</FormHelperText>
                              }
                          </LocalizationProvider>
                        </div>
                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>                
                          <DatePicker
                            format="MM/DD/YYYY" id='endDate'
                            label="End Date"
                            disabled={!editMode}
                            defaultValue={selfAssessment.endDate ? dayjs(selfAssessment.endDate) : undefined} 
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  onBlur: (event) => {
                                    
                                  }
                                },
                              }}
                              onChange={(value) => {
                                handleFieldChange('endDate',value, selfAssessment, setSelfAssessment, setDirty)
                              }}
                            />
                        </LocalizationProvider>
                        </div>                    
                        </Stack>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <FormControl fullWidth error={fieldErrors.status} size='small'>
                          <InputLabel id="status-label">Status</InputLabel>
                          <Select 
                            id="status"
                            size='small'
                            labelId="status-label"
                            label="Status"
                            disabled={!editMode}
                            value={selfAssessment.status} 
                            onChange={(event) => handleSelectChange("status", event, selfAssessment, setSelfAssessment, setDirty)}
                            onClose={()=>setError(selfAssessment.status, "status")}
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Completed">Completed</MenuItem>
                          </Select>
                          <FormHelperText hidden={!fieldErrors.status}>This field is required</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <TextField className='tis-form-field'
                          id="assessor"
                          size='small'
                          value={selfAssessment.assessor}
                          onChange={(event) => handleTextChange(event, selfAssessment, setSelfAssessment, setDirty)}
                          onBlur={()=>setError(selfAssessment.assessor, "assessor")}
                          label="Assessor"
                          disabled={!editMode}
                          fullWidth
                          error={fieldErrors.assessor}
                          helperText={
                            fieldErrors.assessor ? "This field is required" : false
                          }
                        />
                      </Grid>
                    <Grid xs={12} md={6}>
                    
                    </Grid>
                    </Grid>
                  </Box>
                </Category>
                  <Category category="ProcessFlowDiagrams" title="Process Flow Diagrams" hideCollapse={true}>
                    <Box>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={12} md={12}>
                        <Typography className='tis-form-text'>A diagram which shows the major steps in your manufacturing process and identifies the points in the process where quality control parameters are measured.  Diagrams do not have to be engineering drawings, but should at least give an idea of physical line layout.  Please attach flow diagrams here:</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          { 
                            assessmentFiles.diagrams && 
                            <FileListControl
                              rowData={assessmentFiles.diagrams}
                              showLastModified={true}
                              showActions={editMode}
                              enableUpload={editMode}
                              enableDownload={true}
                              getFileUrlServiceAddr={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/files/`}
                              getFileUrlServiceQueryParams='group=diagrams'
                              getPutFileUrlServiceAddr={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/new-file-url`}
                              getPutFileUrlServiceQueryParams='group=diagrams'
                              onFileUploaded={() => loadAssessmentFiles()}
                              removeFileUrl={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/files`}
                              removeFileUrlQueryParams='group=diagrams'
                              onFileRemoved={() => loadAssessmentFiles()}
                            />
                          }
                        </Grid>
                      </Grid>
                      
                    </Box>
                  </Category>
                  <Category category="QualityControlPlanMatrix" title="Quality Control Plan/Matrix" hideCollapse={true}>
                  <Box>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid xs={12} md={12}>
                    <Typography className='tis-form-text'>A summary of all CCPs or OPRPs labeled in your process flow diagram, with the following information for each: frequency of measurement or testing, sample size, test method (provide SOP number), specification or acceptable range, and what action is taken (and by whom) if the result is unsatisfactory. Please use the table below (insert rows as necessary):</Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                      <ChildItemTable
                        columnSet='qcPlanColumns'
                        childItemsData={qcPlanRows}
                        handleSaveItem={createOrUpdateChildItem}
                        handleDeleteItem={deleteChildItem}
                        itemType={childItemTypes.QC_PLAN}
                        setChildItemsData={setQcPlanRows}
                        editMode={editMode}
                      />
                    </Grid>
                    </Grid>
                    </Box>
                  </Category>
                  <Category category="QualityIssuesCorrectiveActionProgram" title="Coca-Cola Quality Issues and Corrective Action Program" hideCollapse={true}>
                    <Box>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid xs={12} md={12}>
                    <Typography className='tis-form-text'>List each Market Action, Stock Recovery, Escalated Quality Failure, and Consumer Complaint Trends within the last 12 months.  For each, include the corrective actions taken, the status of the corrective action plan and verifications that any completed corrective actions have been effective in resolving the issue. Please use the table below (insert rows as necessary):
                    </Typography>
                    </Grid>
                    <Grid xs={12} md={12} >
                    <div className='tis-form-table'>
                      <ChildItemTable
                        columnSet='qualityIssuesColumns'
                        childItemsData={qualityIssuesRows}
                        handleSaveItem={createOrUpdateChildItem}
                        handleDeleteItem={deleteChildItem}
                        itemType={childItemTypes.QUALITY_ISSUE}
                        setChildItemsData={setQualityIssuesRows}
                        editMode={editMode}
                      />
                    </div>
                    </Grid>
                    </Grid></Box>
                  </Category>
                  <Category category="QualitySafetyEnvironmentalMetrics" title="Quality, Safety, and Environmental Metrics" hideCollapse={true}>
                    <Box>
                      <Stack spacing={2}>
                        <Typography className='tis-form-text'>Provide the facility metrics with goals and performance year to date for Quality, Safety, and Environmental. Please input the data here:</Typography>
                        <TextField className='tis-form-field'
                          fullWidth
                          label="Quality"
                          id="qualityMetrics"
                          multiline
                          minRows={2}
                          disabled={!editMode}
                          value={selfAssessment.qualityMetrics}
                          onChange={(event) => handleTextChange(event, selfAssessment, setSelfAssessment, setDirty)}
                        />
                        <TextField className='tis-form-field'
                          fullWidth
                          label="Safety"
                          id="safetyMetrics"
                          multiline
                          minRows={2}
                          disabled={!editMode}
                          value={selfAssessment.safetyMetrics}
                          onChange={(event) => handleTextChange(event, selfAssessment, setSelfAssessment, setDirty)}
                        />
                        <TextField className='tis-form-field'
                          fullWidth
                          label="Environmental"
                          id="environmentalMetrics"
                          multiline
                          minRows={2}
                          disabled={!editMode}
                          value={selfAssessment.environmentalMetrics}
                          onChange={(event) => handleTextChange(event, selfAssessment, setSelfAssessment, setDirty)}
                        />
                      </Stack>
                    </Box>
                  </Category>
                  <Category category="SupportingData" title="Supporting Data" hideCollapse={true}>
                    <Box>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={12} md={12}>
                          <Typography className='tis-form-text'>
                          Attach the capability data (Cp and Cpk) for each line.  Include the following attributes as applicable: Brix, Assay, Net Content (each package size), Torque (each cap type), CO2, TDS (Bottled Water), Ozone (Bottled Water), Other As defined by process type.  Please attach the data here or provide separately in an email to your assessor:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          { 
                            assessmentFiles.relatedFiles && 
                            <FileListControl
                              rowData={assessmentFiles.relatedFiles}
                              showLastModified={true}
                              showActions={editMode}
                              enableUpload={editMode}
                              enableDownload={true}
                              getFileUrlServiceAddr={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/files/`}
                              getFileUrlServiceQueryParams='group=relatedFiles'
                              getPutFileUrlServiceAddr={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/new-file-url`}
                              getPutFileUrlServiceQueryParams='group=relatedFiles'
                              removeFileUrl={`${ApiGateway.assessments}/self/${selfAssessment.assessmentId}/files`}
                              removeFileUrlQueryParams='group=relatedFiles'
                              onFileUploaded={() => loadAssessmentFiles()}
                              onFileRemoved={() => loadAssessmentFiles()}
                            />
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Category>
                </Stack>
            </Paper>        
      </Page>
      )}
    </>
  )
}
import { useState } from "react"
import { getRequestConfig } from "../auth/auth"
import { ApiGateway } from "../config/config"
import { useEffect } from "react"
import axios from "axios"
import { Autocomplete, FormControl, TextField } from "@mui/material"
export default function OwnershipSelectionControl({ defaultOwnershipId = undefined, onSelectOwnership = undefined, required = false }) {

  const [ownershipId, setOwnershipId] = useState(defaultOwnershipId)
  const [ownershipOptions, setOwnershipOptions] = useState([])

  const getOwnershipList = async(requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }
    
    const basePath = `${ApiGateway.ownership}/search`
    const search = { isActive: true }

    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setOwnershipOptions(results.map(r => { return {label: [r.ownershipId, r.ownershipName].join(' - '), value: r.ownershipId} }))
    }
  }

  const getOwnershipOptionLabel = (option) => {
    if (typeof option === 'string' || !option.label ) {
      const ownership = ownershipOptions.find((p) => p.value === (option.value ?? option))
      option = !ownership ? undefined : { value: ownership.value, label: ownership.label }
    }
    return !option ? undefined : option.label
  }

  const handleSelectOwnership = async (event, value) => {
    const option = value ?? event.target.value
    const _ownershipId = option?.value ?? undefined
    setOwnershipId(_ownershipId)
    if (onSelectOwnership) onSelectOwnership(_ownershipId)
  }

  useEffect(() => {
    getOwnershipList()
  }, [])

return (
  <>
    {(ownershipOptions !== undefined && ownershipOptions.length > 0) && 
      <FormControl fullWidth size='small'>
        <Autocomplete size='small' fullWidth id={`ownership_select`} 
          value={ownershipId}
          onChange={handleSelectOwnership}
          multiple={false}
          options={ownershipOptions}
          getOptionLabel={(option) => getOwnershipOptionLabel(option)}
          openOnFocus
          renderInput={(params) => <TextField
              {...params}
              required={required}
              label="Select an Ownership"
            />
          }
          >
          </Autocomplete>
      </FormControl>
    }
  </>
)
}
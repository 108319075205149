import { useMsal } from '@azure/msal-react'
import { useEffect, useState, useContext, useCallback } from 'react'
import { useParams, useLocation, useNavigate, useFetcher } from 'react-router-dom'
import { Science, Add } from '@mui/icons-material'
import { ROLES } from '../auth/role'
import axios from 'axios'
import { ApiGateway, FeatureFlags } from '../config/config'
import UserContext from '../auth/UserContext'
import RoleContext from '../auth/RoleContext'
import LineSection from './line/LineSection'
import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { Switch, TextareaAutosize, Autocomplete, Card, Checkbox, FormControl, Button, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
// import { handleFieldChange, handleSelectChange, handleTextChange } from '../form/Form'
// import qfsModels from './models/QualityAndFSModels'
// import esModels from './models/EnvironmentalAndSafetyModels'
import { getToken } from '../auth/auth'
import ConfirmDialog from '../form/ConfirmDialog'
import React, { useMemo } from 'react';

// const assessmentModels = [...qfsModels, ...esModels]

export default function CPKSubmissionFormPage(props = {}) {
  const user = useContext(UserContext)
  const roleDetails = useContext(RoleContext)
  const location = useLocation()
  const isFacilityBottler = roleDetails.hasRole([ROLES.BOTTLER])
  const isCorpBottler = roleDetails.hasRole([ROLES.BOTTLER_CORP])
  const isBottler = isFacilityBottler || isCorpBottler

  const { instance } = useMsal()
  const navigate = useNavigate()
  const params = useParams()

  const assessmentTypes = {
    BU: 'bu',
    TECHNICAL: 'technical'
  }

  const [submissionId, setSubmissionId] = useState(params.submissionId || undefined)
  const [manufacturerAcList, setManufacturerAcList] = useState([])
  const [ownershipAcList, setOwnershipAcList] = useState([])
  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [mfrProfileLabel, setMfrProfileLabel] = useState()
  const [type, setType] = useState(assessmentTypes.BU)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [manufacturerList, setManufacturerList] = useState([])
  const [ownershipList, setOwnershipList] = useState([])
  const [ownershipId, setOwnershipId] = useState(null)
  const [manufacturerId, setManufacturerId] = useState(null)

  const [newRecord, setNewRecord] = useState({
    submitter: user?.name,
    email: user?.email,
    completedOn: dayjs(new Date()),
    submissionDate: null,
    ownershipId: "",
    manufacturerId: "",
    brixLines: [{
      index: 0,
      value: null
    }],
    assayLines: [{
      index: 0,
      value: null
    }],
    tdsLines: [{
      index: 0,
      value: null
    }],
    ozoneLines: [{
      index: 0,
      value: null
    }],
    customerCreditsReturnsRate: null
  })

  const [fieldErrors, setFieldErrors] = useState({})

  const loadSubmission = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.cpk}/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {
      id: submissionId
    }

    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      search['byMyKoId'] = true
    }

    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      if (results && results.length) {
        const _newRecord = results[0]
        _newRecord.completedOn = dayjs(_newRecord.completedOn)
        _newRecord.createdOn = dayjs(_newRecord.createdOn)
        setManufacturerId(_newRecord.manufacturerId)
        setOwnershipId(_newRecord.ownershipId)
        setNewRecord(_newRecord)
      }
    }
  }

  const isBlank = (x) => {
    return x === undefined
      || x === null
      || x === ''
  }

  const notBlank = (x) => !isBlank(x)

  const basicInfoValid = useMemo(() => {
    let valid = true
    if (!newRecord.submissionDate) valid = valid && false
    if (!ownershipId) valid = valid && false
    if (!manufacturerId) valid = valid && false
    return valid
  }, [newRecord, manufacturerId, ownershipId])

  useEffect(() => {
    const validateNewForm = () => {
      let valid = true
      const lines = [
        'brixLines',
        'assayLines',
        'tdsLines',
        'ozoneLines'
      ]
      const lineValues = Array.from(
        new Set(
          lines.reduce((s, line) => {
            const vals = newRecord[line].map(l => l.value)
            s.push(...vals)
            return s
          }, [])
        )
      )
      if (!lineValues?.length && isBlank(newRecord.customerCreditsReturnsRate)) valid = valid && false
      if (lineValues?.length && lineValues.filter(isBlank)?.length === lineValues?.length && isBlank(newRecord.customerCreditsReturnsRate)) valid = valid && false
      if (!basicInfoValid) valid = false
      setIsValid(valid)
    }

    setIsDirty(true)
    validateNewForm()
  }, [newRecord, basicInfoValid])

  const setClean = () => {
    setIsDirty(false)
  }

  const formateDate = (date) => {
    if (!date) return
    if (date?.$isDayjsObject) return date?.$d.toISOString()
    if (typeof date === 'string') return date
  }

  const onSave = () => {
    (async () => {
      setIsLoading(true)
      const submission = {
        ...newRecord, ownershipId, manufacturerId,
        completedOn: formateDate(newRecord.completedOn),
        submissionDate: formateDate(newRecord.submissionDate)
      }
      console.log('submission', submission)
      const token = await getToken()
      const basePath = ApiGateway.cpk
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.idToken}`
        }
      }
      await axios.post(basePath, submission, options)
      setIsLoading(false)
      navigate(`/process-capability-submissions/list`)
    })()
  }

  const confirmCancel = () => {
    if (isDirty && !submissionId) { showDialog() }
    else handleCancel()
  }

  const handleCancel = () => {
    navigate(`/process-capability-submissions${params.mfrProfileId ? ['/', params.mfrProfileId].join('') : '/list'}`)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const breadcrumb = <div><span><Science />
    {submissionId && <>&nbsp;View&nbsp;Process Capability Submission</>}
    {/* {submissionId && !isBottler && <>&nbsp;Edit&nbsp;Process Capability Submission</>} */}
    {!submissionId && <>&nbsp;Create&nbsp;New&nbsp;Process Capability Submission</>}
  </span></div>


  const loadOwnershipOptions = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.ownership}/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {
      isActive: true
    }
    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      search['byMyKoId'] = true
    }
    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      setOwnershipList(results)
      setOwnershipAcList(results)
      if (results.length === 1) {
        setOwnershipId(results[0].ownershipId)
      }
    }
  };

  const getDefaultSubmissionDate = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.cpk}/snapshot/next-date`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }

    const response = await axios.get(basePath, options)
    if (response.data) {
      const { date } = response.data
      const nextSnapshotDate = new Date(date)
      nextSnapshotDate.setMonth(nextSnapshotDate.getMonth() - 1)
      nextSnapshotDate.setDate(1)
      const _newRecord = { ...newRecord }
      _newRecord.submissionDate = dayjs(nextSnapshotDate).startOf('month')
      setNewRecord(_newRecord)
    }
  };

  useEffect(() => {
    if (!location?.state?.page) {
      (async () => {
        setIsLoading(true)
        const promises = [
          loadOwnershipOptions(),
          getDefaultSubmissionDate()
        ]
        if (submissionId) {
          promises.push(loadSubmission())
        }
        await Promise.all(promises)
        setIsLoading(false)
      })()
    }
  }, [submissionId])

  useEffect(() => {
    const loadManufacturerOptions = async () => {
      const token = await getToken()

      const basePath = `${ApiGateway.manufacturer}/search`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.idToken}`
        }
      }
      const search = {
        ownershipId,
        isActive: true,
        processTypes: true
      }

      if (isBottler) search['byMyKoId'] = true

      const response = await axios.post(basePath, search, options)
      if (response.data) {
        const { results } = response.data
        setManufacturerList(results)
        setManufacturerAcList(results)
        if (isFacilityBottler && results.length === 1) {
          setManufacturerId(results[0]?.manufacturerId)
        }
      }
    };
    (async () => {
      const currentOwnershipId = manufacturerList.length ? manufacturerList[0]?.ownershipId : null
      if (currentOwnershipId === null || ownershipId !== currentOwnershipId) {
        setIsLoading(true)
        await Promise.all([
          loadManufacturerOptions()
        ])
        setIsLoading(false)
      }
    })()
  }, [ownershipId])

  const itemSizes = {
    mobile: 12,
    desktop: 3.75
  }
  const gridItemProps = {
    xs: itemSizes.mobile,
    sm: itemSizes.mobile,
    md: itemSizes.desktop,
    lg: itemSizes.desktop,
    xl: itemSizes.desktop
  }

  const fwGridItemProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  }

  const handleChange = (e) => {
    const _newRecord = { ...newRecord }
    const id = e.target.id || e.target.name
    _newRecord[id] = e.target.value
    setNewRecord(_newRecord)
  }

  const handleDateChange = (id, date) => {
    const _newRecord = { ...newRecord }
    _newRecord[id] = date
    setNewRecord(_newRecord)
  }

  const getMaxSubmissionDate = () => {
    // let today = dayjs(new Date('2023-11-29T15:01:01Z'))
    let today = dayjs()
    if (today.date() >= 28) {
      today = today.add(1, 'month')
    }

    return today.startOf('month')
  }

  const facility = useMemo(
    () => {
      return manufacturerAcList.find((v) => `${v.manufacturerId}` === `${manufacturerId}`) || null
    },
    [manufacturerAcList, manufacturerId]
  );

  const ownership = useMemo(
    () => {
      return ownershipAcList.find((v) => `${v.ownershipId}` === `${ownershipId}`) || null
    },
    [ownershipAcList, ownershipId]
  );

  const isSyrup = useMemo(() =>
    `${ownershipId}` === '20097-1',
    [ownershipId]
  )

  const ownershipSelected = useMemo(() =>
    ownershipId !== null && ownershipId !== undefined,
    [ownershipId]
  )

  const facilitySelected = useMemo(() =>
    manufacturerId !== null && manufacturerId !== undefined,
    [manufacturerId]
  )

  useEffect(() => {
    console.log(newRecord)
  }, [newRecord])

  useEffect(() => {
    if (location?.state?.page === "CPKFormPage") {
      setManufacturerAcList(location?.state?.manufacturerAcList)
      setOwnershipAcList(location?.state?.ownershipAcList)
      setManufacturerList(location?.state?.manufacturerList)
      setOwnershipList(location?.state?.ownershipList)
      setOwnershipId(location?.state?.ownershipId)
      setManufacturerId(location?.state?.manufacturerId)
      setNewRecord(location?.state?.newRecord)
    }
  }, [location?.state])

  const existingSubmissionEditable = useMemo(() => {
    return !isBlank(submissionId)
  })

  return (
    <>
      <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <FormViewCommandBar onSave={onSave} onCancel={confirmCancel}
          loading={isLoading}
          helpPage="/process-capability-submissions/help"
          helpPageState={{
            page: "CPKFormPage",
            backTo: params.submissionId ? `/process-capability-submissions/view/${params.submissionId}` : `/process-capability-submissions/create`,
            manufacturerAcList,
            ownershipAcList,
            manufacturerList,
            ownershipList,
            ownershipId,
            manufacturerId,
            newRecord,
          }}
          enableSave={isValid}
          showClose={false}
          showSave={!submissionId}
        />}>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page" />
        {/* <Paper sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto' }}> */}

        <Grid container columnGap={1} rowGap={1} spacing={1}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Paper style={{ padding: "1em" }}>
              Submit your Process Capability Data monthly using actual production data per NA-QFS-RQ-600.
              This form requests Capability Data for Brix, Assay/TA, TDS, & Ozone by production line.
              These are the only attributes requiring monthly submissions.
              <br />
              <br />
              <Typography variant='body2' color='text.secondary'>
                Submissions cannot be modified and there should only be one submission created per Facility per month.
                <br />
                In the event a submission must be corrected, add a new submission for the same submission month with the correct data. The latest submission for a particular month will be used and older submissions will be ignored.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
          <Grid container columnGap={1} rowGap={1} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Section 1</h2>
              <h3>Basic Information</h3>
            </Grid>

            <Grid item {...gridItemProps} >
              <FormControl fullWidth>
                {/* Data of submission */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    format="MM/DD/YYYY"
                    label="Date of data entry"
                    value={newRecord?.completedOn?.['$d'] ? dayjs(newRecord?.completedOn['$d']) : newRecord?.completedOn ? dayjs(newRecord?.completedOn) : null}
                    slotProps={{
                      textField: {
                        id: 'completedOn',
                        size: 'small',
                        onBlur: (event) => { },
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item {...gridItemProps} >
              <FormControl fullWidth>
                <TextField
                  id="submitter"
                  size='small'
                  disabled
                  value={newRecord.submitter}
                  onChange={handleChange}
                  label="Name of person submitting data"
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item {...gridItemProps} >
              <FormControl fullWidth>
                <TextField
                  id="email"
                  size='small'
                  disabled
                  value={newRecord.email}
                  onChange={handleChange}
                  label="Email of person submitting data"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item {...gridItemProps} >

              <Autocomplete
                id="ownershipId"
                size='small'
                getOptionLabel={(option) => `${option?.ownershipName}` || ""}
                filterOptions={(x) => x}
                options={ownershipAcList}
                autoComplete
                filterSelectedOptions
                value={ownership}
                disabled={isFacilityBottler || notBlank(submissionId)}
                noOptionsText="No results"
                onChange={(event, newValue) => setOwnershipId(newValue?.ownershipId)}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setOwnershipAcList(ownershipList)
                  } else {
                    const newList = ownershipAcList.filter(own => {
                      const lowerInput = (newInputValue || "").toLowerCase();
                      const lowerItem = (own.ownershipName || "").toLowerCase();
                      return lowerItem.includes(lowerInput);
                    })
                    setOwnershipAcList(newList)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={params.value || ''}
                    label="Ownership"
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                          <Typography variant="body2" color="text.secondary">
                            {option?.ownershipName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </Grid>

            <Grid item {...gridItemProps} >
              <Autocomplete
                id="facility"
                size='small'
                getOptionLabel={(option) => `${option?.manufacturerName}` || ""}
                filterOptions={(x) => x}
                options={manufacturerAcList}
                autoComplete
                filterSelectedOptions
                value={facility}
                disabled={notBlank(submissionId) || (isBlank(submissionId) && isFacilityBottler) || isBlank(ownershipId)}
                noOptionsText="No results"
                onChange={(event, newValue) => {
                  setManufacturerId(newValue?.manufacturerId)
                  const _newRecord = {...newRecord}
                  _newRecord.waterProduced = newValue?.processTypes?.includes(3)
                  setNewRecord(_newRecord)
                }}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setManufacturerAcList(manufacturerList)
                  } else {
                    const newMfrList = manufacturerList.filter(mfr => {
                      const lowerInput = (newInputValue || "").toLowerCase();
                      const lowerItem = (mfr.manufacturerName || "").toLowerCase();
                      return lowerItem.includes(lowerInput);
                    })
                    setManufacturerAcList(newMfrList)
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} value={params.value || ''} label="Facility" fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                          <Typography variant="body2" color="text.secondary">
                            {option?.manufacturerName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </Grid>

            <Grid item {...gridItemProps} >
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={notBlank(submissionId)}
                    maxDate={getMaxSubmissionDate()}
                    views={['year', 'month']}
                    format="MM/YYYY" id='submissionDate'
                    label="Data submitted for the Month/Year of"
                    // value={newRecord?.submissionDate?.['$d'] ? newRecord?.submissionDate : dayjs((newRecord?.submissionDate || null) || newRecord?.submissionDate?.['$d'] || null)}
                    // defaultValue={newRecord?.submissionDate?.['$d'] ? newRecord?.submissionDate : dayjs((newRecord?.submissionDate || null) || newRecord?.submissionDate?.['$d'] || null)}
                    value={newRecord?.submissionDate?.['$d'] ? dayjs(newRecord?.submissionDate['$d']) : newRecord?.submissionDate ? dayjs(newRecord?.submissionDate) : null}
                    // defaultValue={newRecord?.submissionDate?.['$d'] ? dayjs(newRecord?.submissionDate['$d']) : newRecord?.submissionDate ? dayjs(newRecord?.submissionDate) : null}
                    onChange={(date) => handleDateChange('submissionDate', date)}
                    slotProps={{
                      textField: {
                        id: 'submissionDate',
                        size: 'small',
                        onBlur: (event) => { },
                        onChange: (date) => handleDateChange('submissionDate', date)
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            {!isSyrup && FeatureFlags.cpkBottledWater && <Grid item {...gridItemProps} >
              <FormControl fullWidth size='small'>
                <FormControlLabel control={
                  <Switch
                    value={newRecord.waterProduced}
                    checked={newRecord.waterProduced}
                    onChange={() => {
                      const _newRecord = { ...newRecord }
                      _newRecord.tdsLines = [{index: 0}]
                      _newRecord.ozoneLines = [{index: 0}]
                      _newRecord.waterProduced = !newRecord.waterProduced
                      setNewRecord(_newRecord)
                    }}
                  />
                } label="Bottled water products were produced during the submission period" />
              </FormControl>
            </Grid>}

          </Grid>
        </Paper>

        {basicInfoValid && <LineSection
          disabled={notBlank(submissionId)}
          newRecord={newRecord}
          setNewRecord={setNewRecord}
          fwGridItemProps={fwGridItemProps}
          handleChange={handleChange}
          lineName='brix'
          section={2}
          instructions={<>
            <h3>Nutritive Products</h3>
            <ul>
              <li>Select Coca-Cola for CSD facilities. For all other facilities select product of highest production per line</li>
              <li>For products that are filled full strength such as juices and syrup, use the release values</li>
              <li>Enter the data for each month.</li>
              <li>Only enter the average of the month you are submitting for each line.</li>
            </ul>
            When calculating the Cpk, use the specification range per the MMI
          </>}
        />}

        {basicInfoValid && <LineSection
          disabled={notBlank(submissionId)}
          newRecord={newRecord}
          setNewRecord={setNewRecord}
          fwGridItemProps={fwGridItemProps}
          handleChange={handleChange}
          lineName="assay"
          section={3}
          instructions={<>
            <h3>Diet Beverage Assay</h3>
            <ul>
              <li>Select Diet Coke or Coke Zero for CSD facilities - - - Use +/- 1.5% range for calculating Cpk of Assay (this is for calculating assay Cpk and does not replace the specification ranges per the MMI for production)</li>
              <li>For all other facilities select product of highest production per line</li>
              <li>Results must be reported to at least 3 significant digits.</li>
              <li>For thermal lines use Total Titratable Acidity with the specification range per the MMI</li>
              <li>Enter the data for each month. </li>
              <li>Only enter the average of the month you are submitting for each line.</li>
            </ul>
          </>}
        />}

        {!isSyrup && basicInfoValid && (FeatureFlags.cpkBottledWater ? newRecord.waterProduced : true) && <LineSection
          disabled={notBlank(submissionId)}
          newRecord={newRecord}
          setNewRecord={setNewRecord}
          fwGridItemProps={fwGridItemProps}
          handleChange={handleChange}
          lineName="tds"
          section={4}
          instructions={<>
            <h3>Bottled Water lines</h3>
            calculate using specification ranges per the MMI
            <ul>
              <li>Enter the data for each month.</li>
              <li>Only enter the average of the month you are submitting for each line.</li>
            </ul>
          </>}
        />}

        {!isSyrup && basicInfoValid && (FeatureFlags.cpkBottledWater ? newRecord.waterProduced : true) && <LineSection
          disabled={notBlank(submissionId)}
          newRecord={newRecord}
          setNewRecord={setNewRecord}
          fwGridItemProps={fwGridItemProps}
          handleChange={handleChange}
          lineName='ozone'
          section={5}
          instructions={<>
            <h3>Bottled Water lines</h3>
            calculate using specification ranges per the MMI
            <ul>
              <li>Enter the data for each month.</li>
              <li>Only enter the average of the month you are submitting for each line.</li>
            </ul>
          </>}
        />}

        {isSyrup && basicInfoValid && <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
          <Grid container columnGap={1} rowGap={1} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Section 2</h2>
              <h3>Customer Credits and Returns</h3>
            </Grid>

            <Grid item {...fwGridItemProps} >
              <FormControl fullWidth>
                <TextField
                  id="customerCreditsReturnsRate"
                  size='medium'
                  disabled={notBlank(submissionId)}
                  value={newRecord.customerCreditsReturnsRate}
                  onChange={handleChange}
                  label="Monthly Customer Credits and Returns rate"
                  fullWidth
                />
              </FormControl>
            </Grid>

          </Grid>
        </Paper>}

      </Page >
    </>
  )

}
import { default as React, useState } from 'react'
import { Factory } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useLocation, useNavigate } from 'react-router-dom'
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, Paper, TextField, Typography } from '@mui/material'
import { handleFieldChange } from '../form/Form'
import { useEffect } from 'react'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { FACILITY_TYPES } from '../utils/constants'


export default function CreateManufacturerProfilePage(props = {}) {
  let navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [facilityTypeFilters, setFacilityTypeFilters] = useState({
    copacker: true,
    cps: true,
    distributionCenter: true,
    producingPlant: true,
    repacker: true,
    rtm: false
  })

  const { copacker, cps, distributionCenter, producingPlant, repacker, rtm } = facilityTypeFilters

  const [manufacturer, setManufacturer] = useState({
    manufacturerId: undefined,
    manufacturerName: undefined
  })

  useEffect(() => {
    let valid = false
    if (manufacturer.manufacturerId) {
      valid = true
    }
    setIsValid(valid)
  }, [manufacturer])

  const [manufacturerOptions, setManufacturerOptions] = useState()

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const breadcrumb = <div><span><Factory />&nbsp;Create&nbsp;Manufacturer&nbsp;Profile</span></div>

  const onSave = async () => {
    console.log("CreateManufacturerProfilePage onSave")

    if (isValid) {
      startLoading()
      const basePath = `${ApiGateway.manufacturerProfile}`
      const requestConfig = await getRequestConfig()
      try {
        const response = await axios.post(basePath, manufacturer, requestConfig)
        const { manufacturerProfile } = response.data
        if (manufacturerProfile) {
          navigate(`/manufacturer/profile/edit/${manufacturerProfile.id}`, { replace: true })
        }

      } catch (error) {
        saveUnsuccessfulError = error
      }
      stopLoading()
    }
  }

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save user due to ${saveUnsuccessfulError}, do you want to try again?`
  }

  const confirmCancel = () => {
    console.log("Cancel")
    if (isDirty) { showDialog() }
    else handleClose()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/manufacturer/profile/list`)
    } else {
      navigate(-1)
    }
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const filterChange = (event) => {
    const newFilters = {
      ...facilityTypeFilters,
      [event.target.name]: event.target.checked
    }
    setFacilityTypeFilters(newFilters)
    loadManufacturerOptions(newFilters)
  }

  const handleSelectManufacturer = async (event, value) => {
    const mfr = value ?? event.target.value
    const _mfrId = mfr?.value ?? undefined
    handleFieldChange("manufacturerId", _mfrId, manufacturer, setManufacturer, setDirty)
  }

  const loadManufacturerOptions = async (_facilityTypeFilters) => {
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.manufacturer}/search`
    const filters = _facilityTypeFilters ?? facilityTypeFilters
    const types = Object.keys(filters).reduce((p, filter) => {
      if (filters[filter] === true) {
        p.push(FACILITY_TYPES[filter])
      }
      return p
    }, [])
    const search = {
      noActiveProfile: true,
      isActive: true,
      type: types.join(',')
    }
    try {
      const response = await axios.post(basePath, search, requestConfig)
      const { results } = response.data

      setManufacturerOptions(results.map((r) => { return { label: [r.manufacturerId, r.manufacturerName].join(' - '), value: r.manufacturerId } }))
    } catch (error) {
      console.error(error)
    }
  }

  const getManufacturerOptionLabel = (option, mfrList) => {
    if (typeof option === 'string' || !option.label) {
      const mfr = (mfrList ?? manufacturerOptions).find((p) => p.id === (option.id ?? option))
      option = !mfr ? undefined : { value: mfr.id, label: [mfr.manufacturerId, mfr.manufacturerName ?? ''].join(' - ') }
    }
    return !option ? undefined : option.label
  }

  useEffect(() => {
    loadManufacturerOptions().then(
      stopLoading()
    )
  }, [])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty && isValid} showClose={false} />
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleClose} yesLabel="Discard" noLabel="Stay on Page" />
      <Paper sx={{ border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '.5em'}}>
      <Grid container>
        <Grid item sm={6} md={6}>
          {manufacturerOptions && <FormControl fullWidth size='small' >
            <Autocomplete
              // isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
              multiple={false}
              id="manufacturer_autocomplete"
              size='small'
              fullWidth
              options={manufacturerOptions}
              getOptionLabel={getManufacturerOptionLabel}
              value={manufacturer.manufacturerId}
              openOnFocus
              onChange={handleSelectManufacturer}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Find/Select Manufacturer"
                />
              )}
            />
            {
              !manufacturer.manufacturerId &&
              <FormHelperText error={true}>This field is required</FormHelperText>
            }
          </FormControl>}
        </Grid>
        <Grid item sm={6} md={6} paddingLeft={1}>
        <Typography variant='subtitle1'>Filter selections by type:</Typography>
          <FormControl component='fieldset' fullWidth>            
            <FormGroup row>
              <FormControlLabel control={
                <Checkbox size='small' checked={copacker} onChange={filterChange} name="copacker" />
              } label={FACILITY_TYPES.copacker} />
              <FormControlLabel control={
                <Checkbox size='small' checked={cps} onChange={filterChange} name="cps" />
              } label={FACILITY_TYPES.cps} />
              <FormControlLabel control={
                <Checkbox size='small' checked={distributionCenter} onChange={filterChange} name="distributionCenter" />
              } label={FACILITY_TYPES.distributionCenter} />
              <FormControlLabel control={
                <Checkbox size='small' checked={producingPlant} onChange={filterChange} name="producingPlant" />
              } label={FACILITY_TYPES.producingPlant} />
              <FormControlLabel control={
                <Checkbox size='small' checked={repacker} onChange={filterChange} name="repacker" />
              } label={FACILITY_TYPES.repacker} />
              <FormControlLabel control={
                <Checkbox size='small' checked={rtm} onChange={filterChange} name="rtm" />
              } label={FACILITY_TYPES.rtm} />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      </Paper>
    </Page>
  )
}


import { Skeleton, TextareaAutosize, Autocomplete, Card, Box, Checkbox, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChart from '../charts/BarChart'
import Bar from '../charts/Bar'

const getProgressClass = (val) => {
    if (!val) return 'progress-low'
    if (val < 33.3) return 'progress-low'
    if (val === 100) return 'progress-high'
    return 'progress-med'
}

export default function FormCard(props = {}) {
    const { 
        completedFields = 0, 
        totalFields = 0, 
        showProgress = true, 
        title,
        rowGap = 1,
        spacing = 1,
    } = props
    const progressPercentage = totalFields ? (completedFields / totalFields) * 100 : 0
    const { summaryStyle = {}, detailsStyle = {} } = props
    return <Accordion defaultExpanded={props.defaultExpanded} style={{ margin: 10 }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={summaryStyle}
        >
            {(title || showProgress) && <Grid container>
                {title && <Grid item xs={10}>
                    <h3 style={{ margin: 4 }}>{props.title}</h3>
                </Grid>}
                {showProgress && <Grid item xs={2}>
                    <BarChart style={{ marginTop: 8, marginRight: 10 }} slim>
                        <Bar value={progressPercentage || 0} className={getProgressClass(progressPercentage)} />
                    </BarChart>
                </Grid>}
            </Grid>}

        </AccordionSummary>
        <AccordionDetails
            sx={detailsStyle}
        >
            <Grid container columnGap={1} rowGap={rowGap} spacing={spacing}>
                {props.children}
            </Grid>
        </AccordionDetails>
    </Accordion>
}

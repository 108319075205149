import { useState } from "react"
import { getRequestConfig } from "../auth/auth"
import { ApiGateway } from "../config/config"
import { useContext } from 'react'
import { ROLES } from "../auth/role"
import { useEffect } from "react"
import axios from "axios"
import { Skeleton, Autocomplete, FormControl, TextField } from "@mui/material"
import RoleContext from "../auth/RoleContext"

export default function ProfileSelectionControl({
  defaultMfrProfileId = undefined,
  forOwnershipId = undefined,
  onSelectProfile = undefined,
  required = false,
  searchParams = undefined }) {
  const roleDetails = useContext(RoleContext)

  const [mfrProfileId, setMfrProfileId] = useState(defaultMfrProfileId)
  const [manufacturerProfileList, setManufacturerProfileList] = useState()
  const [manufacturerOptions, setManufacturerOptions] = useState([])

  const getManufacturerProfilesList = async (requestConfig) => {
    if (!requestConfig) {
      requestConfig = await getRequestConfig()
    }

    const basePath = `${ApiGateway.manufacturerProfile}/search`
    const search = { ...searchParams }
    if (forOwnershipId) {
      search.ownershipId = forOwnershipId
    }

    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      if (results.length && roleDetails.hasRole([ROLES.BOTTLER])) {
        if (!mfrProfileId) {
          const _mfrProfileId = results[0].id
          setMfrProfileId(_mfrProfileId)
          if (onSelectProfile) onSelectProfile(_mfrProfileId)
        }
      }
      setManufacturerProfileList(results)
      setManufacturerOptions(results.map((q) => { return { label: getManufacturerOptionLabel(q, results), value: q.id } }))
    }
  }

  const getManufacturerOptionLabel = (option, profileList) => {
    if (typeof option === 'string' || !option.label) {
      const profile = (profileList ?? manufacturerProfileList).find((p) => p.id === (option.id ?? option))
      option = !profile ? undefined : { value: profile.id, label: [profile.manufacturerId, profile.Manufacturer?.manufacturerName ?? ''].join(' - ') }
    }
    return !option ? undefined : option.label
  }

  const handleSelectManufacturer = async (event, value) => {
    const profile = value ?? event.target.value
    const _mfrProfileId = profile?.value ?? undefined
    if (onSelectProfile && onSelectProfile.length === 1) onSelectProfile(_mfrProfileId)
    if (onSelectProfile && onSelectProfile.length === 2)
      onSelectProfile(
        _mfrProfileId,
        manufacturerProfileList.find(mp => mp.id === _mfrProfileId) || {}
      )
  }

  useEffect(() => {
    getManufacturerProfilesList()
  }, [forOwnershipId])

  return (
    <>
      {!manufacturerProfileList && <Skeleton sx={{ height: '100%'}} />}
      {manufacturerProfileList && 
      <FormControl fullWidth size='small'>
        <Autocomplete size='small' fullWidth id={`manufacturer_select`} 
          value={mfrProfileId}
          onChange={handleSelectManufacturer}
          multiple={false}
          options={manufacturerOptions}
          getOptionLabel={(option) => getManufacturerOptionLabel(option)}
          openOnFocus
          renderInput={(params) => <TextField
              {...params}
              required={required}
              label="Select a Manufacturer"
            />
          }
          >
          </Autocomplete>
      </FormControl>
    }
    </>
  )
}
import { default as React, useEffect, useState, useRef, useMemo, useContext, createRef } from 'react'
import { renderToString } from 'react-dom/server'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { TaskAlt, TextDecrease, HistoryEdu } from '@mui/icons-material'
import { TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Grid, Paper, IconButton, FormControl, FormLabel, Radio, FormControlLabel, RadioGroup, FormElement, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import AuthorizationPreviewCommandBar from '../commandBar/AuthorizationPreviewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import AuthorizationDetailCard from './AuthorizationDetailCard'
import AuthorizationApprovalSection from './AuthorizationApprovalSection'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import cocaColaNaB64 from './CocaColaLogoB64.js'
import { fontFamily, fontSize, fontStyle, fontWeight, lineHeight } from '@mui/system'
import UserContext from '../auth/UserContext'
import { LetterTypes, SIGNATURE_ATTESTATION, approvalStatuses, toFormattedDate } from './common.js'
import { toPascalCase } from '../utils/TextUtils.js'
import AuthorizationLetterTemplate from './AuthorizationLetterTemplate.js'
import { styles } from './AuthorizationLetterTemplate.js'

export default function AuthorizationPreviewPage(props = {}) {
  const params = useParams()
  const userDetails = useContext(UserContext)
  const { firstName, lastName } = userDetails?.userProfile || {}

  const { authorizationId } = params

  const [isLoading, setIsLoading] = useState(true)
  const [mfrProfile, setMfrProfile] = useState({})
  const [authorization, setAuthorization] = useState({})
  const [approvalCategories, setApprovalCategories] = useState([])
  const [signature, setSignature] = useState('')
  const [signed, setSigned] = useState(false)
  const [date, setDate] = useState(toFormattedDate(new Date()))
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false)
  const [signatureError, setSignatureError] = useState('')
  const [signatureStyle, setSignatureStyle] = useState('barokah')
  const [templateData, setTemplateData] = useState([])
  const [templateRefs, setTemplateRefs] = useState({})

  const letterRef = useRef()
  const headerRef = useRef()
  const bodyRef = useRef()
  const footerRef = useRef()

  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  const navigate = useNavigate()

  useEffect(() => {
    var body = document.body;
    if (body.className.indexOf('sidebar-collapse') === -1) {
      body.className += ' sidebar-collapse collapsed-by-preview';
    }

    const loadTemplates = async () => {
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorizations}/letter/templates`
      const response = await axios.get(basePath, requestConfig)
      const { results } = response.data
      setTemplateData(results)
      const templateRefs = templateData.reduce((s, field) => {
        s[field.id] = createRef()
        return s
      }, {})
      setTemplateRefs(templateRefs)
    }

    (async () => {
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorization}/approval-categories`
      const { data } = await axios.get(basePath, requestConfig)
      const { results } = data
      setApprovalCategories(results)
    })();

    (async () => {
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorizations}/${authorizationId}`
      const { data } = await axios.get(basePath, requestConfig)
      const { result } = data
      if (result?.id) {
        setAuthorization(result)
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.manufacturerProfile}/search`
        const search = { manufacturerId: result?.manufacturerId }
        const { data } = await axios.post(basePath, search, requestConfig)
        const { results } = data
        if (results?.length) {
          setMfrProfile(results[0])
        }
      }
      await loadTemplates()
      setIsLoading(false)
    })();

  }, [authorizationId])

  const breadcrumb = <span><TaskAlt />&nbsp;Facility Authorization Preview</span>

  const onSign = useMemo(() => {
    return () => {
      const { firstName, lastName } = userDetails?.userProfile || {}

      if (signature !== `${firstName} ${lastName}`) {
        setSignatureError('Signature does not match the name in your profile.')
        return
      }

      setSigned(true)
      setSignatureDialogOpen(false)
    }
  }, [userDetails, signature])

  const onPublish = useMemo(() => {
    return async () => {
      const body = bodyRef.current.innerHTML
      const header = headerRef.current.innerHTML
      const footer = footerRef.current.innerHTML

      startLoading()
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorizations}/${authorizationId}/publish`
      const publishDetails = {
        signatureName: signature,
        attestation: SIGNATURE_ATTESTATION,
        date,
        html: {
          body,
          header,
          footer,
        },
      }
      const response = await axios.post(basePath, publishDetails, requestConfig)
      stopLoading()
      navigate(`/facility-authorizations/${authorizationId}`)

    }
  }, [userDetails, signature, letterRef, headerRef, bodyRef, footerRef])

  const signatureStyles = [
    {
      name: 'Style 1',
      value: 'barokah',
    },
    {
      name: 'Style 2',
      value: 'famulred',
    },
    {
      name: 'Style 3',
      value: 'pwmanuel',
    }
  ]

  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
          commandBar={
            <AuthorizationPreviewCommandBar
              loading={isLoading}
              onBack={() => navigate(`/facility-authorizations/${authorizationId}/edit`)}
              onSign={() => setSignatureDialogOpen(true)}
              onPublish={onPublish}
              signature={signature}
              signed={signed}
            />}>

          <Dialog
            open={signatureDialogOpen}
            onClose={() => setSignatureDialogOpen(false)}
          >
            <DialogTitle>Sign Letter</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DialogContentText>
                    Please type your name as it appears below to sign.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={signatureError !== ''}
                    helperText={signatureError}
                    size='small'
                    label=''
                    value={signature}
                    placeholder={`${firstName} ${lastName}`}
                    onChange={e => {
                      if (signatureError !== '') setSignatureError('')
                      setSignature(e.target.value)
                    }
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <div style={{
                    height: '40px',
                    width: '100%',
                    borderBottom: '1px dashed gray',
                    display: 'flex',
                    alignItems: 'flex-end'
                  }}>
                    X <span style={styles.signature[signatureStyle]}>{signature}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size='small'>
                    <InputLabel >Signature Style</InputLabel>
                    <Select
                      size='small'
                      fullWidth
                      defaultValue='barokah'
                      value={signatureStyle}
                      onChange={e => setSignatureStyle(e.target.value)}
                    >
                      {signatureStyles.map(item =>
                        <MenuItem key={item.value} value={item.value}>{item.name} {item.preview}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <DialogContentText>
                  {SIGNATURE_ATTESTATION}
                </DialogContentText>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => {
                setSignatureDialogOpen(false)
                setSignature('')
              }}>Cancel</Button>
              <Button variant="contained" onClick={onSign} endIcon={<HistoryEdu />}>Sign</Button>
            </DialogActions>
          </Dialog>

          {!isLoading &&
            <AuthorizationLetterTemplate
              letterRef={letterRef}
              headerRef={headerRef}
              bodyRef={bodyRef}
              footerRef={footerRef}
              signatureStyle={signatureStyle}
              onClickSign={() => setSignatureDialogOpen(true)}
              authorization={authorization}
              mfrProfile={mfrProfile}
              approvalCategories={approvalCategories}
              date={date}
              signed={signed}
              templateData={templateData}
              templateRefs={templateRefs}
            />
          }

        </Page>
      )}
    </RoleContext.Consumer>
  )
}
import { Box, Button, Stack, Link } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { MaterialReactTable } from "material-react-table";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { AddRecordModal, addRow, handleSaveRowEdits, checkColumnError, EditRecordModal } from "../../form/Form";
import { useMemo, useState, useCallback } from "react";
import ConfirmDialog from "../../form/ConfirmDialog";

const filterOptions = createFilterOptions()

export default function ChildItemTable (props = {
  columnSet: undefined, 
  childItemsData: [], 
  handleSaveItem: undefined,
  handleDeleteItem: undefined,
  itemType: '',
  setChildItemsData: undefined,
  editMode: false}) {

  const [tempRow, setTempRow] = useState({})
  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [validationErrors, setValidationErrors] = useState({})
  const [deleteDialog, setDeleteDialog] = useState(false)

  const hideDeleteDialog = () => {
    setTempRow(undefined)
    setDeleteDialog(false)
  }

  const handleConfirmDelete = async () => {
    await props.handleDeleteItem(props.itemType, tempRow, props.setChildItemsData)    
    hideDeleteDialog()
  }


  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        variant: "outlined",
        onBlur: (event) => { checkColumnError(cell.id, event.target.value, validationErrors, setValidationErrors)
        },
      };
    },
    [validationErrors],
  )

  const editRow = (row) => {
    console.log(row.original)
    setTempRow(row.original)
    setShowEditPanel(true)
  }

  const deleteRow = (row) => {
    setTempRow(row.original)
    setDeleteDialog(true)
  }

  const editColumn = { id: 'edit', size: 80, header: 'Actions', columnDefType: 'display', Cell: (
    ({ cell, row }) => (
    <Stack direction='row' spacing={2}>
      <Link onClick={() => editRow(row)}><EditIcon /></Link>
      <Link onClick={() => deleteRow(row)}><DeleteIcon /></Link>
    </Stack>
    )
    )
  }

  const columnSets = {
    qcPlanColumns: useMemo(() => { 
      const set = [
      {accessorKey: 'controlPointType', header: 'Control Point Type', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'frequency', header: 'Frequency', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'sampleSize', header: 'Sample Size', size: 60, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'testMethod', header: 'Test Method (SOP #)', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'specOrAcceptableRange', header: 'Spec or Acceptable Range', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'actionPlan', header: 'Action Plan', size: 160, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})}
      ]
      if (props.editMode) set.push(editColumn)
      return set
    }),
    qualityIssuesColumns: useMemo(() => { 
      const set = [
      {accessorKey: 'date', header: 'Date', size: 80, editable: true, customType: "Date", Cell: ({cell}) => { const v = cell.getValue(); if (v.toString() !== '') return new Date(v).toLocaleDateString(undefined, {month: '2-digit', day: '2-digit', year: 'numeric' }); return cell.getValue() } },
      {accessorKey: 'customer', header: 'Customer', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'issue', header: 'Issue', size: 60, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'correctiveAction', header: 'Corrective Action', size: 80, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'verification', header: 'Verification', size: 160, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})},
      {accessorKey: 'status', header: 'Status', size: 220, editable: true, muiTableBodyCellEditTextFieldProps: ({ cell }) => ({...getCommonEditTextFieldProps(cell)})}
      ]
      if (props.editMode) set.push(editColumn)
      return set
    })
  }

  const overrideColumnsBySet = {
    qcPlanColumns: [],
    qualityIssuesColumns: []
  }

  return (
    <div className='tis-form-table'>
      {deleteDialog && <ConfirmDialog title="Delete this item?" text="This action is immediate and cannot be undone. Do you want to delete this item?" 
        open={deleteDialog} 
        hideDialog={hideDeleteDialog} 
        handleConfirm={handleConfirmDelete}
        yesLabel="Delete"
        yesButtonStyle={{backgroundColor: 'red', color: 'white', fontWeight: 'bold'}}
        yesButtonVariant='filled'
        noLabel="Keep"
        noButtonStyle={{backgroundColor: 'green', color: 'white', fontWeight: 'bold'}}
        noButtonVariant='filled'
        />}
      <MaterialReactTable
        columns={columnSets[props.columnSet]}
        data={props.childItemsData || []}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableEditing={false}
        muiTableContainerProps={{ sx: { minHeight: 300, maxHeight: 400} }}
        
        renderTopToolbar={({ table }) => <Box>
          { props.editMode &&
          <Button color="primary" size="medium" startIcon={<AddIcon />} 
            onClick={() => setShowCreatePanel(true)} 
          >
            Add record
          </Button>
          }
        </Box>}
      />
      { props.editMode && showCreatePanel && 
        <AddRecordModal
          columns={columnSets[props.columnSet]}
          open={!!showCreatePanel}
          onClose={() => setShowCreatePanel(false)}
          onSubmit={(newItem) => props.handleSaveItem(props.itemType, newItem, props.setChildItemsData)}
          addRow={addRow}
          rowSetter={props.setChildItemsData}
          setDirty={props.setDirty}
        />
      }
      { props.editMode && showEditPanel &&
        <EditRecordModal
          columns={columnSets[props.columnSet]}
          open={!!showEditPanel}
          getCurrentValues={() => tempRow}
          onClose={() => setShowEditPanel(false)}
          onSubmit={(editItem) => props.handleSaveItem(props.itemType, editItem, props.setChildItemsData)}
          setDirty={props.setDirty}
        />
      }
    </div>
  )
}
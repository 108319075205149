import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ChartJsPieChart(props={title: undefined, labelsArray: [], dataArray: [], backgroundColorsArray: []}) {

  const data = {
    labels: props.labelsArray,
    datasets: [
      {
        label: props.title,
        data: props.dataArray,
        backgroundColor: props.backgroundColorsArray,
        borderColor: props.backgroundColorsArray,
        borderWidth: 1,
      },
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'left',
        labels: {
          padding: 5
        }
      }
    }
  }

  return (
    <Pie data={data} options={options}/>
  )
}
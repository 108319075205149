import Grid from '@mui/material/Unstable_Grid2'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { ASSESSMENT_TYPE_LABELS, CAP_STATUSES, CAP_STATUS_LABELS, ITEM_PROPS, RATINGS, RATING_LABELS } from '../../form/formUtils'
import { handleFieldChange } from '../../form/Form';
import { getRequestConfig } from '../../auth/auth';
import { ROLES } from '../../auth/role'
import RoleContext from '../../auth/RoleContext'
import { ApiGateway } from '../../config/config';
import axios from 'axios';
import FileListControl from '../../form/FileListControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import StatusTransitionDialog from '../../form/StatusTransitionDialog';
import { DateField } from '@mui/x-date-pickers';
import { TaskAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function CAPItem(props = {  
  assessmentId: undefined,
  correctiveActionItem: undefined,
  correctiveActionPlan: undefined,
  itemModel: undefined,
  assessmentItem: undefined,
  onWorkflowUpdate: undefined
}) {
    // console.log('CAPItem instantiated, props: ', props)
    const calculatePointsClass = (value) => {
        return ('' + value).toLowerCase().replace('_', '-')
    }
    
    const [assessmentItem, setAssessmentItem] = useState()
    const [lastCapItemState, setLastCapItemState] = useState(props.correctiveActionItem)
    const [capItem, setCapItem] = useState(props.correctiveActionItem)
    const [assessmentItemFiles, setAssessmentItemFiles] = useState()
    const [correctiveActionItemFiles, setCorrectiveActionItemFiles] = useState()
    const [showQuestions, setShowQuestions] = useState(false)
    const [selectedAssessmentTab, setSelectedAssessmentTab] = useState(0)
    const [selectedTab, setSelectedTab] = useState(0)
    const [validationErrors, setValidationErrors] = useState([])

    const [reviewComments, setReviewComments] = useState(props.correctiveActionItem.CorrectiveActionItemQSEComments || [])

    const [sendingReview, setSendingReview] = useState(false)
    const [saving, setSaving] = useState(false)

    const roleDetails = useContext(RoleContext)

    const tabChange = (event, toTab) => {
      setSelectedTab(toTab)
    }

    const assessmentTabChange = (event, toTab) => {
      setSelectedAssessmentTab(toTab)
    }
    
    const maxPoints = props.itemModel.props.maxPoints
    

    const getAssessmentItem = async () => {
      const requestConfig = await getRequestConfig()
      const promises = [
        loadItemFiles('assessment', requestConfig),
        loadItemFiles('corrective-action', requestConfig)
      ]
      if ( !props.assessmentItem ) {
        promises.push(axios.get(`${ApiGateway.assessments}/${props.assessmentId}/item/${props.id}`, requestConfig))
      }
      const responses = await Promise.all(promises)
      const _item = props.assessmentItem ?? responses[2].data.item
      setAssessmentItem(_item)
  }

  const acknowledgeComment = async (id) => {
    const requestConfig = await getRequestConfig()
    const response = await axios.post(`${ApiGateway.correctiveAction}/item/comment/${id}/acknowledge`, {}, requestConfig)
    const comments = reviewComments.reduce((p,c) => {
      if (c.id === id) {
        c.acknowledged = 1
      }
      p.push(c)
      return p
    }, [])
    setReviewComments(comments)
  }

  const loadItemFiles = async (category = 'assessment', requestConfig) => {
    if ( !requestConfig ) requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.assessments}/${props.assessmentId}/item/${props.id}`
    const response = await axios.get(`${basePath}/files?category=${category}`, requestConfig)
    if (category === 'corrective-action') {
      setCorrectiveActionItemFiles(response.data ?? [])
    } else setAssessmentItemFiles(response.data ?? [])
}

  const getCorrectiveActionItem = async () => {
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}`
    const requestConfig = await getRequestConfig()
    const response = await axios.get(basePath, requestConfig)
    if (response.data) {
      const _capItem = response.data
      setCapItem(_capItem)
      if (props.onWorkflowUpdate) {
        props.onWorkflowUpdate(_capItem)
      }
      setReviewComments(_capItem.CorrectiveActionItemQSEComments || reviewComments)
      setLastCapItemState(_capItem)
    }
  }

  const saveCapItem = async (_item) => {
    setSaving(true)
    const itemForSave = structuredClone(_item)
    if (itemForSave.CorrectiveActionItemQSEComments) {
      delete itemForSave.CorrectiveActionItemQSEComments
    }
    const basePath = `${ApiGateway.correctiveAction}/item/${_item.id}`
    const requestConfig = await getRequestConfig()
    try {
      await axios.post(basePath, _item ,requestConfig)
    } finally {
      setSaving(false)
    }
  }

  const hardSetCapItem = async (_capItem) => {
    const changes = Object.keys(lastCapItemState).some((k) => {
        return lastCapItemState[k] !== _capItem[k]
    })        
    setCapItem(_capItem)
    if (changes) {        
        await saveCapItem(_capItem)
        setLastCapItemState(_capItem)
    }
}

  const isEditable = (rolesList) => {
    return roleDetails.hasRole(rolesList ?? [ROLES.BOTTLER])
      && (props.correctiveActionPlan.status ?? '') !== 'closed'
      && capItem.status !== CAP_STATUSES.completed
  }

  const isDraft = (rolesList) => {
    return isEditable(rolesList)
    && [CAP_STATUSES.not_started, CAP_STATUSES.review].includes(capItem.status)
  }

  const isApprovedForAction = (rolesList) => {
    return isEditable(rolesList)
    && capItem.status === CAP_STATUSES.approved
  }

  const toggleQuestions = () => {
    setShowQuestions(!showQuestions)
  }

  const [inProcessDialogVisible, setInProcessDialogVisible] = useState(false)
  const hideInProcessDialog = () => { setInProcessDialogVisible(false) }
  const showInProcessDialog = () => { setInProcessDialogVisible(true) }

  const handleInProcessStatusChange = async (values) => {
    setSaving(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/started`
    const requestConfig = await getRequestConfig()
    const payload = {
      ...values
    }
    try {
      await axios.post(basePath, payload ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSaving(false)
    }
  }

  const handleRequestReview = async (values) => {
    const errors = []    
    if ( !capItem.rootCauseAnalysis || capItem.rootCauseAnalysis.trim() === '') {
      errors.push('rootCauseAnalysis')
    }
    if (( !capItem.immediateAction || capItem.immediateAction.trim() === '')
      &&
      (( !capItem.correctiveAction || capItem.correctiveAction.trim() === ''))
    ) {
      errors.push('immediateAction')
      errors.push('correctiveAction')
    }
    if (errors.length) {
      setValidationErrors(errors)
    } else {
      setValidationErrors([])
      setSendingReview(true)
      
      const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/request-review`
      const requestConfig = await getRequestConfig()
      try {
        await axios.post(basePath, {} ,requestConfig)
        const _item = {
          ...capItem,
          status: CAP_STATUSES.review
        }
        await getCorrectiveActionItem()

      } finally {
        setSendingReview(false)
      }
     
    }
  }

  const [reviewDialogVisible, setReviewDialogVisible] = useState(false)
  const hideReviewDialog = () => { setReviewDialogVisible(false)}
  const showReviewDialog = () => { setReviewDialogVisible(true)}

  const [approveDialogVisible, setApproveDialogVisible] = useState(false)
  const hideApproveDialog = () => { setApproveDialogVisible(false) }
  const showApproveDialog = () => { setApproveDialogVisible(true) }

  const [finalizeDialogVisible, setFinalizeDialogVisible] = useState(false)
  const hideFinalizeDialog = () => { setFinalizeDialogVisible(false) }
  const showFinalizeDialog = () => { setFinalizeDialogVisible(true) }

  const handleReviewReply = async ({comment}) => {
    const errors = []
    
    setSendingReview(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/qse-review-comment`
    const requestConfig = await getRequestConfig()
    try {
      await axios.post(basePath, { comment } ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSendingReview(false)
    }
  }

  const handleApprove = async () => {
    const errors = []
    
    setSendingReview(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/qse-approve`
    const requestConfig = await getRequestConfig()
    try {
      await axios.post(basePath, {} ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSendingReview(false)
    }
  }

  const handleReturnedStatusChange = async ({comment}) => {
    const errors = []
    
    setSendingReview(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/returned`
    const requestConfig = await getRequestConfig()
    try {
      await axios.post(basePath, { comment } ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSendingReview(false)
    }
  }

  const [completedDialogVisible, setCompletedDialogVisible] = useState(false)
  const hideCompletedDialog = () => { setCompletedDialogVisible(false) }
  const showCompletedDialog = () => { setCompletedDialogVisible(true) }

  const handleCompletedStatusChange = async (values) => {
    setSaving(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/completed`
    const requestConfig = await getRequestConfig()
    const payload = {
      ...values
    }
    try {
      await axios.post(basePath, payload ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSaving(false)
    }
  }

  const handleFinalizedStatusChange = async ({comment}) => {
    const errors = []
    
    setSendingReview(true)
    
    const basePath = `${ApiGateway.correctiveAction}/item/${capItem.id}/finalize`
    const requestConfig = await getRequestConfig()
    try {
      await axios.post(basePath, { comment } ,requestConfig)
      await getCorrectiveActionItem()

    } finally {
      setSendingReview(false)
    }
  }

  const getUnreadCommentCount = () => {
    const unread = reviewComments.reduce((p,c) => {
      if ( !c.acknowledged) p.push(c);
      return p;
    }, [])
    return unread.length
  }


    useEffect(() => {
      getAssessmentItem()
    },[])

    return (
      <>
      {!assessmentItem && (
        <Typography variant='h5' align='center'>Loading...</Typography>
      )}
      {assessmentItem && (      
        <>
          <Tabs value={selectedTab} onChange={tabChange}>          
            <Tab label='Corrective Action Plan' id={`tab-0`}></Tab>
            <Tab label='Assessment Findings' id={`tab-1`}></Tab>
            {[CAP_STATUSES.review, CAP_STATUSES.approved,CAP_STATUSES.in_process, CAP_STATUSES.completed].includes(capItem.status) && reviewComments.length && 
              <Tab label={`QSE Review Comments (${getUnreadCommentCount()} new)`} id={`tab-2`}></Tab>
            }
          </Tabs>
          
          <TabPanel selectedTab={selectedTab} thisTab={0}>
            <Grid key={assessmentItem.itemId} id={assessmentItem.itemId} container rowSpacing={2} className={`tis-cap-item cap-entry-form`}>
            {/* <Grid container xs={12} md={12} rowGap={1} className=''> */}              
              { capItem.status === CAP_STATUSES.review && reviewDialogVisible &&
                  <StatusTransitionDialog
                    configurations={[                      
                      {id: 'comment', type: 'textarea', label: 'Comment', required: true, maxLength: 1000}
                    ]}
                    open={reviewDialogVisible}
                    hideDialog={hideReviewDialog}
                    title='Request Changes in Corrective Action'
                    text='Please enter a comment regarding changes requested.'
                    noLabel='Cancel'
                    yesLabel='Send'
                    handleConfirm={handleReviewReply}/>
              }
              { capItem.status === CAP_STATUSES.review && approveDialogVisible &&
                  <StatusTransitionDialog
                    open={approveDialogVisible}
                    hideDialog={hideApproveDialog}
                    title='Approve Corrective Action'
                    text='Please confirm this approval. This action is final.'
                    noLabel='Cancel'
                    yesLabel='Approve (Final)'
                    handleConfirm={handleApprove}/>
              }
              { [CAP_STATUSES.approved, CAP_STATUSES.in_process].includes(capItem.status) && inProcessDialogVisible &&
                  <StatusTransitionDialog
                    configurations={[
                      {id: 'personResponsible', type: 'text', label: 'Person Responsible', value: capItem.personResponsible, required: true, maxLength: 100},
                      {id: 'targetCompletionDate', type: 'date', label: 'Target Completion Date', value: capItem.targetCompletionDate, required: true}
                    ]}
                    open={inProcessDialogVisible}
                    hideDialog={hideInProcessDialog}
                    title={capItem.status === CAP_STATUSES.in_process ? 'Update In Process Settings' : 'Mark Action Item In Process'}
                    text='Please specify who is responsible for this action item and the target completion date.'
                    noLabel='Cancel'
                    yesLabel='Mark In Process'
                    handleConfirm={handleInProcessStatusChange} />
              }
              { capItem.status === CAP_STATUSES.in_process && completedDialogVisible &&
                  <StatusTransitionDialog
                    configurations={[                      
                      {id: 'completionDate', type: 'date', label: 'Date Completed', required: true},
                      {id: 'verifiedBy', type: 'text', label: 'Verified By', required: false, maxLength: 100},
                      {id: 'verifiedCompleteDate', type: 'date', label: 'Date Verified', required: false}
                    ]}
                    open={completedDialogVisible}
                    hideDialog={hideCompletedDialog}
                    title='Set Action Item Complete'
                    text='Please specify the date this action was completed and verified.'
                    noLabel='Cancel'
                    yesLabel='Save'
                    handleConfirm={handleCompletedStatusChange}/>
              }
              { capItem.status === CAP_STATUSES.completed && reviewDialogVisible &&
                  <StatusTransitionDialog
                    configurations={[                      
                      {id: 'comment', type: 'textarea', label: 'Comment', required: true, maxLength: 1000}
                    ]}
                    open={reviewDialogVisible}
                    hideDialog={hideReviewDialog}
                    title='Request Changes in Corrective Action'
                    text='Please enter a comment regarding changes requested.'
                    noLabel='Cancel'
                    yesLabel='Send'
                    handleConfirm={handleReturnedStatusChange}/>
              }
              { ( [CAP_STATUSES.review, CAP_STATUSES.completed].includes(capItem.status) && capItem.status !== CAP_STATUSES.finalized )
                && finalizeDialogVisible &&
                  <StatusTransitionDialog
                    open={finalizeDialogVisible}
                    hideDialog={hideFinalizeDialog}
                    title='Finalize Corrective Action'
                    text='Please confirm this final approval.'
                    noLabel='Cancel'
                    yesLabel='Approve (Final)'
                    handleConfirm={handleFinalizedStatusChange}/>
              }
              <Grid xs={12} md={12} style={{backgroundColor: '#ecf0f5bf'}}>
              <Stack spacing={2} direction='row' alignItems='center'>
                <Typography flexGrow={1}><b>Status:</b>&nbsp;{CAP_STATUS_LABELS[capItem.status]}</Typography>

                { isEditable([ROLES.BOTTLER]) && ([CAP_STATUSES.not_started, CAP_STATUSES.review].includes(capItem.status)) &&
                  <LoadingButton disabled={saving} loading={sendingReview} onClick={() => handleRequestReview()} variant='outlined'>Request Review</LoadingButton>}

                  { isEditable([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) && ([CAP_STATUSES.review].includes(capItem.status)) &&
                  <LoadingButton disabled={saving} loading={sendingReview} onClick={() => showReviewDialog()} variant='outlined'>Request Changes</LoadingButton>}

                  { isEditable([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) && ([CAP_STATUSES.review].includes(capItem.status)) &&
                  <LoadingButton disabled={saving} loading={sendingReview} onClick={() => showApproveDialog()} variant='outlined'>Approve Plan</LoadingButton>}

                  { isEditable() && capItem.status === CAP_STATUSES.approved &&
                  <Button onClick={() => showInProcessDialog()} variant='outlined'>Start Process</Button>}

                  { isEditable([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) && capItem.status === CAP_STATUSES.in_process &&
                  <Button onClick={() => showInProcessDialog()} variant='outlined'>Edit Process</Button>}

                  { isEditable() && capItem.status === CAP_STATUSES.in_process &&
                  <Button onClick={() => showCompletedDialog()} variant='outlined'>Mark Completed</Button>}
                  
                  { roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) && ([CAP_STATUSES.completed].includes(capItem.status)) &&
                  <LoadingButton disabled={saving} loading={sendingReview} onClick={() => showReviewDialog()} variant='outlined'>Return Action Item</LoadingButton>}
                  
                  { roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) 
                    && ([CAP_STATUSES.review, CAP_STATUSES.completed].includes(capItem.status) && capItem.status !== CAP_STATUSES.finalized)
                    &&
                  <LoadingButton disabled={saving} loading={sendingReview} onClick={() => showFinalizeDialog()} variant='outlined'>Finalize Action Item</LoadingButton>}

                </Stack>
              </Grid>
              { capItem.status === CAP_STATUSES.in_process &&
                <>
                <Grid md={6}>
                  <TextField fullWidth label="Responsible Person" size='small' value={capItem.personResponsible} disabled
                    />
                </Grid>
                <Grid md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField 
                      format='MM/DD/YYYY'
                      fullWidth
                      label="Target Completion Date"
                      value={capItem.targetCompletionDate ? dayjs(capItem.targetCompletionDate) : undefined}
                      disabled
                      size='small'
                      />
                    </LocalizationProvider>
                </Grid>
                </>
              }
              { capItem.status === CAP_STATUSES.completed &&
              <>
              <Grid xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField 
                    format='MM/DD/YYYY'
                    label="Date Completed"
                    value={capItem.completionDate ? dayjs(capItem.completionDate) : undefined}
                    disabled
                    size='small'
                    />
                  </LocalizationProvider>
                </Grid>
              <Grid xs={5}>
                <TextField fullWidth label="Verified By" size='small' value={capItem.verifiedBy} disabled
                />                
              </Grid>
              <Grid xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField 
                  format='MM/DD/YYYY'
                  label="Date Verified"
                  value={capItem.verifiedCompleteDate ? dayjs(capItem.verifiedCompleteDate) : undefined}
                  disabled
                  size='small'
                  />
                  </LocalizationProvider>                
              </Grid>
              </>
              }
              <Grid xs={12}>
                <TextField fullWidth label="Root Cause Analysis" size='small' multiline rows={6}
                  inputProps={{ maxLength: 2000 }}
                  helperText={`${capItem.rootCauseAnalysis?.length ?? 0}/2000`}
                  value={capItem.rootCauseAnalysis}
                  disabled={ !isEditable() }
                  error={validationErrors.includes('rootCauseAnalysis')}
                  onChange={(e) => handleFieldChange('rootCauseAnalysis', e.target.value, capItem, setCapItem)}
                  onBlur={(e) => handleFieldChange('rootCauseAnalysis', e.target.value, capItem, hardSetCapItem)}
                  />
                  { validationErrors.includes('rootCauseAnalysis') && <FormHelperText error={true}>This field is required</FormHelperText>}
                </Grid>
              <Grid xs={12}>
                <TextField fullWidth label="Immediate Action" size='small' multiline rows={6}
                  inputProps={{ maxLength: 2000 }}
                  helperText={`${capItem.immediateAction?.length ?? 0}/2000`}
                  value={capItem.immediateAction}
                  disabled={ !isEditable() }
                  error={validationErrors.includes('immediateAction')}
                  onChange={(e) => handleFieldChange('immediateAction', e.target.value, capItem, setCapItem)}
                  onBlur={(e) => handleFieldChange('immediateAction', e.target.value, capItem, hardSetCapItem)}
                  />
                  { validationErrors.includes('immediateAction') && <FormHelperText error={true}>One of Immediate Action or Corrective Action is required for review.</FormHelperText>}
                </Grid>
              <Grid xs={12}>
                <TextField fullWidth label="Corrective Action" size='small' multiline rows={6}
                  inputProps={{ maxLength: 2000 }}
                  helperText={`${capItem.correctiveAction?.length ?? 0}/2000`}
                  value={capItem.correctiveAction}
                  disabled={ !isEditable() }
                  error={validationErrors.includes('correctiveAction')}
                  onChange={(e) => handleFieldChange('correctiveAction', e.target.value, capItem, setCapItem)}
                  onBlur={(e) => handleFieldChange('correctiveAction', e.target.value, capItem, hardSetCapItem)}
                  />
                  { validationErrors.includes('correctiveAction') && <FormHelperText error={true}>One of Immediate Action or Corrective Action is required for review.</FormHelperText>}
                </Grid>
              <Grid xs={12}>
                <TextField fullWidth label="Measure of Effectiveness" size='small' multiline rows={4}
                  inputProps={{ maxLength: 2000 }}
                  helperText={`${capItem.measureOfEffectiveness?.length ?? 0}/2000`}
                  value={capItem.measureOfEffectiveness}
                  disabled={ !isEditable() }
                  onChange={(e) => handleFieldChange('measureOfEffectiveness', e.target.value, capItem, setCapItem)}
                  onBlur={(e) => handleFieldChange('measureOfEffectiveness', e.target.value, capItem, hardSetCapItem)}
                  />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField fullWidth label="Comments" size='small' multiline rows={5}
                  inputProps={{ maxLength: 1000 }}
                  helperText={`${capItem.comments?.length ?? 0}/1000`}
                  value={capItem.comments}
                  disabled={ !isEditable() }
                  onChange={(e) => handleFieldChange('comments', e.target.value, capItem, setCapItem)}
                  onBlur={(e) => handleFieldChange('comments', e.target.value, capItem, hardSetCapItem)}
                  />
              </Grid>
              <Grid xs={12} md={6}>
              <FileListControl
                  rowData={correctiveActionItemFiles}
                  showLastModified={true}
                  showActions={isEditable()}
                  enableUpload={isEditable()}
                  enableDownload={true}
                  getFileUrlServiceAddr={`${ApiGateway.assessments}/${props.assessmentId}/item/${assessmentItem.itemId}/files/`}
                  getFileUrlServiceQueryParams={`category=corrective-action`}
                  getPutFileUrlServiceAddr={`${ApiGateway.assessments}/${props.assessmentId}/item/${assessmentItem.itemId}/new-file-url`}
                  getPutFileUrlServiceQueryParams={`category=corrective-action`}
                  removeFileUrl={`${ApiGateway.assessments}/${props.assessmentId}/item/${assessmentItem.itemId}/files`}
                  removeFileUrlQueryParams={`category=corrective-action`}
                  onFileRemoved={() => loadItemFiles('corrective-action')}
                  onFileUploaded={() => loadItemFiles('corrective-action')}
              />
              </Grid>
            {/* </Grid> */}
            </Grid>
          </TabPanel>
          <TabPanel selectedTab={selectedTab} thisTab={1}>
            <Grid container rowSpacing={2} className={`tis-cap-item`}>
              <Grid xs={1.5} md={1.5} >
                  <div className="tis-cap-item-id">
                    {/* {assessmentItem.itemId} */}
                    <div className="points-label">Score:</div>
                    <div className={`points ${calculatePointsClass(assessmentItem[ITEM_PROPS.findingRating])}`}>
                        <div className="earned-points">{parseFloat(assessmentItem[ITEM_PROPS.earnedPoints])}</div><div className="max-points">{maxPoints}</div>
                    </div>
                  </div>
                </Grid>
                <Grid xs={10.5} md={10.5}>
                  <Stack spacing={1}>
                    <Stack spacing={3} direction='row' alignItems='baseline'>
                      <div className="title">{props.itemModel.props.title}</div>
                      <Button variant='text' onClick={toggleQuestions}>{showQuestions ? 'Hide' : 'Show'} Criteria</Button>
                    </Stack>
                    {showQuestions && <div className="tis-cap-question-panel">{
                    props.children.map(child => <div className="tis-quality-and-fs-questions">{child}</div>)
                    }</div>}
                    <Stack spacing={3} direction='row'>
                      <Typography><strong>Finding Rating:</strong> {RATING_LABELS[assessmentItem[ITEM_PROPS.findingRating]]}</Typography>                  
                      <Typography><strong>Repeat:</strong> {assessmentItem[ITEM_PROPS.isRepeat] ? 'Yes' : 'No'}</Typography>
                    </Stack>
                    <Stack spacing={3} direction='row'>
                      <Typography><strong>Assessment Type:</strong> {ASSESSMENT_TYPE_LABELS[assessmentItem[ITEM_PROPS.assessmentType]]}</Typography>
                      <Typography><strong>Area Finding: Observed/Location:</strong>&nbsp;{assessmentItem[ITEM_PROPS.areaFinding]}</Typography>
                  </Stack>
                  </Stack>
                </Grid>
                <Grid xs={12} md={12}>
                  <Box>
                    <Tabs value={selectedAssessmentTab} onChange={assessmentTabChange}>          
                      <Tab label='Finding: Gaps/Observations' id={`tab-0`}></Tab>
                      <Tab label='Objective Evidence' id={`tab-1`}></Tab>
                      <Tab label={`Supporting Files (${assessmentItemFiles ? assessmentItemFiles.length : 0})`} id={`tab-2`}></Tab>
                    </Tabs>
                  </Box>
                <TabPanel selectedTab={selectedAssessmentTab} thisTab={0}>
                  <div>{assessmentItem[ITEM_PROPS.findingObservations]}</div>
                </TabPanel>
                <TabPanel selectedTab={selectedAssessmentTab} thisTab={1}>
                  <Stack rowGap={2}>
                  <Typography fontWeight='bold'>Objective Evidence:</Typography>
                  <div>{assessmentItem[ITEM_PROPS.objectiveEvidence]}</div>
                  <Typography fontWeight='bold'>QSE Objective Evidence:</Typography>
                  <div>{assessmentItem[ITEM_PROPS.objectiveEvidenceQse]}</div>
                  </Stack>
                </TabPanel>
                <TabPanel selectedTab={selectedAssessmentTab} thisTab={2}>
                  <FileListControl
                      rowData={assessmentItemFiles}
                      showLastModified={true}
                      showActions={false}
                      enableUpload={false}
                      enableDownload={true}
                      getFileUrlServiceAddr={`${ApiGateway.assessments}/${props.assessmentId}/item/${assessmentItem.itemId}/files/`}
                  />
                </TabPanel>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel selectedTab={selectedTab} thisTab={2}>
              <Grid container rowSpacing={2} className={`tis-cap-item cap-review-comments`}>
                { reviewComments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((c) => {
                  return (
                    <Grid item xs={12} sx={{ borderBottom: '1px dotted grey', display: 'flex', justifyContent: 'flex-start' }}>
                      <Stack spacing={2} direction='row' alignItems='flex-start'>
                        { (!c.acknowledged && roleDetails.hasRole([ROLES.BOTTLER]) ) && <Link onClick={() => acknowledgeComment(c.id)} title='Mark Read'><TaskAlt /></Link>}
                        <Typography fontWeight='bold' whiteSpace='nowrap'>{new Date(c.createdAt).toLocaleString()}</Typography>
                        <Box sx={{whiteSpace: 'pre-wrap'}}>{c.comment}</Box>
                      </Stack>
                    </Grid>
                  )
                })}
              </Grid>
            </TabPanel>
          
            <div className='separator'>&nbsp;</div>
        </>
    )}
    </>
  )
}
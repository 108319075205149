import { default as React, useEffect, useState, useMemo } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { TaskAlt } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton, Box, Grid, Paper, IconButton, FormControl, FormLabel, Radio, FormControlLabel, RadioGroup, FormElement, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import ManufacturerAutocomplete from '../form/ManufacturerAutocomplete'
import QSEAutocomplete from '../form/QSEAutoComplete'
import WizardCommandBar from '../commandBar/WizardCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileListControl from '../form/FileListControl'
import { serializeFilterToQueryString } from '../utils/TableUtils'
import ProfileSelectionControl from '../manufacturer/ProfileSelectionControl'
import { LetterTypes } from './common'

export default function NewAuthorizationPage(props = {}) {
  const [isLoading, setIsLoading] = useState(false)
  const [manufacturerId, setManufacturerId] = useState('')
  const [letterType, setLetterType] = useState('')
  const [isHistorical, setIsHistorical] = useState(false)
  const [issuedDate, setIssuedDate] = useState(dayjs())
  const [reviewedDate, setReviewedDate] = useState(dayjs())
  const [revisionDate, setRevisionDate] = useState(dayjs())
  const [hasAuthorizationHistory, sethasAuthorizationHistory] = useState(false)
  const [revisionNumber, setRevisionNumber] = useState('')
  const [reviewHasRevision, setReviewHasRevision] = useState('no')
  const [revisionHasReview, setRevisionHasReview] = useState('no')
  const [parent, setParent] = useState({})
  const [existingDraft, setExistingDraft] = useState({})
  const [draftAlreadyExists, setDraftAlreadyExists] = useState(false)

  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const resetFields = () => {
    setLetterType('')
    setIssuedDate(dayjs())
    setReviewedDate(dayjs())
    setRevisionDate(dayjs())
    setRevisionNumber('')
    setReviewHasRevision('no')
    setRevisionHasReview('no')
  }

  const breadcrumb = <span><TaskAlt />&nbsp;New Facility Authorization</span>

  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const makeAuthorization = () => {
    const _authorization = {
      manufacturerId: `${manufacturerId}`,
      letterType,
      isHistorical
    }

    if (isHistorical) {
      _authorization.issuedDate = issuedDate?.toISOString()
      const creatingReviewWithRevion = letterType === LetterTypes.REVIEW && reviewHasRevision === 'yes'
      const creatingRevisionWithReview = letterType === LetterTypes.REVISION && revisionHasReview === 'yes'

      if (letterType === LetterTypes.REVISION || creatingReviewWithRevion) _authorization.revisionDate = revisionDate?.toISOString()
      if (letterType === LetterTypes.REVISION || creatingReviewWithRevion) _authorization.revisionNumber = revisionNumber
      if (letterType === LetterTypes.REVIEW || creatingRevisionWithReview) _authorization.reviewedDate = reviewedDate?.toISOString()
      return _authorization
    }

    if (!hasAuthorizationHistory) return _authorization

    _authorization.parentId = parent.id

    if (letterType === LetterTypes.REVISION) _authorization.revisionNumber = revisionNumber
    if (letterType !== LetterTypes.NEW) _authorization.issuedDate = parent.issuedDate
    if (letterType !== LetterTypes.REVISION) _authorization.revisionDate = parent.revisionDate
    if (letterType !== LetterTypes.REVISION) _authorization.revisionNumber = parent.revisionNumber
    if (letterType !== LetterTypes.REVIEW) _authorization.reviewedDate = parent.reviewedDate

    if (letterType === LetterTypes.NEW) _authorization.reviewedDate = null
    if (letterType === LetterTypes.NEW) _authorization.revisionDate = null
    if (letterType === LetterTypes.NEW) _authorization.revisionNumber = null

    return _authorization
  }

  const onNext = async () => {
    startLoading()
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.authorizations}`
    const _authorization = makeAuthorization()
    const response = await axios.put(basePath, _authorization, requestConfig)
    const { instance } = response.data
    const { id } = instance
    stopLoading()
    if (isHistorical) navigate(`/facility-authorizations/${id}/files`)
    else navigate(`/facility-authorizations/${id}/edit`)
  }

  useEffect(() => {
    resetFields()
    const getAuthorizationForThisMfr = async (requestConfig, workflowStatus, isLatest = true) => {
      const search = {
        manufacturerId: `${manufacturerId}`,
        workflowStatus,
        isLatest
      }

      const basePath = `${ApiGateway.authorizations}/search`

      const response = await axios.post(basePath, search, requestConfig)
      return response?.data?.results?.length ? response?.data?.results[0] : null
    }

    const getAuthorizations = async () => {
      startLoading()

      const requestConfig = await getRequestConfig()

      const [parent, existingDraft] = await Promise.all([
        getAuthorizationForThisMfr(requestConfig, 'PUBLISHED'),
        getAuthorizationForThisMfr(requestConfig, 'DRAFT', false)
      ])

      if(existingDraft) {
        setDraftAlreadyExists(true)
        setExistingDraft(existingDraft)
      } else {
        setExistingDraft({})
        setDraftAlreadyExists(false)
      }

      if (parent) {
        setParent(parent)
        sethasAuthorizationHistory(true)
        if (parent.revisionNumber) setRevisionNumber(parent.revisionNumber + 1)
      } else {
        sethasAuthorizationHistory(false)
      }

      stopLoading()
    }
    if (manufacturerId) {
      getAuthorizations()
    } else {
      setParent({})
    }
  }, [manufacturerId])

  const letterTypeOptions = useMemo(() => {

    if (!hasAuthorizationHistory && !isHistorical) {
      return [
        {
          label: 'New Issue',
          value: LetterTypes.NEW
        },
      ]
    }

    return [
      {
        label: 'New Issue',
        value: LetterTypes.NEW
      },
      {
        label: 'Review',
        value: LetterTypes.REVIEW
      },
      {
        label: 'Revision',
        value: LetterTypes.REVISION
      },
    ]

  }, [hasAuthorizationHistory, manufacturerId, isHistorical])

  const sectionTwoVisible = useMemo(() => {
    if (draftAlreadyExists) return false
    if (!manufacturerId) return false
    return letterTypeOptions.length > 0 ? true : false
  }, [letterTypeOptions, draftAlreadyExists])

  const fieldSize = 3

  const nextEnabled = useMemo(() => {
    if (draftAlreadyExists) return false
    let enabled = manufacturerId && letterType
    if (letterType === LetterTypes.EXISTING) {
      enabled = enabled && issuedDate
    }
    if (letterType === LetterTypes.REVISION) {
      enabled = enabled && revisionNumber > 0
    }
    return enabled
  }, [letterType, revisionNumber, issuedDate, manufacturerId, draftAlreadyExists])

  const creatingReviewWithRevisions = letterType === LetterTypes.REVIEW && reviewHasRevision === 'yes' && isHistorical
  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
          commandBar={
            <WizardCommandBar
              loading={isLoading}
              onCancel={() => navigate('/facility-authorizations/list')}
              enableNext={nextEnabled}
              onNext={onNext}
            />
          }>

          <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
            <Grid container columnGap={1} rowGap={1} spacing={1}>
              <Grid item xs={fieldSize}>
                <ProfileSelectionControl
                  onSelectProfile={(id, profile) => setManufacturerId(profile?.manufacturerId)}
                />
              </Grid>

              <Grid item xs={3}>&nbsp;</Grid>

              {draftAlreadyExists && <Grid item xs={8}>
                <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '5px' }} >{existingDraft.Manufacturer?.manufacturerName} already has a current DRAFT letter.</Typography>
              </Grid>}

              {!draftAlreadyExists && <Grid item xs={8}>
                <FormControl size='small'>
                  {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                  <RadioGroup
                    row
                    value={isHistorical}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="false"
                    name="radio-buttons-group"
                    onChange={e => {
                      if (e.target.value === 'false' && letterType === LetterTypes.REVISION) {
                        setRevisionNumber('')
                        setLetterType('')
                      }
                      setIsHistorical(e.target.value === 'true')
                    }}
                  >
                    <FormControlLabel value={false} control={<Radio defaultChecked />} label="Create a New Letter" />
                    {!hasAuthorizationHistory && <FormControlLabel value={true} control={<Radio />} label="Load Authorization History" />}
                  </RadioGroup>
                </FormControl>
              </Grid>}

            </Grid>
          </Paper>

          {sectionTwoVisible && <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
            <Grid container columnGap={1} rowGap={1} spacing={1}>

              <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Letter Type</InputLabel>
                  <Select
                    fullWidth
                    name="letterType"
                    value={letterType}
                    onChange={(e) => {
                      if (e.target.value === LetterTypes.REVISION && !revisionNumber) setRevisionNumber(1)
                      setLetterType(e.target.value)
                    }}
                  >
                    {letterTypeOptions.map((letterType, i) =>
                      <MenuItem key={letterType.value} value={letterType.value}>{letterType.label}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>


              {isHistorical && letterType === LetterTypes.REVIEW && <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Does the letter have revision history?</InputLabel>
                  <Select
                    fullWidth
                    name="reviewHasRevision"
                    value={reviewHasRevision}
                    onChange={(e) => setReviewHasRevision(e.target.value)}
                  >
                    <MenuItem key='yes' value='yes'>Yes</MenuItem>
                    <MenuItem key='no' value='no'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}

              {isHistorical && letterType === LetterTypes.REVISION && <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Does the letter have review history?</InputLabel>
                  <Select
                    fullWidth
                    name="revisionHasReview"
                    value={revisionHasReview}
                    onChange={(e) => setRevisionHasReview(e.target.value)}
                  >
                    <MenuItem key='yes' value='yes'>Yes</MenuItem>
                    <MenuItem key='no' value='no'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}

              {isHistorical && [LetterTypes.REVIEW, LetterTypes.REVISION].includes(letterType) && <Grid item xs={11 - (fieldSize*2)}>&nbsp;</Grid>}
              {isHistorical && letterType === LetterTypes.NEW && <Grid item xs={11 - fieldSize}>&nbsp;</Grid>}

              {isHistorical && letterType !== '' && <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id='issuedDate'
                      format="MM/DD/YYYY"
                      disableFuture
                      label="Issue Date"
                      size="small"
                      value={dayjs(issuedDate?.['$d'] || issuedDate)}
                      onChange={setIssuedDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          id: 'issuedDate',
                          onBlur: (event) => { },
                          onChange: setIssuedDate,
                          error: false
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>}

              {isHistorical && (letterType === LetterTypes.REVIEW || (letterType === LetterTypes.REVISION && revisionHasReview === 'yes'))&& <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id='reviewedDate'
                      format="MM/DD/YYYY"
                      disableFuture
                      label="Reviewed Date"
                      size="small"
                      value={dayjs(reviewedDate?.['$d'] || reviewedDate)}
                      onChange={setReviewedDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          id: 'issuedDate',
                          onBlur: (event) => { },
                          onChange: setReviewedDate,
                          error: false
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>}

              {isHistorical && (letterType === LetterTypes.REVISION || creatingReviewWithRevisions) && <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id='revisionDate'
                      format="MM/DD/YYYY"
                      disableFuture
                      label="Revison Date"
                      size="small"
                      value={dayjs(revisionDate?.['$d'] || revisionDate)}
                      onChange={setRevisionDate}
                      slotProps={{
                        textField: {
                          size: "small",
                          id: 'revisionDate',
                          onBlur: (event) => { },
                          onChange: setRevisionDate,
                          error: false
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>}

              {(letterType === LetterTypes.REVISION || creatingReviewWithRevisions) && <Grid item xs={fieldSize}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Revision Number</InputLabel>
                  <Select
                    fullWidth
                    name="revisionNumber"
                    value={`${revisionNumber}`}
                    onChange={(e) => setRevisionNumber(e.target.value)}
                  >
                    {Array.from({ length: 30 }, (_, i) => i + 1).map(rn =>
                      <MenuItem disabled={rn <= parent.revisionNumber} key={rn} value={rn}>{rn}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>}

              {/* <Grid item xs={fieldSize + 1}>
                <FileListControl
                  rowData={[]}
                  showActions={true}
                  enableUpload={true}
                  showFileList={false}
                  getPutFileUrlServiceAddr={`${ApiGateway.authorization}/self/${'d'}/new-file-url`}
                />
              </Grid> */}

            </Grid>
          </Paper>}

        </Page>
      )}
    </RoleContext.Consumer>
  )
}
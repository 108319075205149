import Grid from '@mui/material/Unstable_Grid2'
import { Button, Paper, FormControl, TextField } from '@mui/material'
import LineControl from './LineControl'
import { Science, Add } from '@mui/icons-material'

export default function LineSection(props) {
    const {
        newRecord,
        setNewRecord,
        fwGridItemProps,
        handleChange,
        instructions,
        lineName = " ",
        section
    } = props

    const lineLabel = `${lineName[0].toUpperCase()}${lineName.substring(1)}`
    const linesAccessor = `${lineName}Lines`
    const commentsAccessor = `${lineName}Comments`
    const addLine = () => {
        const _newRecord = { ...newRecord }
        _newRecord[linesAccessor].push({
            index: newRecord[linesAccessor].length,
            value: null
        })
        setNewRecord(_newRecord)
    }
    const removeLine = () => {
        const _newRecord = { ...newRecord }
        _newRecord[linesAccessor].pop()
        setNewRecord(_newRecord)
    }

    return <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
        <Grid container columnGap={1} rowGap={1} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2>{`Section ${section} - ${lineLabel}`}</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                {instructions}
            </Grid>
            <LineControl
                disabled={props.disabled}
                setter={setNewRecord}
                newRecord={newRecord}
                type={`${lineLabel} Cpk`}
                accessor={linesAccessor}
                addLine={addLine}
                removeLine={removeLine}
            />
            <Grid item {...fwGridItemProps}>
                <FormControl fullWidth>
                    <TextField
                        disabled={props.disabled}
                        id={commentsAccessor}
                        type="number"
                        size='medium'
                        multiline={true}
                        value={newRecord[commentsAccessor]}
                        onChange={handleChange}
                        label={`Comments - ${lineLabel} Cpk`}
                        fullWidth
                    />
                </FormControl>
            </Grid>
        </Grid>
    </Paper>

}
import { Box, Button, IconButton, TextField, Tooltip, FormControl, InputLabel, Select, MenuItem, Stack, Link } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { MaterialReactTable } from "material-react-table";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { MuiTelInput } from "mui-tel-input";
import { AddRecordModal, deleteRow, handleEditClick, addRow, handleSaveRowEdits, handleCancelEdit, setRowAutocomplete, checkRequiredError, checkColumnError, EditRecordModal } from "../../form/Form";
import { useMemo, useState, useCallback } from "react";
import ConfirmDialog from "../../form/ConfirmDialog";

const filterOptions = createFilterOptions()

export default function ProductTable (props = {
  columnSet: '',
  productsData: [],
  handleSaveItem: undefined,
  handleDeleteItem: undefined,
  itemType: '',
  setProductsData: undefined,
  editMode: false
}) {

  const [tempRow, setTempRow] = useState({})
  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [showEditPanel, setShowEditPanel] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const [deleteDialog, setDeleteDialog] = useState(false)

  const hideDeleteDialog = () => {
    setTempRow(undefined)
    setDeleteDialog(false)
  }

  const handleConfirmDelete = async () => {
    console.log(props)
    await props.handleDeleteItem(props.itemType, tempRow, props.setProductsData)    
    hideDeleteDialog()
  }

  const editRow = (row) => {
    console.log(row.original)
    setTempRow(row.original)
    setShowEditPanel(true)
  }

  const deleteRow = (row) => {
    setTempRow(row.original)
    setDeleteDialog(true)
  }

  const handleSaveRowEdits = async (itemType, item, rowDataSetter) => {    
    if (!Object.keys(validationErrors).length) {
      await props.handleSaveItem(itemType, item, rowDataSetter)
    }
  }

  const editColumn = { id: 'edit', size: 80, header: 'Actions', columnDefType: 'display', Cell: (
    ({ cell, row }) => (
    <Stack direction='row' spacing={2}>
      <Link onClick={() => editRow(row)}><EditIcon /></Link>
      <Link onClick={() => deleteRow(row)}><DeleteIcon /></Link>
    </Stack>
    )
    )
  }

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        variant: "outlined",
        onBlur: (event) => { checkColumnError(cell.id, event.target.value, validationErrors, setValidationErrors)
        },
      };
    },
    [validationErrors],
  )

  const packageTypes = ['Aluminum Bottle','Can','Gable Top','Glass','Plastic','Preform','Paperboard BRIK','Drum','Box','Pail','Bag/Pouch','Tote','Pallet']
  const processTypes = ["Hot-Fill Processing", "Cold-Fill Processing", "Aseptic", "Carbonated", "Chilled and Frozen", "Fountain", "UHT", "Packaged Water Processing", "Dry Filling", "Retort Processing", "Tunnel Processing", "Food Service Concentrate", "Ice Cream and Dairy Based Frozen Desert Manufacturing"]
  const columnSets = {
    productsColumns: useMemo(() => {
      const set = [
      { accessorKey: 'processType', header: 'Process Type', size: 160, editable: true,
        customType: "Autocomplete", customElement: (value, onChange) => <Autocomplete
          value={value}
          onChange={onChange}
          filterOptions={(options, params) => {
            const filtered = filterOptions(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue)
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          id="processType"
          options={processTypes}
          groupBy={(option)=>{
            if (processTypes.includes(option)){
              return ""
            }
            return "Create New"
          }}
          getOptionLabel={(option) => {return option.toString();}}
          disablePortal
          renderInput={(params) => (
            <TextField {...params} 
            label="Process Type" 
            size='small'
            error={checkRequiredError(value)}
            helperText={checkRequiredError(value) ? "This field is required" : false}
          />
        )}
        />,
      },
      { accessorKey: 'line', header: 'Line', size: 100, editable: true, 
        muiTableBodyCellEditTextFieldProps: {
          variant: "outlined"
        }
      },
      { accessorKey: 'mostSensitiveProduct', header: 'Most Sensitive Product Produced', size: 250, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
      { accessorKey: 'packageType', header: 'Package Type', size: 120, editable: true,
          customType: "Select", customOptions: packageTypes
      },
      { accessorKey: 'packageSize', header: 'Package Size', size: 120, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
    ]
    if (props.editMode) set.push(editColumn)
    return set
    }),
    rawMaterialsColumns: useMemo(() => {
      const set = [
      { accessorKey: 'description', header: 'Material Description', size: 200, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
      { accessorKey: 'supplierCompany', header: 'Supplier Company', size: 200, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
      { accessorKey: 'city', header: 'City', size: 80, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
      { accessorKey: 'state', header: 'State', size: 80, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
      }),
      },
      { accessorKey: 'phone', header: 'Phone Number', size: 100, editable: true, required: false,
      },
      ]
      if (props.editMode) set.push(editColumn)
      return set
    }),
    comanufacturedColumns: useMemo(() => {
      const set = [
        { accessorKey: 'description', header: 'Product Description', size: 200, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'isPungent', header: 'Pungent?', size: 100, editable: true,
            customType: "Select", customOptions: {'Yes': 1, 'No': 0}
        },
        { accessorKey: 'containsAllergens', header: 'Contains Allergens?', size: 160, editable: true,
            customType: "Select", customOptions: {'Yes': 1, 'No': 0}
        },
        { accessorKey: 'lines', header: 'Line(s)', size: 120, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'sanitation', header: 'What type of sanitation is completed when changing to a TCCC product?', size: 450, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
      ]
      if (props.editMode) set.push(editColumn)
      return set
    }),
    backupColumns: useMemo(() => {
        const set = [
        { accessorKey: 'productName', header: 'Product Name', size: 200, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'operationName', header: 'Operation Name', size: 200, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'city', header: 'City', size: 80, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'state', header: 'State', size: 80, editable: true, 
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
        }),
        },
        { accessorKey: 'phone', header: 'Phone Number', size: 100, editable: true, required: false},
      ]
      if (props.editMode) set.push(editColumn)
      return set
    })
  }

  const overrideColumnsBySet = {
    productsColumns: ["processTypeId", "packageType"],
    rawMaterialsColumns: ["phone"],
    comanufacturedColumns: ["isPungent", "containsAllergens"],
    backupColumns: ["phone"]
  }

  return (
    <div className='tis-form-table'>
      {deleteDialog && <ConfirmDialog title="Delete this item?" text="This action is immediate and cannot be undone. Do you want to delete this item?" 
        open={deleteDialog} 
        hideDialog={hideDeleteDialog} 
        handleConfirm={handleConfirmDelete}
        yesLabel="Delete"
        yesButtonStyle={{backgroundColor: 'red', color: 'white', fontWeight: 'bold'}}
        yesButtonVariant='filled'
        noLabel="Keep"
        noButtonStyle={{backgroundColor: 'green', color: 'white', fontWeight: 'bold'}}
        noButtonVariant='filled'
        />}
      <MaterialReactTable
        columns={columnSets[props.columnSet]}
        data={props.productsData || []}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableEditing={false}
        muiTableContainerProps={{ sx: { minHeight: 300, maxHeight: 400} }}
        
        renderTopToolbar={({ table }) => <Box>
          { props.editMode &&
            <Button color="primary" size="medium" startIcon={<AddIcon />} 
              onClick={() => setShowCreatePanel(true)} 
            >
              Add record
            </Button>
          }
        </Box>}
      />
      { showCreatePanel &&
       <AddRecordModal
        columns={columnSets[props.columnSet]}
        open={!!showCreatePanel}
        onClose={() => setShowCreatePanel(false)}
        onSubmit={(newItem) => props.handleSaveItem(props.itemType, newItem, props.setProductsData)}
        addRow={addRow}
        rowSetter={props.setProductsData}
        setDirty={props.setDirty}
      /> }
      { showEditPanel && 
       <EditRecordModal
        columns={columnSets[props.columnSet]}
        open={!!showEditPanel}
        getCurrentValues={() => tempRow}
        onClose={() => setShowEditPanel(false)}
        onSubmit={(item) => handleSaveRowEdits(props.itemType, item, props.setProductsData)}
        rowSetter={props.setProductsData}
        setDirty={props.setDirty}
      />
      }

    </div>
  )
}
import { Typography } from '@mui/material'

export default function Question(props = {}) {
  if (Array.isArray(props.title)) {
    return (<div>
      {
        (props.title.map((t, tidx) => {
          return <div key={tidx}><Typography>{t}</Typography></div>
        }))
      }
      {props.html && <div dangerouslySetInnerHTML={{ __html: props.html }}></div>}
      {props.children}
    </div>)
  } else {
    switch (typeof props.title) {
      case 'object':
        return (
          <div>
            <ul>
              {
                props.title.list.map((t, tidx) => {
                  return <li key={tidx}><Typography>{t}</Typography></li>
                })
              }
            </ul>
            {props.html && <div dangerouslySetInnerHTML={{ __html: props.html }}></div>}
            {props.children}
          </div>
        )
      default:
        return (
          <div>{props.title}
            {props.html && <span dangerouslySetInnerHTML={{ __html: props.html }}></span>}
            {props.children}
          </div>
        )
    }
  }
}
import { Autocomplete, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useEffect, useState, useContext, useMemo } from 'react'

// props:
// disabled
// getOptionsList
// optionsList
// onChange
// value = newRecord.brandId
// controlLabel = "Brand"
// optionLabelProp = shortName
// optionLabelGetter
// optionIdProp = id
// optionIdGetter

export default function CustomAutoComplete(props = {}) {

  // const [selectedOptionValue, setSelectedOptionValue] = useState()
  const [optionList, setOptionList] = useState([])
  const [optionACList, setOptionACList] = useState([])
  const [typeIn, setTypeIn] = useState()

  const { optionsList: suppliedOptionsList, getOptionsList, optionLabelGetter, optionLabelProp, optionIdProp = 'id', optionIdGetter, sort } = props

  if(suppliedOptionsList && getOptionsList) {
    throw new Error('Props optionsList and getOptionsList are mutually exclusive')
  }
  
  useEffect(() => {
    const compare = ( a, b ) => {
      let valA, valB
      if(typeof a === 'string') {
        valA = a
        valB = b
      } else {
        valA = a[optionLabelProp]
        valB = b[optionLabelProp]
      }
      if ( valA < valB ) return -1
      if ( valA > valB ) return 1
      return 0
    }
    (async () => {
      let list
      if(suppliedOptionsList) {
        list = suppliedOptionsList
      } else {
        await getOptionsList((result) => {
          list = result
        })
      }
      if(sort) {
        const sorted = list.sort(compare)
        setOptionList(sorted)
        setOptionACList([...sorted])
      } else {
        setOptionList(list)
        setOptionACList([...list])
      }
    })()
  }, [suppliedOptionsList, getOptionsList, sort, optionLabelProp])

  const selectedOptionValue = typeIn
  ? typeIn
  : props.value

  return <Autocomplete
    id="brandId"
    size={props.size || 'small'}
    getOptionLabel={(option) => {
      const labelProp = optionLabelGetter ? optionLabelGetter(option) : optionLabelProp
      const val = typeof option === 'string' ? option : option?.[labelProp] || option?.label
      return val || ""
    }}
    disabled={props.disabled}
    // freeSolo
    filterOptions={(x) => x}
    options={optionACList}
    value={selectedOptionValue}
    autoComplete
    includeInputInList
    filterSelectedOptions
    noOptionsText="No results"
    onChange={props.onChange}
    onInputChange={(event, newInputValue) => {
      if(!newInputValue) {
        setOptionACList(optionList)
        setTypeIn()
      } else {
        const inputRe = new RegExp(`.*${newInputValue}.*`, 'ig')
        let addOption = newInputValue
        const options = optionList.filter(f => {
          if(typeof f === 'object') {
            addOption = {id: '__typein', label: newInputValue}
          }
          const labelProp = optionLabelGetter ? optionLabelGetter(newInputValue) : optionLabelProp
          const val = typeof f === 'object' ? f?.[labelProp] || f?.label : f
          return val?.match(inputRe)?.length
        })
        setOptionACList([...options, addOption])
      }
    }}
    renderInput={(params) => (
      <TextField {...params} label={props.controlLabel} fullWidth />
    )}
    renderOption={(renderOptionProps, option) => {
      const idProp = optionIdGetter ? optionIdGetter(option) : optionIdProp
      const id = `${props.controlLabel}-${typeof option === 'string' ? option : option?.[idProp]}`
      const labelProp = optionLabelGetter ? optionLabelGetter(option) : optionLabelProp
      const label = typeof option === 'string' ? option : option?.[labelProp] || option?.label
      return (
        <li key={id} {...renderOptionProps} onClick={(e) => {
          renderOptionProps.onClick(e)
          const existsInList = optionList.find(f => typeof f === 'string' ? f === option : f?.[idProp] === option?.[idProp])
          if(!existsInList) setTypeIn(label)
        }}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
              <Typography variant="body2" color="text.secondary">
                {label}
              </Typography>
            </Grid>
          </Grid>
        </li>
      );
    }}
  />
}
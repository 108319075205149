import { default as React, useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import Page from '../layout/Page'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { TaskAlt, TableRows } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import { getRequestConfig, getToken } from '../auth/auth'
import axios from 'axios'
import { ApiGateway } from '../config/config'
import { Stack } from '@mui/system'

export default function AdminPage(props = {}) {
  const [status, setStatus] = useState()
  const navigate = useNavigate()
  const breadcrumb = <span>Application Administration</span>

  const dialogClose = () => {
    setConfirmDialog(dialogStub)
  }

  const dialogStub = {
    open: false,
    hideDialog: dialogClose,
    title: 'BE ABSOLUTELY SURE',
    text: '',
    yesLabel: 'CONTINUE (FINAL)',
    yesButtonStyle: { backgroundColor: 'darkred' },
    noLabel: 'Cancel',
    noButtonVariant: 'contained',
    noButtonStyle: { backgroundColor: 'darkgreen' },

    handleConfirm: undefined
  }

  const [confirmDialog, setConfirmDialog] = useState(dialogStub)

  const confirmSyncTables = (tableDescription, uri) => {
    setConfirmDialog({
      ...dialogStub,
      text: `${tableDescription} Table(s) will be created/altered, are you sure??`,
      handleConfirm: (async () => {
        requestSync(uri).then(setConfirmDialog(dialogStub))
      }),
      open: true
    })
  }

  const confirmSyncAssessmentTables = () => {
    confirmSyncTables('BU Assessment', `app/admin/models/assessment`)
  }

  const confirmSyncManufacturerProfileTables = () => {
    confirmSyncTables('Manufacturer Profile', `app/admin/models/manufacturer-profile`)
  }

  const confirmSyncCorrectiveActionTables = () => {
    confirmSyncTables('Corrective Action', `app/admin/models/corrective-action`)
  }

  const confirmSyncCPKTables = () => {
    confirmSyncTables('CPK Submission', `app/admin/models/cpk-submission`)
  }

  const confirmSyncIMLogTables = () => {
    confirmSyncTables('IM Log', `app/admin/models/im-log`)
  }

  const confirmSyncProductionVolumeTables = () => {
    confirmSyncTables('Production Volume', 'app/admin/models/production-volume')
  }

  const confirmSyncAuthorizationTables = () => {
    confirmSyncTables('Authorization', 'app/admin/models/authorization')
  }

  const requestSync = async (basePath) => {
    setStatus(undefined)
    const requestConfig = await getRequestConfig()

    try {
      const result = await axios.post(`${ApiGateway.domain}/${basePath}`, {}, requestConfig)
      setStatus(result.data)
    } catch (error) {
      setStatus(error)
    }
  }

  const editAuthorizationLetterTemplate = () => {
    navigate('/app/admin/edit-authorization-letter-template')
  }

  return (
    <>
      <Page {...props} breadcrumb={breadcrumb} >
        <Paper>
          <ConfirmDialog {...confirmDialog}></ConfirmDialog>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'><TableRows/> Sync Table Models - WARNING - applies DDL statements to create or alter tables</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={1} direction='row' flexWrap={'wrap'} padding={1}>
                <Button variant='outlined' onClick={confirmSyncAssessmentTables} >BU Assessment Tables</Button>
                <Button variant='outlined' onClick={confirmSyncManufacturerProfileTables} >Manufacturer Profile Tables</Button>
                <Button variant='outlined' onClick={confirmSyncCorrectiveActionTables} >Corrective Action Tables</Button>
                <Button variant='outlined' onClick={confirmSyncCPKTables} >CPK Submission Tables</Button>
              </Stack>
              <Stack spacing={1} direction='row' flexWrap={'wrap'} padding={1}>
                <Button variant='outlined' onClick={confirmSyncIMLogTables} >IM Log Tables</Button>
                <Button variant='outlined' onClick={confirmSyncProductionVolumeTables}>Production Volume Tables</Button>
                <Button variant='outlined' onClick={confirmSyncAuthorizationTables}>Authorization Tables</Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <span>{JSON.stringify(status, null, 2)}</span>
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'><TaskAlt /> Facility Authorization</Typography>
            </Grid>
            <Stack spacing={1} direction='row' flexWrap={'wrap'} padding={1}>
              <Button variant='outlined' onClick={editAuthorizationLetterTemplate} >Edit Authorization Letter Template</Button>
            </Stack>
          </Grid>
        </Paper>
      </Page>
    </>
  )
}

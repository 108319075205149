import { useState } from 'react'

function BarChart(props={}) {

    const [showTitle, setShowTitle] = useState(props.title !== null && props.title !== undefined && props.title.length)

    return (
        <div className={props.slim ? 'tis-bar-chart-slim' : 'tis-bar-chart'} style={props.style}>
            <div className="inner-wrapper">
                { showTitle && <div className="title">{props.title}</div> }
                <div className="body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
export default BarChart
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import {Col, ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';
// import FormGroupLayout from 'tis-react/lib/Form/FormGroupLayout';

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationState: null,
      validationMessages: null
    };
    // ReactQuill.Quill.register('modules/imageResize', ImageResize);
    this.modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ]
    };
  }

  getClassName() {
    return (this.props.disabled) ? this.props.className + ' disabled' : this.props.className;
  }

  validate() {
    var result = this.props.onValidate(this.props.value);
    var validationState = (result.isValid) ? null : 'error';
    var validationMessage = result.message;
    this.setState({
      validationState,validationMessage
    });
    return result.isValid;
  }

  clearValidation() {
    this.setState({
      validationState:null,
      validationMessage:null
    });
  }

  reset() {
    this.clearValidation();
  }

  render() {
    return (
      <ReactQuill
      disabled={this.props.disabled}
      value={this.props.value}
      onChange={this.props.onChange}
      modules={this.modules}
      theme="snow"
    />
    )
  }
}

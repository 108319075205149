import React from 'react'
import { ApiGateway } from '../../config/config'
import { getRequestConfig, getToken } from '../../auth/auth'
import axios from 'axios'

const AssessmentContext = React.createContext()

export const calculateProgress = (model, itemsList) => {
  let children = []
  let earnedPoints = 0, maxPoints = 0, rated = 0, total = 0
  
  if (['Category', 'Section'].includes(model.type)) {
    children = model.children.map((child) => {
      const [cprog, cdrn] = calculateProgress(child, itemsList)
      earnedPoints += parseFloat(cprog.earnedPoints)
      maxPoints += cprog.maxPoints
      rated += cprog.rated
      total += cprog.total
      return cprog
    })
  }
  if (model.type === 'Item') {
    total = 1
    const item = itemsList.find((i) => i.itemId === model.props.id)
    if (item) {
      const included = item.findingRating && !['na','not_assessed'].includes(item.findingRating)
      if (included) {
        maxPoints = parseFloat(model.props.maxPoints)
        earnedPoints = parseFloat(item.earnedPoints).toFixed(1)
      }
      if (item.findingRating) rated = 1
    } else {
      maxPoints = parseFloat(model.props.maxPoints)        
    }      
  }
  const progress = {
    id: model.props.id,
    title: model.props.title,
    earnedPoints: earnedPoints,
    maxPoints,
    rated,
    total
  }
  return [progress, children]
}

export const loadAssessmentItems = async (assessmentId, requestConfig) => {  
  const basePath = `${ApiGateway.assessments}/items/search`
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }
  const response = await axios.post(basePath, { assessmentId }, requestConfig)
  if (response.data && response.data.results) {
    return response.data.results
  }
  return []
}

export const getSummary = (itemsList, _id, _item) => {
  const item = _item ?? itemsList.find((i) => i.id === _id)
  let earnedPoints = 0
  let maxPoints = 0
  let findingRating = 0
  if (item) {
      earnedPoints = item.earnedPoints
      maxPoints = item.maxPoints
      findingRating = Math.round(earnedPoints * 10000 / maxPoints) / 100
  }
  
  const summary = {
      earnedPoints,
      maxPoints,
      findingRating
  }
  // console.log(summary)

  return summary
}

export default AssessmentContext
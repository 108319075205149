import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import React, { Children, memo, useEffect, useMemo, useRef, useState } from 'react'
import { calculateTotalEarnedPoints, calculateTotalMaxPoints, calculateFindingRating, getPointsClass } from '../../utils/GroupAggregator'
import Bar from '../../charts/Bar'
import BarChart from '../../charts/BarChart'
import AssessmentItem from './AssessmentItem'
import Question from '../../form/Question'
import Guidance from '../../form/Guidance'
import { getSummary } from '../context/AssessmentContext'
import EventBus from '../../utils/EventBus'

export default function AssessmentCategoryForm(props = {categoryModel: undefined}) {
    const { subscribeToProgress, progressItemsList, defaultAssessmentType, refreshItemList } = props
    const assessment = useMemo(() => props.assessment, [props.assessment])
    const category = useMemo(() => props.categoryModel, [props.categoryModel])
    const internalProps = { expandIcon: <ExpandMoreIcon/>, ...props} 

    const TitleBar = memo(function TitleBar( {id, title, earnedPoints = undefined, maxPoints = undefined, findingRating = NaN}) {
        return (
            <Grid container spacing={0} xs={12} md={12}>
                <Grid xs={4} style={{paddingLeft: 0}}>
                    <Typography>{id}. <span>{title}</span></Typography>
                </Grid>
                <Grid xs={2.5}>
                    <Typography>Earned Points: {earnedPoints === undefined ? '--' : earnedPoints}</Typography>
                </Grid>
                <Grid xs={2.5}>
                    <Typography>Max Points: {maxPoints === undefined ? '--' : maxPoints}</Typography>
                </Grid>
                <Grid xs={3}>
                    <Grid xs={6}><Typography>Finding Rating, {isNaN(findingRating) ? '--' : `${findingRating}%`}</Typography></Grid>
                    <Grid xs={6}>
                        <BarChart slim><Bar value={findingRating} className={getPointsClass(findingRating)} /></BarChart>
                    </Grid>
                </Grid>
            </Grid>
        )
    })

    const CategoryLayout = memo(function CategoryLayout({category}) {
        const [itemsList, setItemsList] = useState(progressItemsList)

        const progressUpdate = ( _itemsList ) => {
            setItemsList(_itemsList)
        }
        EventBus.on('PROGRESS', progressUpdate)

        const myRef = useRef(null)
        let isExpanded
        const [expanded, setExpanded] = useState(isExpanded)
        isExpanded = useMemo(() => expanded === true,[expanded])
        const [initialized, setInitialized] = useState(false)
        const [icon, setIcon] = useState((false) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)
        const collapse = () => {
            setIcon(<ExpandMoreIcon/>)
            setExpanded(false)
            if(myRef && myRef.getBoundingClientRect instanceof Function) {
                let pos = myRef.getBoundingClientRect()
                window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
            }
        }
        const toggle = () => {
            setInitialized(true)
            setIcon(isExpanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
            refreshItemList()
            setExpanded(!expanded)
        }

        // useEffect(() => {
        //     // console.log('Category Layout useEffect')
        //     subscribeToProgress(progressUpdate)
        // }, [])

        const summary = useMemo(() => {
            return getSummary(itemsList, category.props.id)
        }, [category, itemsList])


        return (
            <Card className="tis-quality-and-fs-category" sx = {{width: '100%'}}>
            <CardHeader ref={myRef} title={<TitleBar 
                id={category.props.id} 
                title={category.props.title} 
                earnedPoints={summary.earnedPoints || 0} 
                maxPoints={summary.maxPoints || 0}
                findingRating={summary.findingRating || 0}
                // earnedPoints={getSummary(memoItemsList, category.props.id).earnedPoints || 0} 
                // maxPoints={getSummary(memoItemsList, category.props.id).maxPoints || 0}
                // findingRating={getSummary(memoItemsList, category.props.id).findingRating || 0}
                />} onClick={toggle} action={icon}></CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                {initialized && <CardContent className="tis-quality-and-fs-category-content">
                    { category.children.map((section, idx) => {
                        return <SectionLayout section={section} key={idx} />
                    })}

                    <div className="tis-collapse" onClick={collapse}>Collapse {category.props.title} <ExpandLessIcon/></div>
                </CardContent>}
            </Collapse>
        </Card>
        )
    })

    const SectionLayout = memo(function SectionLayout({section}) {
        const [itemsList, setItemsList] = useState(progressItemsList)

        const progressUpdate = ( _itemsList ) => {
            setItemsList(_itemsList)
        }
        EventBus.on('PROGRESS', progressUpdate)

        const myRef = useRef(null)
        const [expanded, setExpanded] = useState(false)
        const [initialized, setInitialized] = useState(false)
        const [icon, setIcon] = useState((false) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)
        const collapse = () => {
            setIcon(<ExpandMoreIcon/>)
            setExpanded(false)
            if(myRef && myRef.getBoundingClientRect instanceof Function) {
                let pos = myRef.getBoundingClientRect()
                window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
            }
        }
        const toggle = () => {
            setInitialized(true)
            setIcon(expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
            setExpanded(!expanded)
        }

        const itemComponentMap = {
            Question: (props) => {
                return useMemo(() => <Question {...props}></Question>, [props])
            },
            Guidance: (props) => {
                return useMemo(() => <Guidance {...props}></Guidance>, [props])
            }
        }

        const itemInclude = (list) => {
            return list.map((comp, compidx) => {
                const CompType = itemComponentMap[comp.type]
                return (
                  <CompType assessment={assessment} {...comp.props} key={comp.props.id}>
                    {comp.children && itemInclude(comp.children)}
                  </CompType>
                  )
              })
        }

        // useEffect(() => {
        //     // console.log('Section Layout useEffect')
        //     subscribeToProgress(progressUpdate)
        // }, [])

        const summary = useMemo(() => {
            return getSummary(itemsList, section.props.id)
        }, [category, itemsList])

        return (
            <>
            { (section.props.visible === undefined || section.props.visible === true) &&
                (  <>                          
                    <Card className="tis-quality-and-fs-section" sx = {{width: '100%'}}>
                        <CardHeader ref={myRef} onClick={toggle} action={icon}
                            title={<TitleBar
                                id={section.props.id} 
                                title={section.props.title} 
                                earnedPoints={summary.earnedPoints} 
                                maxPoints={summary.maxPoints}
                                findingRating={summary.findingRating}
                                // earnedPoints={getSummary(memoItemsList, section.props.id).earnedPoints} 
                                // maxPoints={getSummary(memoItemsList, section.props.id).maxPoints}
                                // findingRating={getSummary(memoItemsList, section.props.id).findingRating}
                                />}
                        />
                        <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                            {initialized && <CardContent>
                                {section.children.map((item) => {
                                    return <AssessmentItem 
                                            assessment={assessment} {...item.props}
                                            onChangePoints={() => {return}}
                                            refreshItemList={props.refreshItemList}
                                            key={item.props.id}
                                            defaultAssessmentType={defaultAssessmentType} >
                                                { item.children && itemInclude(item.children)}
                                        </AssessmentItem>
                                })}
                                <div className="tis-collapse" onClick={collapse}>Collapse {internalProps.title} <ExpandLessIcon/></div>
                            </CardContent>}
                            <div className="tis-collapse" onClick={collapse}>Collapse {section.props.title} <ExpandLessIcon/></div>
                        </Collapse>
                    </Card>
                </>)
            }
            { (section.props.visible !== undefined && section.props.visible === false) &&
                <div>{section.children.map((item) => {
                    return <AssessmentItem
                            assessment={assessment} {...item.props}
                            onChangePoints={() => {return}}
                            refreshItemList={props.refreshItemList}
                            key={item.props.id}
                            defaultAssessmentType={defaultAssessmentType}>
                                { item.children && itemInclude(item.children)}
                            </AssessmentItem>
                                
                })}</div>
            }
            
            </>
        )
        
    })

    return (
        <CategoryLayout category={category}/>
    )
}
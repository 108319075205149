import { default as React, useState, useRef, useEffect, useMemo, createRef, useCallback } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import Page from '../layout/Page'
import { Button, Grid, Paper, Select, Typography, FormControl, InputLabel, MenuItem } from '@mui/material'
import { TaskAlt, TableRows } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import { getRequestConfig, getToken } from '../auth/auth'
import axios from 'axios'
import { ApiGateway } from '../config/config'
import { Stack } from '@mui/system'
import RichEditor from '../form/RichEditor'
import AuthorizationLetterTemplate from '../authorizations/AuthorizationLetterTemplate'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

const ALERT_DURATION = 1000

export default function EditAuthorizationSettingsPage(props = {}) {
  const [status, setStatus] = useState()
  const [editorValue, setEditorValue] = useState('')
  const [currentField, setCurrentField] = useState('')
  const [templateUpdates, setTemplateUpdates] = useState({})
  const [loading, setLoading] = useState(true)
  const [templateData, setTemplateData] = useState([])
  const [templateRefs, setTemplateRefs] = useState({})
  const [showVariableOptions, setShowVariableOptions] = useState(false)

  const navigate = useNavigate()
  const breadcrumb = <span><span onClick={e => navigate('/app/admin')}> <a href="#">Application Administration</a></span> {'>'} Edit Authorization Letter Template</span>

  const letterRef = useRef()
  const headerRef = useRef()
  const bodyRef = useRef()
  const footerRef = useRef()

  const mfrProfile = {
    "plantManagerName": "Example Manager",
    "plantManagerPhone": "+1 1111111111",
    "plantManagerEmail": "plantmanager@fakeplant.tis.coke.com",
    "Manufacturer": {
      "manufacturerName": "Test Faciliy",
      "manufacturerAddr": "1234 Central Avenue",
      "manufacturerCity": "Test City",
      "manufacturerState": "GA",
      "manufacturerZip": "30333",
    }
  }

  const approvalCategories = [
    {
      "id": "TEST_CATEGORY",
      "displayOrder": 10,
      "label": "Process Type",
    },
  ]

  const onSave = async () => {
    setLoading(true)
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.authorizations}/letter/templates`
    const newTemplates = Object.keys(templateUpdates).reduce((s, key) => {
      s.push({
        id: key,
        value: templateUpdates[key]
      })
      return s
    }, [])
    const response = await axios.put(basePath, newTemplates, requestConfig)
    const { results } = response.data
    bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": ALERT_DURATION })
    setLoading(false)
  }

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorizations}/letter/templates`
      const response = await axios.get(basePath, requestConfig)
      const { results } = response.data
      setTemplateData(results)
      setLoading(false)
    }
    load()
  }, [])

  useEffect(() => {
    const templateRefs = templateData.reduce((s, field) => {
      s[field.id] = createRef()
      return s
    }, {})
    setTemplateRefs(templateRefs)
  }, [templateData])

  const onEditorUpdate = useCallback((e) => {
    setEditorValue(e)
    const _templateUpdates = { ...templateUpdates }
    _templateUpdates[currentField] = e
    setTemplateUpdates(_templateUpdates)
  }, [currentField, templateUpdates])

  useEffect(() => {
    const calculatedValue = templateUpdates[currentField] || templateData.find(f => f.id === currentField)?.value
    if (editorValue !== calculatedValue) {
      setEditorValue(calculatedValue)
    }
    templateData.forEach(field => {
      const ref = templateRefs[field.id]
      if (ref?.current) {
        ref.current.className = ref.current.className.replace(/letter-edit-highlighted/g, '')
      }
    })

    const ref = templateRefs[currentField]
    if (ref?.current?.className?.indexOf('letter-edit-highlighted') === -1) {
      ref.current.className = `letter-edit-highlighted ${ref.current.className}`
    }
  }, [currentField, templateData, templateRefs, templateUpdates, editorValue])

  return (
    <>
      <Page
        {...props}
        breadcrumb={breadcrumb}
        isLoading={loading}
        commandBar={
          <FormViewCommandBar
            loading={loading}
            onSave={onSave}
            enableSave={true}
            showClose={false}
            onCancel={() => navigate('/app/admin')}
          />}
      >
        <Paper>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={3} sx={{ position: 'fixed', top: '100px' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Section</InputLabel>
                <Select
                  fullWidth
                  name="currentField"
                  value={currentField}
                  onChange={e => setCurrentField(e.target.value)}
                >
                  <MenuItem key='none' value=''>&nbsp;</MenuItem>
                  {templateData.sort((a,b) => a.displayOrder - b.displayOrder).map((field, i) =>
                    <MenuItem key={field.id} value={field.id}>{field.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <br /><br />
              <RichEditor
                disabled={false}
                value={editorValue}
                onChange={onEditorUpdate}
              />
              <br />
              <Typography variant='body'>Instructions</Typography>
                <ul className='MuiTypography-body2'>
                <li>Select a section from the "Section" dropdown above and the affected section will be highlighted in the preview on the right.</li>
                <li>Modify/format the text using the editor above.</li>
                <li>You can substitute dynamic values by using the {'{variable}'} syntax in the template.</li>
                <li>To see a list of possible variables for substition, click "See Variable Options" below.</li>
                <li>Click "SAVE" in the command bar to save your changes to the template.</li>
                <li>WARNING - your changes will affect all letters created by anyone on the QSE team.</li>
                </ul>
              <Button onClick={() => setShowVariableOptions(true)}>See Variable Options</Button>
            </Grid>
            <Grid item xs={4} >
              &nbsp;
            </Grid>
            <Grid item xs={7} sx={{ border: '1px solid #ccc', margin: '5px', padding: '5px' }}>
              {!loading && <AuthorizationLetterTemplate
                showVariableOptions={showVariableOptions}
                onCloseVariableOptions={() => setShowVariableOptions(false)}
                letterRef={letterRef}
                headerRef={headerRef}
                bodyRef={bodyRef}
                footerRef={footerRef}
                signatureStyle={'barokah'}
                onClickSign={() => { }}
                authorization={{
                  "approvalStatus": "FULL",
                  "issuedDate": "2024-01-03",
                  "reviewedDate": "2024-02-07",
                  "revisionNumber": 5,
                  "revisionDate": "2024-03-05",
                  "signatureName": "QSE Manager",
                  Approvals: [
                    {
                      "categoryId": "TEST_CATEGORY",
                      "displayOrder": 10,
                      "description": "Test approval line-item",
                      "date": "2024-08-08",
                      "approvalStatus": "PROVISIONAL",
                    },
                  ]
                }}
                mfrProfile={mfrProfile}
                approvalCategories={approvalCategories}
                date={'2024-01-02'}
                signed={true}
                templateData={templateData.map(item => {
                  if (templateUpdates[item.id]) {
                    return { ...item, value: templateUpdates[item.id] }
                  }
                  return item
                })}
                templateRefs={templateRefs}
              />}
            </Grid>

          </Grid>
        </Paper>
      </Page>
    </>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { ApiGateway } from '../config/config.js'
import {MaterialReactTable} from 'material-react-table'
import { Paper } from '@mui/material'
import axios from 'axios'
import { getRequestConfig } from '../auth/auth.js'
import { Link } from 'react-router-dom'

export default function ListCAPs (props = {search: { mfrProfileId: undefined, ownershipId: undefined }, showFilters: false, setIsLoading: (b) => {}}) {
  const [capList, setCapList] = useState([])

  const startLoading = () => {
    props.setIsLoading(true)
  }

  const stopLoading = () => {
    props.setIsLoading(false)
  }

  const searchCorrectiveActionPlans = async() => {
    startLoading()
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.correctiveAction}/plan/search`
    
    const search = {}

    if (props.search?.mfrProfileId) {
      search.mfrProfileId = props.search.mfrProfileId
    } else if (props.search?.ownershipId) {
      search.ownershipId = props.search.ownershipId
    } else {
      search.byMyKoId = true
    }

    const response = await axios.post(basePath, search, requestConfig)
    
    const rows = response.data.results
    
    setCapList(rows.toSorted((a, b) => {
      if (a.status === 'Closed' && b.status === 'Open') return 1
      return a.assessmentId > b.assessmentId ? -1 : a.assessmentId < b.assessmentId ? 1 : 0
    }))
    stopLoading()
    return
  }

  const capColumns = useMemo(() => [
    {
      accessorKey: 'ManufacturerProfile.Manufacturer.manufacturerName', 
      header: 'Facility',
      grow: true,
      editable: false,
      enableGrouping: false,
      Cell: ({ cell, row }) => {
        const facilityLabel = [
          row.original.ManufacturerProfile.Manufacturer.manufacturerId,
          row.original.ManufacturerProfile.Manufacturer.manufacturerName
        ].join(' - ')

        if (['open','closed'].includes(row.original.status.toLowerCase())) {
          return (
          <Link to={`/corrective-action-plan/${row.original.assessmentId}/${row.original.id}`}>
            { facilityLabel }
          </Link>
          )
        } else {
          return facilityLabel
        }
      }
    },
    { 
      accessorKey: 'status', 
      header: 'Status',
      filterVariant: 'multi-select',
      filterSelectOptions: ["Open", "Closed"], 
      size: 75, 
      editable: false,
      enableGrouping: false
    },
    { 
      accessorKey: 'createdAt', 
      header: 'Created Date',
      size: 100, 
      editable: false,
      enableGrouping: false,
      Cell: ({ cell, row }) => {
        return new Date(cell.getValue()).toLocaleDateString()
      }
    },
    { 
      accessorKey: 'assessmentId', 
      header: 'Related Assessment',
      size: 100, 
      editable: false,
      enableGrouping: false,
      Cell: ({cell, row}) => (
            <Link to={`/assessments/group/${cell.getValue()}`}>
              {cell.getValue()}
              {/* <Button variant="text">{cell.getValue()}</Button> */}
            </Link>
            )
    }
  ], [])

  useEffect(() => {
    searchCorrectiveActionPlans()
  }, [props.search?.mfrProfileId, props.search?.ownershipId])

  return (
    <Paper  sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto'}}>
    <MaterialReactTable
      layoutMode='grid'
      columns={capColumns}
      data={capList}
      enableColumnActions={false}
      enableFilters={true}
      enableColumnFilters={props.showFilters}
      initialState={{ showColumnFilters: true }}
      enablePagination={false}
      enableSorting={true}
      enableBottomToolbar={true}
      enableEditing={false}
      muiTableContainerProps={{ sx: { minHeight: 300 } }}
      muiTableBodyCellProps={{ sx: { padding: '8px !important'}}}
      renderTopToolbar={false}
    />
    </Paper>
  )

}
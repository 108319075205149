import * as React from 'react';
import { useEffect, useContext } from 'react';
import HelpPage from '../help/HelpPage';
import adminHelp from '../help/pages/im-logs/Admin.md'
import facilityBottlerHelp from '../help/pages/im-logs/FacilityBottler.md'
import corpBottlerHelp from '../help/pages/im-logs/CorpBottler.md'
import RoleContext from '../auth/RoleContext'
import { ROLES } from '../auth/role'

export default function IMLogsHelpPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const isFacilityBottler = roleDetails.hasRole([ROLES.BOTTLER])
  const isCorpBottler = roleDetails.hasRole([ROLES.BOTTLER_CORP])
  const isAdmin = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])

  const helpFile = isFacilityBottler ? facilityBottlerHelp : isCorpBottler ? corpBottlerHelp : isAdmin ? adminHelp : undefined
  const [markdown, setMarkdown] = React.useState('')

  useEffect(() => {
    (async () => {
      const mdResponse = await fetch(helpFile)
      const mdText = await mdResponse.text()
      setMarkdown(mdText)
    })()
  }, [])

  return (
    <HelpPage
      pageName="Incident Management Help"
      markdown={markdown}
      {...props}
    />
  )
}
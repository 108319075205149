import { default as React, useEffect, useState, useMemo, useContext } from 'react'
import { Grid, IconButton, Typography  } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { LoadingButton } from '@mui/lab'
import * as complaintLabels from './ComplaintLabels.json'
import { RefreshOutlined } from '@mui/icons-material'

export default function ComplaintTrendComplaintsList(props = { complaintTrendId: undefined, complaintList: undefined, complaintListCallback: undefined }) {

  const [complaintList, setComplaintList] = useState([])
  const [cloading, setCloading] = useState(false)

  const tableVisibleColumns = {
    caseCreatedDate: {
      size: 80,
      id: 'caseCreatedDate',
      header: 'Received',
      accessorFn: (row) => new Date(row.CREATEDDATE).getTime(),
      Cell: ({cell}) => new Date(cell.getValue()).toLocaleDateString('en-US', {timeZone: 'UTC'})
    },
    FINAL_CATEGORY_LEVEL_3: {
      header: 'Category 3',
      size: 100
    },
    FINAL_CATEGORY_LEVEL_4: {
      header: 'Category 4',
      size: 150
    },
    PRODUCT_NAME: {
      grow: true
    },
    PRODUCTION_DATE: {
      header: 'Prd.On',
      size: 80,
      grow: false,
    },
    PRODUCTION_TIME: {
      header: 'At',
      size: 80,
      grow: false
    },
    DAY_CODE: {
      header: 'Day',
      size: 100,
      grow: false
    }
  }

  // ComplaintCategory: {
  //   id: 'category',
  //   header: 'Complaint Category',
  //   grow: true,
  //   accessorFn: (row) => {
  //     return [row.FINAL_CATEGORY_LEVEL_3, row.FINAL_CATEGORY_LEVEL_4].join(' - ')
  //   }
  // },

  const neverVisible = ['CYCLE_MONTH','MONTH','YEAR','WEEK_OF_YEAR','FISCAL_WEEK', 'ID']

  const columnConversions = {
    CREATEDDATE: {
      type: 'datetime'
    },
    UPC_NUMBER: {
      type: 'string'
    },
    PURCHASE_DATE: {
      type: 'date'
    },
    PRODUCTION_DATE: {
      type: 'date'
    },
    LASTMODIFIEDDATE: {
      type: 'datetime'
    },
    BEYOND_CAN_WARRANTY: {
      type: 'boolean'
    },
    BEYOND_OPTIMUM_TASTE_DATE: {
      type: 'boolean'
    },
    ILLNESS: {
      type: 'boolean'
    },
    INJURY: {
      type: 'boolean'
    },
    RECEIVED_MEDICAL_TREATMENT: {
      type: 'boolean'
    }
  }
  const loadTrendComplaints = async (refresh = false) => {
    if ( props.complaintList === undefined || props.complaintList.length === 0 || refresh) {
      setCloading(true)
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.complaintTrends}/${props.complaintTrendId}/complaints`
      try {
        const results = await axios.get(basePath, requestConfig)
        if (results.data) {
          const resultList = results.data.results ?? []
          setComplaintList(resultList)
          if (props.complaintListCallback !== undefined) {
            props.complaintListCallback(resultList)
          }
        }
      } catch (error) {
        console.error(error)
        setComplaintList([])
        if (props.complaintListCallback !== undefined) {
          props.complaintListCallback([])
        }
      } finally {
        setCloading(false)
      }
    } else {
      setComplaintList(props.complaintList)
    }
  }

  const getFormattedColumnValue = (column, value) => {
    if (value && Object.keys(columnConversions).includes(column)) {
      switch (columnConversions[column].type) {
        case 'boolean':
          if (isNaN(value)) return value
          return parseInt(value) ? 'Yes' : 'No'
        case 'date':
          return new Date(value).toLocaleDateString('en-US', {timeZone: 'UTC'})
        case 'datetime':
          const date = new Date(value)
          const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
            timeZone: 'UTC'
          }
          const formatter = new Intl.DateTimeFormat('en-US', options);
          return formatter.format(date)
        case 'string':
          return value.toString()
      }
    } else {
      return value
    }
  }

  const columns = useMemo(() => Object.entries(tableVisibleColumns).map(([c,v]) => {
    return {
      accessorId: c,
      accessorFn: (row) => getFormattedColumnValue(c, row[c]),
      header: complaintLabels[c] ?? '',
      ...v
    }
  }), [complaintList])

  const renderDetailPanel = ( {row} ) => {
    const set = Object.entries(row.original)
    const lth = parseInt((set.length + neverVisible.length) / 2)
    const left = set.slice(0, lth)
    const right = set.slice(lth)
   return (
    <Grid container padding={1}>
        { [left,right].map(( col, idx ) => (
          <Grid item xs={12} md={6} key={idx}>
            <Grid container columnSpacing={1}>
            { 
              col.reduce((p, [ c, v ], i) => {
                if (neverVisible.includes(c)) return p
                p.push(
                  <>
                  <Grid item xs={6} sx={{ textAlign: 'right'}}>
                  <Typography variant='body2' key={`label${i}`}><strong>{complaintLabels[c]}:</strong></Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' key={`value${i}`}>{getFormattedColumnValue(c, v)}</Typography>
                  </Grid>
                  </>
                )
                return p
              }, []) 
            }
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  )}

  const renderToolbarInternalActions = ({ table }) => (
    <>
      <IconButton title='Refresh List' onClick={() => loadTrendComplaints(true)}>
        <RefreshOutlined />
      </IconButton>
      <MRT_ToggleGlobalFilterButton table={table}/>
      <MRT_ToggleFullScreenButton table={table} />
    </>
  )


  useEffect(() => {
    (async () => {
      if (props.complaintTrendId) {
        await loadTrendComplaints()
        }
      }
    )()
  }, [props.complaintTrendId])

  const table = useMaterialReactTable({
    data: complaintList,
    columns: columns,
    layoutMode: 'grid',
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnDragging: false,
    enableSorting: true,
    enableColumnActions: false,
    displayColumnDefOptions: {'mrt-row-expand': { size: 40, grow: false } },
    renderDetailPanel,
    renderToolbarInternalActions,
    muiTableHeadCellProps: {
      sx: {
        fontSize: '.8rem'
      }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.8rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: true,
    initialState: {
      sorting: [
        {
          id: 'caseCreatedDate',
          desc: false
        }
      ]
    }
  });

  return (
    
      <Grid container columnGap={1} rowGap={2} spacing={1}>
        <Grid item xs={12} >
        { !cloading && complaintList && complaintList.length > 0 && 
        
          <MaterialReactTable table={table} />
        }
        {
          !cloading && ( !complaintList || complaintList.length === 0 ) &&
          <Typography variant='body1'>No Results</Typography>
        }
        { cloading && 
          <LoadingButton sx={{padding: 2, textTransform: 'capitalize'}} loading={cloading}>Loading</LoadingButton>
        }
        </Grid>
      </Grid>
  )
}
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import DoubleArrowUp from '@mui/icons-material/KeyboardDoubleArrowUp';
import DoubleArrowDown from '@mui/icons-material/KeyboardDoubleArrowDown';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { getPointsClass } from '../../utils/GroupAggregator'
import Question from '../../form/Question'
import Guidance from '../../form/Guidance'
import { getSummary } from '../../assessments/context/AssessmentContext'
import CAPItem from './CAPItem'
import { Button, Stack } from '@mui/material'
import { RATING_LABELS } from '../../form/formUtils'
import CAPStatusGlobe from './CAPStatusGlobe'

export default function CAPItemListForm(props = {
        assessmentId: undefined,
        correctiveActionPlan: undefined,
        categoryModel: undefined,
        assessmentItemList: undefined
    }) {
    const { subscribeToProgress, progressItemsList } = props
    const assessmentId = props.assessmentId
    const category = useMemo(() => props.categoryModel, [props.categoryModel])

    const includedSections = props.correctiveActionPlan.CorrectiveActionItems.map((item) => {
        return item.itemId.split('.').slice(0,2).join('.')
    })

    const TitleBar = memo(function TitleBar( {id, title, earnedPoints = undefined, maxPoints = undefined, findingRating = NaN, itemFindingRating = undefined}) {
        return (
            <Grid container spacing={0} xs={12} md={12} className='tis-cap-title-bar'>
                <Grid xs={7}>
                    <Typography>{id}. <span>{title}</span></Typography>
                </Grid>
                { (earnedPoints !== undefined) && (maxPoints !== undefined) &&
                <>
                <Grid xs={1} className={getPointsClass(findingRating)}>
                    <div className='tis-cap-assessment-rating'>
                        <Typography fontWeight='bold'>{isNaN(findingRating) ? '--' : `${findingRating}%`}</Typography>
                    </div>
                </Grid>
                <Grid xs={4} className='tis-cap-title-points'>
                    <Typography>{earnedPoints}&nbsp;/&nbsp;{maxPoints}</Typography>
                </Grid>
                </>
                }                
            </Grid>
        )
    })

    const CategoryLayout = memo(function CategoryLayout({category}) {
        const [itemScoresList, setItemScoresList] = useState(progressItemsList)

        const progressUpdate = ( _itemScoresList ) => {
            setItemScoresList(_itemScoresList)
        }        

        const myRef = useRef(null)
        let isExpanded = true
        const [expanded, setExpanded] = useState(isExpanded)
        isExpanded = useMemo(() => expanded === true,[expanded])
        const [initialized, setInitialized] = useState(expanded)
        const [icon, setIcon] = useState((false) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)
        const collapse = () => {
            setIcon(<ExpandMoreIcon/>)
            setExpanded(false)
            if(myRef && myRef.getBoundingClientRect instanceof Function) {
                let pos = myRef.getBoundingClientRect()
                window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
            }
        }
        const toggle = () => {
            setInitialized(true)
            setIcon(isExpanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
            setExpanded(!expanded)
        }

        useEffect(() => {
            // console.log('Category Layout useEffect')
            subscribeToProgress(progressUpdate)
        }, [])


        return (
            <Card className="tis-cap-category">
            <CardHeader ref={myRef} title={<TitleBar 
                id={category.props.id} 
                title={category.props.title} 
                earnedPoints={getSummary(itemScoresList, category.props.id).earnedPoints || 0} 
                maxPoints={getSummary(itemScoresList, category.props.id).maxPoints || 0}
                findingRating={getSummary(itemScoresList, category.props.id).findingRating || 0}
                />} onClick={toggle} action={icon}></CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
                {initialized && <CardContent className="tis-cap-category-content">
                    { category.children.reduce((p, section, idx) => {
                        if (includedSections.includes(section.props.id)) {
                            p.push(<SectionLayout section={section} key={idx} />)
                        }
                        return p
                    },[])}

                    {/* <div className="tis-collapse" onClick={collapse}>Collapse {category.props.title} <ExpandLessIcon/></div> */}
                </CardContent>}
            </Collapse>
        </Card>
        )
    })

    const SectionLayout = memo(function SectionLayout({section}) {
        const [itemScoresList, setItemScoresList] = useState(progressItemsList)

        const progressUpdate = ( _itemScoresList ) => {
            setItemScoresList(_itemScoresList)
        }        

        const myRef = useRef(null)
        const [expanded, setExpanded] = useState(true)
        const [initialized, setInitialized] = useState(true)
        const [icon, setIcon] = useState((false) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)
        const collapse = () => {
            setIcon(<ExpandMoreIcon/>)
            setExpanded(false)
            if(myRef && myRef.getBoundingClientRect instanceof Function) {
                let pos = myRef.getBoundingClientRect()
                window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
            }
        }
        const toggle = () => {
            setInitialized(true)
            setIcon(expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
            setExpanded(!expanded)
        }

        

        useEffect(() => {
            // console.log('Section Layout useEffect')
            subscribeToProgress(progressUpdate)
        }, [])

        return (
            <>
            { (section.props.visible === undefined || section.props.visible === true) &&
                (  <>                          
                    <Card className="tis-cap-section">
                        <CardHeader ref={myRef} onClick={toggle} action={icon}
                            title={<TitleBar
                                id={section.props.id} 
                                title={section.props.title} 
                                earnedPoints={getSummary(itemScoresList, section.props.id).earnedPoints} 
                                maxPoints={getSummary(itemScoresList, section.props.id).maxPoints}
                                findingRating={getSummary(itemScoresList, section.props.id).findingRating}
                                />}
                        />
                        <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
                            {initialized && <CardContent>
                                {section.children.reduce((p, item) => {
                                    const correctiveActionItem = props.correctiveActionPlan.CorrectiveActionItems.find((capItem) => capItem.itemId === item.props.id)
                                    if (correctiveActionItem) {
                                        p.push(<CAPItemLayout
                                            assessmentId={assessmentId}
                                            correctiveActionItem={correctiveActionItem}
                                            correctiveActionPlan={props.correctiveActionPlan}
                                            item={item}
                                            {...item.props}
                                            onChangePoints={() => {return}}
                                            key={item.props.id} />
                                        )
                                    }
                                    return p
                                }, [])}
                                {/* <div className="tis-collapse" onClick={collapse}>Collapse {internalProps.title} <ExpandLessIcon/></div> */}
                            </CardContent>}
                            {/* <div className="tis-collapse" onClick={collapse}>Collapse {section.props.title} <ExpandLessIcon/></div> */}
                        </Collapse>
                    </Card>
                </>)
            }
            { (section.props.visible !== undefined && section.props.visible === false) &&
                <div>{section.children.reduce((p, item) => {
                    const correctiveActionItem = props.correctiveActionPlan.CorrectiveActionItems.find((capItem) => capItem.itemId === item.props.id)
                    if (correctiveActionItem) {
                        p.push(<CAPItemLayout                                             
                            assessmentId={assessmentId}
                            correctiveActionItem={correctiveActionItem}
                            correctiveActionPlan={props.correctiveActionPlan}
                            item={item}
                            {...item.props}
                            key={item.props.id} />
                        )
                    }
                    return p
                }, [])}</div>
            }
            
            </>
        )
        
    })

    const CAPItemLayout = memo(function CAPItemLayout({assessmentId, item, correctiveActionItem}) {
        const myRef = useRef(null)

        const getIcon = (isExpanded) => {
            return isExpanded ? <DoubleArrowUp/> : <DoubleArrowDown/>
        }

        const [expanded, setExpanded] = useState(false)
        const [initialized, setInitialized] = useState(false)
        const [icon, setIcon] = useState(getIcon(false))
        const [capItem, setCapItem] = useState(correctiveActionItem)
        const collapse = () => {
            setIcon(getIcon(false))
            setExpanded(false)
            if(myRef && myRef.getBoundingClientRect instanceof Function) {
                let pos = myRef.getBoundingClientRect()
                window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
            }
        }        

        const toggle = () => {
            setInitialized(true)
            setIcon(getIcon(!expanded))
            setExpanded(!expanded)
        }

        const itemComponentMap = {
            Question: (props) => {
                return useMemo(() => <Question {...props}></Question>, [props])
            },
            Guidance: (props) => {
                return useMemo(() => <Guidance {...props}></Guidance>, [props])
            }
        }

        const getStatusClass = () => {
            let status = capItem.status.replace(/_/g,'-')
            if (status === 'in-process' && capItem.targetCompletionDate) {
                if (new Date(capItem.targetCompletionDate).getTime() < new Date().getTime()) {
                    status = status + '-late'
                }
            }
            return `tis-cap-status-globe cap-${status}`
        }

        const ItemTitleBar = memo(function ItemTitleBar( {id, title, itemFindingRating = undefined, icon = undefined}) {
            return (
                <Grid container className='tis-cap-title-bar'>
                    <Grid xs={7}>
                        <Stack direction='row'>
                            <CAPStatusGlobe key={item.props.id} status={capItem.status} targetDate={capItem.targetCompletionDate}></CAPStatusGlobe>
                            <Typography>{id}. <span>{title}</span></Typography>
                        </Stack>
                    </Grid>                    
                    <Grid xs={3} className={itemFindingRating.replace(/_/g,'-')}>
                        <div className='tis-cap-assessment-rating'>
                            <Typography fontWeight='bold'>{RATING_LABELS[itemFindingRating]}</Typography>
                        </div>
                    </Grid>
                    <Grid xs={2} sx={{textAlign: 'right'}}>
                        <Button variant='outlined' onClick={toggle}>{expanded ? 'Close' : 'Open'}&nbsp;Plan&nbsp;{icon}</Button>
                        {/* <Typography>{ icon }&nbsp;CAP&nbsp;Status:&nbsp;{CAP_STATUS_LABELS[correctiveActionItem.status]}</Typography> */}
                    </Grid>                
                </Grid>
            )
        })

        const itemInclude = (list) => {
            return list.map((comp, compidx) => {
                const CompType = itemComponentMap[comp.type]
                return (
                  <CompType {...comp.props} key={comp.props.id}>
                    {comp.children && itemInclude(comp.children)}
                  </CompType>
                  )
              })
        }

        const assessmentItem = props.assessmentItemList.find((i) => i.itemId === item.props.id)

        return (
            <>            
                <Card className="tis-cap-item-card">                    
                    <CardHeader ref={myRef}
                        title={<ItemTitleBar
                            id={item.props.id}
                            title={item.props.title}
                            icon={icon}
                            itemFindingRating={assessmentItem.findingRating}
                            statusClass={getStatusClass()}
                            />}
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
                        {initialized && <CardContent>
                            <CAPItem
                                assessmentId={assessmentId}
                                correctiveActionItem={capItem}
                                correctiveActionPlan={props.correctiveActionPlan}
                                itemModel={item}
                                assessmentItem={assessmentItem}
                                onWorkflowUpdate={setCapItem}
                                {...item.props}
                                onChangePoints={() => {return}}
                                key={item.props.id} >
                                    { item.children && itemInclude(item.children)}
                            </CAPItem>
                            <div className="tis-collapse" onClick={collapse}>Collapse&nbsp;{item.props.id}&nbsp;{item.props.title}<ExpandLessIcon/></div>
                        </CardContent>}
                    </Collapse>
                </Card>
            </>
        )

    })

    useEffect(() => {
        // console.log('AssessmentCategoryForm useEffect')
    }, [])

    return (
        <>
        {/* <Grid container spacing={0} xs={12} md={12} className='tis-cap-items-header-bar'>
                <Grid xs={7}>
                    <Typography>Assessment Topic</Typography>
                </Grid>                
                <Grid xs={1} className='tis-cap-assessment-rating'>
                    <Typography>Rating</Typography>
                </Grid>
                <Grid xs={4} className='tis-cap-title-points'>
                    <Typography>Score / Max</Typography>
                </Grid>
        </Grid> */}
        <CategoryLayout category={category} correctiveActionPlan={props.correctiveActionPlan} />
        </>
    )
}
import { default as React, useContext, useState, useMemo, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Factory } from '@mui/icons-material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, IconButton, Paper } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Unstable_Grid2'
import axios from 'axios'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import ProfileSelectionControl from './ProfileSelectionControl'

export default function ListProductionVolumesPage(props = {}) {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const roleDetails = useContext(RoleContext)
  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [dataRows, setDataRows] = useState([])

  const listColumns = useMemo(() => [
    {
      accessorKey: 'volumeDate',
      header: 'Volume Date',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      size: 120,
      editable: false,
      filterVariant: 'date',
      grow: false,
      Cell: ({ cell }) => (
        <span>{new Date(cell.getValue()).toISOString().split('T')[0]}</span>
      )
    },
    {
      accessorKey: 'productionCode',
      header: 'Prod. Code',
      size: 120,
      muiTableBodyCellProps: {
        align: 'center',
      },
      editable: false,
      filterVariant: 'text',
      grow: false
    },
    {
      accessorKey: 'volumeAmount',
      header: 'Volume',
      size: 100,
      grow: false,
      editable: false,
      filterVariant: 'number',
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => (
        <span>{new Intl.NumberFormat().format(cell.getValue())}</span>
      )
    },
    {
      accessorKey: 'createdByName',
      header: 'Reported By',
      editable: false,
      filterVariant: 'text',
      minSize: 100,
      grow: true,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      columnDefType: 'display',
      size: 10,
      grow: false,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem' }}>
          {roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && 
            <Tooltip arrow placement="left" title="Edit">
              <Link to={`/manufacturer/production-volume/${mfrProfileId}/${row.original.id}`}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          }
        </Box>
      )
    }
  ],[mfrProfileId, roleDetails])

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><Factory />&nbsp;Production Volumes</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const addRecord = () => {
    navigate(`/manufacturer/production-volume/${mfrProfileId}/create`)
  }

  const performSelectManufacturer = async (_mfrProfileId) => {
    setMfrProfileId(_mfrProfileId)
    setDataRows([])
    if (!_mfrProfileId) {
      navigate(`/manufacturer/production-volumes`)
    } else {
      navigate(`/manufacturer/production-volumes/${_mfrProfileId}`)
    }
  }

  const table = useMaterialReactTable({
    columns: listColumns,
    data: dataRows,
    renderTopToolbar: false,
    enableColumnFilters: showFilters,
    enableHiding: false,
    initialState: {
      showColumnFilters: true,
      columnVisibility: {
        actions: roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) ? true : false
      }
    },
    enablePagination: false,
    enableSorting: true,
    enableBottomToolbar: true,
    enableColumnDragging: false,
    enableColumnActions: false,
    layoutMode: 'grid',
    muiTableContainerProps: { sx: { minHeight: 300 } },
  })

  const getDataRows = async (mfrProfileId) => {
    startLoading()

    const basePath = `${ApiGateway.manufacturer}/${mfrProfileId}/production-volume/search`
    const requestConfig = await getRequestConfig()
    const response = await axios.post(basePath, {}, requestConfig)
    const rows = response.data

    setDataRows(rows)
    stopLoading()
    return
  }

  const initializePage = async () => {
    if (mfrProfileId) {
      await getDataRows(mfrProfileId)
    } else {
      stopLoading()
    }
  }

  useEffect(() => {
    initializePage()
  }, [mfrProfileId])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        <ListViewCommandBar
          showFilters={true}
          toggleFilters={toggleFilters}
          addRecord={addRecord}
          showAdd={roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR, ROLES.BOTTLER]) && mfrProfileId !== undefined}
        />
      }>
      

      <Paper sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em .5em', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', }}>
        <Grid container rowGap={1}>
          <Grid item xs={12}>
          <ProfileSelectionControl defaultMfrProfileId={mfrProfileId} onSelectProfile={performSelectManufacturer} searchParams={{ isNorthstar: true }}/>
          </Grid>
          <Grid item xs={12}>
          <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>

    </Page>
  )
}
import { default as React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TaskAlt } from '@mui/icons-material'
import ListAuthorizations from './ListAuthorizations'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'

export default function ListAuthorizationsPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><TaskAlt/>&nbsp;Facility Authorizations</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const addRecord = () => {
    navigate('/facility-authorizations/create')
  }

  return (
    <RoleContext.Consumer>
      { roleCtx => (
        <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
          commandBar={
            <ListViewCommandBar
              helpPage="/facility-authorizations/help"
              helpPageState={{
                backTo: '/facility-authorizations/list',
              }}
              loading={isLoading}
              showFilters={true}
              toggleFilters={toggleFilters} 
              addRecord={addRecord} 
              showAdd={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])}              
            />
          }>
            
          <ListAuthorizations showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} />
        </Page>
      )}
    </RoleContext.Consumer>
  )
}
import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import CloseIcon from '@mui/icons-material/Close'
import Popper from '@mui/material/Popper'
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'

export default function Guidance(props = {}) {
    return (
        <span>
            <PopupState variant="popper" popupId={props.id}>
                {(popupState) => (
                <span>
                    <HelpIcon {...bindToggle(popupState)} className="tis-help-icon"/>
                    <Popper {...bindPopper(popupState)} transition className='assessment-guidance'>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={150}>
                                <Paper className="tis-mfi-guidance">
                                    <h2>Guidance</h2>
                                    <IconButton aria-label="close" {...bindToggle(popupState)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <div style={{ padding: '0 1em 1em' }}>
                                      <Stack spacing={2}>
                                      { props.title && (Array.isArray(props.title)) ? 
                                          props.title.map((t, tidx) => {
                                            return <div key={tidx}>{t}</div>
                                          })
                                        : <div>{props.title}</div>
                                      }
                                      { props.html && <div dangerouslySetInnerHTML={{ __html: props.html }}></div> }
                                      { props.list && <div><ol type={props.type || "1"}>
                                        { props.list.map((g, gidx) => {
                                          if (typeof g == 'object') {
                                            const keys = Object.keys(g)            
                                            if (keys.includes('html')) {
                                              return <li key={gidx} dangerouslySetInnerHTML={{ __html: g.html }} />
                                            } else if (keys.includes('list')) {
                                              const gs = g.list.map((s, sidx) => {
                                                return <li key={sidx}><Typography>{s}</Typography></li>
                                              })
                                              return <li key={gidx}>{g.title || ''}<ol type={g.type || "1"}>{gs}</ol></li>                                              
                                            }
                                            return <li key={gidx}>{g}</li>
                                          } else {
                                            return <li key={gidx}><Typography>{g}</Typography></li>
                                          }
                                        }) }
                                      </ol></div>}
                                      </Stack>
                                    </div>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </span>
                )}
            </PopupState>
        </span>
    )
}
import React, { useState, useEffect } from 'react';
import {Alert, IconButton} from '@mui/material';
import {Close} from '@mui/icons-material'

export default function TemporaryAlert(props = {}) {
  const [open, setOpen] = useState(true);

  const {
    title,
    timeout = 5000,
    severity = 'success'
  } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && <Alert 
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {title}
      </Alert>}
    </>
  );
}
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { FactCheck } from '@mui/icons-material'
import { ROLES } from '../auth/role'
import axios from 'axios'
import { ApiGateway } from '../config/config'

import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { Autocomplete, Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'

import { handleFieldChange, handleSelectChange, handleTextChange } from '../form/Form'
import qfsModels from './models/QualityAndFSModels'
import esModels from './models/EnvironmentalAndSafetyModels'
import { getRequestConfig, getToken } from '../auth/auth'
import ConfirmDialog from '../form/ConfirmDialog'
import ProfileSelectionControl from '../manufacturer/ProfileSelectionControl'

const assessmentModels = [...qfsModels, ...esModels]

export default function CreateAssessmentGroupPage (props = {}) {
  const { instance } = useMsal()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const assessmentTypes = {
    BU: 'bu',
    TECHNICAL: 'technical'
  }

  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [mfrProfileLabel, setMfrProfileLabel] = useState()
  const [type, setType] = useState(assessmentTypes.BU)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [manufacturerProfileList, setManufacturerProfileList] = useState()

  const [newRecord, setNewRecord] = useState({
    assessmentId: "new",
    assessmentType: "bu",
    startDate: undefined,
    estimatedDays: 0,
    manufacturerId: '',
    assessors: [],
    status: "Open",
    qseManager: undefined,
    filter: []})

  const [fieldErrors, setFieldErrors] = useState({})

  const validateNewForm = (_newRecord = newRecord) => {
    let _isValid = true
    if (_newRecord.assessmentType === 'technical' && _newRecord.filter.length === 0) {
      console.log('no valid due to filter')
      _isValid = false
    }
    if (!_newRecord.manufacturerId || _newRecord.manufacturerId == '0') {
      console.log('no valid due to manufacturerId')
      _isValid = false
    }
    if (!_newRecord.qseManager || _newRecord.assessors.length === 0) {
      console.log('no valid due to assessors')
      _isValid = false
    }

    if (!_newRecord.startDate) {
      console.log('no valid due to start date')
      _isValid = false
    }

    if (!validateDays(_newRecord.estimatedDays)) {
      console.log('not valid due to estimated days')
      _isValid = false
    }

    setIsValid(_isValid)
    return _isValid
  }
  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const onSave = async () => {

    if (! validateNewForm()) return

    setIsLoading(true)
    try {
    const requestConfig = await getRequestConfig()
    const basePath = ApiGateway.assessments
    
    await axios.post(basePath, newRecord, requestConfig)
    } finally {
      setIsLoading(false)
    }
    handleClose()
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleClose()
  }
  
  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/my-assessments`)
    } else {
      navigate(-1)
    }
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const loadManufacturerOptions = async (_mfrProfileId) => {
    if (_mfrProfileId) {
      const requestConfig = await getRequestConfig()

      const basePath = `${ApiGateway.manufacturerProfile}/search`

      let search = {}
      // if (_mfrProfileId) {
        search = { mfrProfileId: _mfrProfileId }
      // }
      const response = await axios.post(basePath, search, requestConfig)
      if (response.data) {
        const { results } = response.data
        setManufacturerProfileList(results)
        // if (_mfrProfileId) {
          getMfrProfileInfo(results)
        // }
      }
    }
  }

  const getMfrProfileInfo = (profiles) => {
    let label = undefined
    const profile = profiles.find((p) => p.id === mfrProfileId)
    if (profile) {
      label = `${profile.manufacturerId} - ${profile.Manufacturer.manufacturerName}`
      setNewRecord(
        { ...newRecord,
          manufacturerId: profile.manufacturerId
        })
    }

    setMfrProfileLabel(label)
  }

  const loadAssessorOptions = async() => {
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.user}/search`
    const roleId = ROLES.QSE_MGR.id
    const assessorOptions = await axios.post(basePath, { roleId }, requestConfig )

    if(assessorOptions && assessorOptions.status >= 200 && assessorOptions.status < 300) {
      setAssessorOptions(assessorOptions.data)
    } else {
      setAssessorOptions([{label: 'unable to load data', value: '-1'}])
    }
    
  }

  const [assessorOptions, setAssessorOptions] = useState([])
  let assessors = newRecord.assessors ? newRecord.assessors.map(a => { return { koId: a.value } }) : []

  const [categoryChoices, setCategoryChoices] = useState(
    assessmentModels.reduce((p, c) => {
      p[c.props.id] = false
      return p
      }, {})
  )

  const setAssessmentFilter = (choices) => {
    const assessmentFilter = assessmentModels.reduce((p,c) => {
      if (choices[c.props.id] === true) {
        p.push(c.props.id)
      }
      return p
    }, [])
    const _newRecord = {
      ...newRecord,
      filter: assessmentFilter
    }
    setNewRecord(_newRecord)
    validateNewForm(_newRecord)
  }

  const handleCategorySelection = (event) => {
    const newChoices = {      
        ...categoryChoices,
        [event.target.name]: event.target.checked      
    }
    setCategoryChoices(newChoices)
    setAssessmentFilter(newChoices)
  }

  const checkCategorySelectionRequiredError = () => {
    return newRecord.filter.length === 0
  }

  const breadcrumb = <div><span><FactCheck />&nbsp;Create&nbsp;New&nbsp;Assessment</span></div>

  const validateDays = (days = undefined) => {
    const a = days === undefined ? newRecord.estimatedDays : days
    const valid = a !== undefined
      && parseInt(a) > 0
      && parseInt(a) == a
      && !isNaN(a)
      
    return valid
  }

  const performSelectManufacturer = async (_mfrProfileId, _mfrProfile) => {
    const _newRecord = { ...newRecord, manufacturerId: _mfrProfile.manufacturerId }
    setNewRecord(_newRecord)
    setDirty()
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await Promise.all([
        loadAssessorOptions(),
        loadManufacturerOptions(mfrProfileId)
      ])
      setIsLoading(false)
    })()
  }, [])

  return (
    <>
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <FormViewCommandBar  onSave={onSave} onCancel={confirmCancel} 
          enableSave={isDirty} 
          showClose={false}
        />}>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleClose} yesLabel="Discard" noLabel="Stay on Page"/>
      <Paper sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>        
        <Grid container columnGap={1} rowGap={1} spacing={1}>                  
          <Grid item xs={4.5} md={4.5}>
          <FormControl fullWidth size='small'>
            { !mfrProfileId && 
              <ProfileSelectionControl onSelectProfile={performSelectManufacturer} />
            }
            { !newRecord.manufacturerId && 
              <FormHelperText error={true}>This field is required</FormHelperText>
            }
              { mfrProfileLabel && 
                <TextField 
                  id="mfrProfileLabel" 
                    size='small' disabled 
                    defaultValue={mfrProfileLabel}
                    fullWidth
                />
              }
            </FormControl>
          </Grid>

          <Grid item xs={3} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>                
                <DatePicker
                  format="MM/DD/YYYY" id='startDate'
                  label="Start Date"
                  defaultValue={undefined} 
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                        required: true,
                        onBlur: (event) => {
                          
                        }
                      },
                    }}
                    onChange={(value) => {
                      handleFieldChange('startDate',value, newRecord, setNewRecord, setDirty)
                    }}
                  />
              </LocalizationProvider>
              { 
                !newRecord.startDate && 
                <FormHelperText error={true}>This field is required</FormHelperText>
              }
          </Grid> 

          <Grid item xs={2} md={2}>
          <TextField
            id="estimatedDays"
            size='small'
            value={newRecord.estimatedDays}
            onChange={(event) => {
              handleTextChange(event, newRecord, setNewRecord)
              setDirty()
            }}
            //onBlur={()=>setError(newRecord.status, "status")}
            label="Est. # of Days (up-to)"
            fullWidth
            type='number'
            FormHelperTextProps={{ error: true,  hidden: validateDays() }}
            helperText="Must be greater than 0; whole days only"
          />
          </Grid>

          <Grid item xs={4.5} md={4.5}>
            
              <FormControl fullWidth>
              <Autocomplete
                // isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}              
                id="qseManager"
                size='small'
                fullWidth
                options={assessorOptions}
                getOptionLabel={(option) =>[option.firstName, option.lastName].join(' ')}
                value={newRecord.qseManager}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="QSE Manager"
                  />
                )}
                onChange={(_event, value) => {
                  handleFieldChange("qseManager", value, newRecord, setNewRecord, setDirty)
                }}
              />
              { 
                !newRecord.qseManager && 
                <FormHelperText error={true}>This field is required</FormHelperText>
              }
              </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
              <FormControl fullWidth>
              <Autocomplete
                // isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                multiple
                id="assessors"
                size='small'
                fullWidth
                options={assessorOptions}
                getOptionLabel={(option) =>[option.firstName, option.lastName].join(' ')}
                value={newRecord.assessors}
                openOnFocus
                onChange={(_event, values) => {
                  handleFieldChange("assessors", values, newRecord, setNewRecord, setDirty)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Assessor(s)"
                  />
                )}
              />
              { 
                newRecord.assessors.length === 0 && 
                <FormHelperText error={true}>This field is required</FormHelperText>
              }
              </FormControl>
          </Grid>

          <Grid item xs={6} md={6}>
            <Stack spacing={0}>              
              <FormControl fullWidth size='small'>
                <InputLabel>Assessment Type:</InputLabel>
                <Select size='small' fullWidth id="assessmentType"
                defaultValue={newRecord.assessmentType}
                onChange={(event) => handleSelectChange('assessmentType', event, newRecord, setNewRecord, setDirty)} >
                  <MenuItem value='bu'>OU Assessment</MenuItem>
                  <MenuItem value='technical'>Technical Visit</MenuItem>
                </Select>
              </FormControl>
              { newRecord.assessmentType === "technical" &&                 
                <Card variant="outlined">                  
                <FormControl 
                  component="fieldset" 
                  variant="filled"
                  size='small'
                  fullWidth
                  sx={{padding: 3, maxHeight: 300, overflow: 'auto'}}
                  >
                  <FormHelperText variant='filled' error={checkCategorySelectionRequiredError()}>Select included topics**</FormHelperText>
                  <FormGroup style={{width: '100%'}} row={false}
                  id="filter"
                  >
                    { assessmentModels.map((m, idx) => {
                      return (<FormControlLabel key={idx}
                        control={
                          <Checkbox checked={categoryChoices[m.props.id]} onChange={handleCategorySelection} name={m.props.id} size='small'/>
                        }
                        label={[m.props.id, m.props.title].join(' - ')}
                      />)
                      })
                    }
                  </FormGroup>                  
                </FormControl>
                </Card>
              }
            </Stack>
          </Grid>
          
        </Grid>
      </Paper>      
    </Page>
    </>
  )

}
import { Box, Popover, Typography } from "@mui/material"

import { useState } from "react"
import { CAP_STATUSES, CAP_STATUS_LABELS } from "../../form/formUtils"
const capFlowImg = require('../../img/cap-flow.png')

export default function CAPStatusGlobe({status='not-started', targetDate=undefined}) {
  const [clicked, setClicked] = useState(null)
  const [hovered, setHovered] = useState(null)

  const handleHover = (e) => {
    setHovered(e.currentTarget)
    setClicked(null)
  }

  const handleLegend = (e) => {
    setClicked(e.currentTarget)
    setHovered(null)
  }

  const handleClose = () => {
    setClicked(null)
    setHovered(null)
  }

  const legendOpen = Boolean(clicked)
  const hintOpened = Boolean(hovered)

  const getStatusLabel = (forStatus, targetDate) => {
    if (status === CAP_STATUSES.in_process && targetDate) {
        if (new Date(targetDate).getTime() < new Date().getTime()) {
            return `${CAP_STATUS_LABELS[forStatus]} - Late`
        }
    }
    return CAP_STATUS_LABELS[forStatus]
  }

  const getStatusClass = (forStatus, targetDate) => {
    let status = forStatus.replace(/_/g,'-')
    if (status === 'in-process' && targetDate) {
        if (new Date(targetDate).getTime() < new Date().getTime()) {
            status = status + '-late'
        }
    }
    return `tis-cap-status-globe cap-${status}`
}

  return (
    <Box 
      onClick={handleLegend}
      onMouseEnter={handleHover} 
      onMouseLeave={handleClose}
      className={getStatusClass(status, targetDate)}>
        <Popover id="status-popover" sx={{pointerEvents: 'none'}}
        open={hintOpened}
        anchorEl={hovered}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', x:25 }}
        onClose={handleClose}
        disableRestoreFocus
        >
          <Box padding='10px' border='1px solid silver'>
          <Typography fontWeight='bold'>{getStatusLabel(status, targetDate)}</Typography>
          { targetDate && <Typography>Target Completion {new Date(targetDate).toLocaleDateString()}</Typography>}
          </Box>
        </Popover>
        <Popover id="status-legend-popover"
        sx={{ pointerEvents: 'none', zIndex: 9001 }}
        open={legendOpen}
        anchorEl={clicked}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
        disableRestoreFocus
        >
          <Box padding='10px' border='1px solid silver'>
            <img src={capFlowImg} />
          </Box>
        </Popover>
      </Box>
  )
}
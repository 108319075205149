import { default as React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Factory } from '@mui/icons-material'
import ListManufacturerProfiles from './ListManufacturerProfiles'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'

export default function ListManufacturerProfilesPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><Factory/>&nbsp;Manufacturer Profiles</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const addRecord = () => {
    navigate('/manufacturer/profile/create')
  }

  return (
    <RoleContext.Consumer>
      { roleCtx => (
        <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
          commandBar={
            <ListViewCommandBar 
              showFilters={true}
              toggleFilters={toggleFilters} 
              addRecord={addRecord} 
              showAdd={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])}              
            />
          }>
          <ListManufacturerProfiles showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} />
        </Page>
      )}
    </RoleContext.Consumer>
  )
}
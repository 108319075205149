import { Edit } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridFooterContainer, GridFooter, GridActionsCellItem } from '@mui/x-data-grid';
import axios from 'axios';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { getRequestConfig } from '../auth/auth.js';
import { ApiGateway } from '../config/config.js';
import { serializeFilterToQueryString, serializeSortToQueryString } from '../utils/TableUtils.js';
import { removeAllLocalStorageItemsStartingWith } from '../utils/storage.js'

export default function ListIMLogs(props = {}) {
  let navigate = useNavigate();

  const [imLogRows, setIMLogRows] = useState([])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([{ field: 'eventDate', sort: 'desc' }]);
  const [rowCount, setRowCount] = useState(0);

  let mfrProfileId = props.mfrProfileId

  const getIMLogsList = async (_mfrProfileId, requestConfig) => {
    props.startLoading()


    const filterQuery = serializeFilterToQueryString(filterModel)
    const sortQuery = serializeSortToQueryString(sortModel)
    const offset = (paginationModel.page) * paginationModel.pageSize
    const limit = paginationModel.pageSize

    const path = `${ApiGateway.imLogs}?_=${filterQuery}${sortQuery}&offset=${offset}&limit=${limit}`
    if (!requestConfig) requestConfig = await getRequestConfig()

    const { data } = await axios.get(path, requestConfig)
    const { results, count } = data

    setIMLogRows(results)
    setRowCount(count || results.length)
    props.stopLoading()
    return
  }

  const phoneNumberFormatter = (phoneNumber) => {
    if (!phoneNumber) return ''
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }

  const cellPhoneNumberFormatter = (cell) => {
    return phoneNumberFormatter(cell?.value)
  }

  const dateFormatter = (date) => {
    if (!date) return ''
    const dateObj = new Date(date)
    return dateObj.toLocaleDateString()
  }

  const cellDateFormatter = (cell) => {
    return dateFormatter(cell?.value)
  }

  const columns = useMemo(() => [
    // {
    //   field: 'actions',
    //   Cell: ({ cell, row }) => {
    //     return <Edit style={{ cursor: 'pointer' }} onClick={() => {
    //       navigate(`/incident-management/edit/${row.original.id}`)
    //     }}></Edit>
    //   }
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<Edit />} onClick={(e, row) => {
          navigate(`/incident-management/edit/${params.row.id}`)
        }} label="Edit" />,
      ]
    },
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'eventTypeLabel', headerName: 'Event Type', width: 150, filterable: false, sortable: false },
    { field: 'managedBy', headerName: 'Managed By' },
    { field: 'eventDate', headerName: 'Event Date', valueFormatter: cellDateFormatter, width: 150, filterable: false, sortable: true },
    { field: 'issueTypeLabel', headerName: 'Issue Type', width: 200, filterable: false, sortable: false },
    { field: 'corpIMCRNumber', headerName: 'Corp IMCR Number', width: 150 },
    { field: 'eventStatus', headerName: 'Event Status', width: 100, filterable: false, sortable: false },
    { field: 'governanceManagerAssigned', headerName: 'Governance Manager', width: 180 },
    { field: 'rcaChoiceLabel', headerName: 'RCA', width: 100, filterable: false, sortable: false },
    { field: 'capChoiceLabel', headerName: 'CAP', width: 100, filterable: false, sortable: false },
    { field: 'costOfQualityStatusLabel', headerName: 'Cost Of Quality Status', width: 180, filterable: false, sortable: false },
    { field: 'costOfQuality', headerName: 'Cost of Quality', width: 120 },
    { field: 'lessonsLearnedChoiceLabel', headerName: 'Lessons Learned', width: 150, filterable: false, sortable: false },
    { field: 'capValidationChoiceLabel', headerName: 'CAP Validation', width: 120, filterable: false, sortable: false },
    { field: 'imcrIatClosedDate', headerName: 'IMCR/IAT Closed Date', valueFormatter: cellDateFormatter, width: 180, filterable: false, sortable: false },
    { field: 'rcaDueDate', headerName: 'RCA Due Date', valueFormatter: cellDateFormatter, width: 120, filterable: false, sortable: false },
    { field: 'capRecordsReceivedDate', headerName: 'CAP Records Received Date', valueFormatter: cellDateFormatter, width: 210, filterable: false, sortable: false },
    { field: 'capOverdue', headerName: 'CAP Overdue', width: 120, filterable: false, sortable: false },
    { field: 'notifiedBy', headerName: 'Notified By', width: 120 },
    { field: 'dateNotified', headerName: 'Date Notified', valueFormatter: cellDateFormatter, width: 120, filterable: false, sortable: false },
    { field: 'submitterName', headerName: 'Submitter Name', width: 150 },
    { field: 'submitterEmail', headerName: 'Submitter Email', width: 150 },
    { field: 'submitterPhoneNumber', headerName: 'Submitter Phone Number', valueFormatter: cellPhoneNumberFormatter, width: 200 },
    { field: 'ownershipIMProvidedTheFollowingInstructions', headerName: 'Ownership IM Instructions', width: 300 },
    { field: 'otherComments', headerName: 'Other Comments', width: 300 },
  ], [])

  const initializePage = async () => {
    const requestConfig = await getRequestConfig()
    const promises = []

    promises.push(getIMLogsList(mfrProfileId, requestConfig))

    props.startLoading()
    await Promise.all([
      ...promises
    ])
    props.stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [
    paginationModel,
    sortModel,
    filterModel
  ])

  const onPaginationModelChange = (_paginationModel) => {
    setPaginationModel(_paginationModel)
  }
  const onPageSizeChange = (newPageSize) => {
    setPaginationModel(Object.assign({}, paginationModel, { pageSize: newPageSize }))
  }

  const onPageChange = (newPage) => {
    setPaginationModel(Object.assign({}, paginationModel, { page: newPage }))
  }

  const onFilterModelChange = (_filterModel) => {
    setFilterModel(_filterModel)
  }
  const onSortModelChange = (_sortModel) => {
    setSortModel(_sortModel)
  }

  const getInitialColumnVisibility = () => {
    const initialColumnVisibility = {}
    columns.forEach((column) => {
      const { field } = column
      const prefix = `IMLogColumnVisibility-${field}`
      const value = localStorage.getItem(prefix)
      if (value !== undefined && value !== null) {
        initialColumnVisibility[field] = value === 'true'
      }
    })
    if(Object.keys(initialColumnVisibility).length === 0) {
      return {
        id: false,
        title: true,
        description: true,
        eventTypeLabel: true,
        managedBy: true,
        eventDate: true,
        issueTypeLabel: true,
        corpIMCRNumber: false,
        eventStatus: false,
        governanceManagerAssigned: false,
        rcaChoiceLabel: false,
        capChoiceLabel: false,
        costOfQualityStatusLabel: false,
        costOfQuality: false,
        lessonsLearnedChoiceLabel: false,
        capValidationChoiceLabel: false,
        imcrIatClosedDate: true,
        rcaDueDate: false,
        capRecordsReceivedDate: false,
        capOverdue: false,
        notifiedBy: false,
        dateNotified: false,
        submitterName: false,
        submitterEmail: false,
        submitterPhoneNumber: false,
        ownershipIMProvidedTheFollowingInstructions: false,
        otherComments: false,
      }
    }
    return initialColumnVisibility
  }

  useEffect(() => {
    setColumnVisibilityModel(getInitialColumnVisibility())
  }, [])

  function CustomFooter() {
    return (
      <GridFooterContainer
        sx={{
          justifyContent: 'start'
        }}
      >
        <GridFooter />
      </GridFooterContainer>
    );
  }


  return (
    <>
      <Paper sx={{ overflowX: 'auto', backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto' }}>
        <Grid>
          <DataGrid
            className="imLogsDataGrid"
            onPageSizeChange={onPageSizeChange}
            // density='compact'
            rows={imLogRows}
            columns={columns}
            filterMode="server"
            autoHeight={true}
            rowCount={rowCount}
            onColumnVisibilityModelChange={(newModel) => {
              if (Object.keys(newModel).length === 0) {
                removeAllLocalStorageItemsStartingWith('IMLogColumnVisibility-')
              }
              for (const field in newModel) {
                const value = newModel[field]
                const prefix = `IMLogColumnVisibility-${field}`
                localStorage.setItem(`${prefix}`, value)
              }
              setColumnVisibilityModel(newModel)
            }}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              pagination: {
                paginationModel
              },
              columns: {
                columnVisibilityModel
              },
              sorting: {
                sortModel,
              },
            }}
            pageSize={paginationModel.pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={onPaginationModelChange}
            onPageChange={onPageChange}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            slots={{ toolbar: GridToolbar }}
            components={{ Footer: CustomFooter }}
          />
          {/* </Grid> */}
          {/* <Grid> */}
          {/* <MaterialReactTable
            table={table}
          /> */}
        </Grid>
      </Paper>
    </>
  )
}
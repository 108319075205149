import { useContext, useEffect, useMemo, useState } from 'react'
import { calculateProgress } from '../context/AssessmentContext'
import { Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import ChartJSBarChart from '../../charts/ChartJsBarChart'
import { RATINGS, RATING_LABELS } from '../../form/formUtils'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ChartJsPieChart from '../../charts/ChartJsPieChart'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import { ASMT_STATUS } from '../../utils/constants'

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

const collectRatings = (_itemsList) => {
  const _ratingTotals = {}
  const _categoryTotals = {}

  _itemsList.forEach((item) => {
    if (item.findingRating && !['na','not_assessed', 'no_finding'].includes(item.findingRating)) {
      const groupRating = _ratingTotals[item.findingRating] || 0
      _ratingTotals[item.findingRating] = groupRating + 1

      const category = item.itemId.split('')[0]
      const categoryRating = _categoryTotals[category] || 0
      _categoryTotals[category] = categoryRating + 1
    }
  })

  return [_ratingTotals, _categoryTotals]
}

export default function AssessmentSummary(props={includedModels:[], assessmentItems: [], assessment: undefined}) {
  const roleDetails = useContext(RoleContext)
  const [outcomeScores, setOutcomeScores] = useState([])
  const [ratingTotals, setRatingTotals] = useState([])
  const [categoryTotals, setCategoryTotals] = useState([])
  const [areaTotals, setAreaTotals] = useState([0,0,0])
  const [selectedTab, setSelectedTab] = useState(0)
  const assessment = props.assessment

  const [assessmentFindings, setAssessmentFindings] = useState([])

  const showSummaryOfFindings = () => {
    return roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) || (assessment 
      && [ASMT_STATUS.COMPLETED, ASMT_STATUS.FINALIZED].includes(assessment.status))
  }

  const columns = useMemo(() => [
    {
      accessorKey: 'itemId',
      header: 'Item',
      grow: false,
      size: 75
    },
    {
      accessorKey: 'title',
      header: 'Title',
      grow: true
    },
    {
      accessorKey: 'findingRating',
      // accessorFn: (row) => {return RATING_LABELS[row.findingRating]},
      header: 'Rating',
      filterVariant: 'multi-select',
      filterSelectOptions: Object.entries(RATING_LABELS).reduce((p, [v, l]) => {
        if (!['na','not_assessed','no_finding'].includes(v)) {
          p.push({ label: l, value: v })
        }
         return p
      }, []),
      grow: false,
      size: 250,
      Cell: ({cell}) => (<>
        <span style={{height: '20px', width: '20px', borderRadius: '50%', display: 'inline-block', border: 0 }} 
        className={`points ${cell.getValue().replace(/_/g,'-')}`}></span>
        <Typography fontSize='inherit !important' paddingLeft={1}>{ RATING_LABELS[cell.getValue()] }</Typography></>)
    },
    {
      accessorKey: 'isRepeat',
      header: 'Repeat',
      enableColumnFilter: false,
      grow: false,
      size: 70,
      Cell: ({cell}) => { return cell.getValue() === 0 ? 'No' : 'Yes' }
    },
    {
      accessorKey: 'earnedPoints',
      header: 'Points',
      enableColumnFilter: false,
      grow: false,
      size: 80,
      Cell: ({cell, row}) => { return `${parseFloat(row.original.earnedPoints).toFixed(1)} / ${row.original.maxPoints}` }
    }
  ], [assessmentFindings])

  const extractItemModel = (model) => {
    let children = []
    let maxPoints = 0
    
    if (['Category', 'Section'].includes(model.type)) {
      children = model.children.flatMap((child) => {
        return extractItemModel(child)
      })
    }
    if (model.type === 'Item') {
      maxPoints = parseFloat(model.props.maxPoints)
    }
    const itemModel = {
      id: model.props.id,
      title: model.props.title,
      maxPoints,
    }
    return [itemModel, ...children]
  }

  const initializeSummary = async () => {

    if (showSummaryOfFindings()) {
      const _itemModels = props.includedModels.flatMap((m) => extractItemModel(m))
      
      const findings = props.assessmentItems.reduce((p,item) => {
        const included = item.findingRating && !['na','not_assessed','no_finding'].includes(item.findingRating)
        if (included) {
          const model = _itemModels.find(m => m.id === item.itemId)
          if (model) {
            const maxPoints = parseFloat(model.maxPoints)
            p.push({ ...item, title: model.title, maxPoints})
          }
        }
        return p
      },[])
      setAssessmentFindings(findings)
    }

    const progress = props.includedModels.map((c) => {
      const [a,b] = calculateProgress(c, props.assessmentItems)
      return a // (a) is Category only, (a, ...b) is Category, Section
    })

    const _graphScores = progress.map((i) => {
      return ({id: i.id, title: i.title, value: (i.earnedPoints / i.maxPoints) * 100 }) 
    })

    const [_ratingTotals, _categoryTotals] = collectRatings(props.assessmentItems)

    setOutcomeScores(_graphScores)
    setRatingTotals(_ratingTotals)
    setCategoryTotals(_categoryTotals)
    setAreaTotals(Object.keys(_categoryTotals).reduce((p, c) => {
      if (c.match(/[A-M]/)) {
        p[0] += _categoryTotals[c]
      } else if (c.match(/[N-P]/)) {
        p[1] += _categoryTotals[c]
      } else if (c.match(/[Q-T]/)) {
        p[2] += _categoryTotals[c]
      }
      return p
    },[0, 0, 0]))
  }

  const tabChange = (event, toTab) => {
    setSelectedTab(toTab)
  }

  useEffect(() => {
    initializeSummary()
  }, [])

  const table = useMaterialReactTable({
    data: assessmentFindings,
    columns: columns,
    layoutMode: 'grid',
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: true,
    enablePagination: false,
    enableColumnDragging: false,
    enableSorting: false,
    enableColumnActions: false,
    displayColumnDefOptions: {'mrt-row-expand': { size: 30, grow: false } },
    initialState: {
      expanded: true,
      showColumnFilters: true
    },
    renderDetailPanel: ( {row} ) => {
      const set = Object.entries(row.original)
     return (
      <Stack spacing={1} paddingLeft={2}>
            {(row.original.areaFinding || '').length > 0 && <>
            <Typography variant='body2' style={{ fontWeight: 'bold'}}>Area Finding: Observed/Location</Typography>
            <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.areaFinding}</Typography>
            </>}
            <Typography variant='body2' style={{ fontWeight: 'bold'}}>Finding: Gaps/Observations</Typography>
            <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.findingObservations}</Typography>
            <Typography variant='body2' style={{ fontWeight: 'bold'}}>Objective Evidence</Typography>
            <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.objectiveEvidence}</Typography>
            <Typography variant='body2' style={{ fontWeight: 'bold'}}>QSE Objective Evidence</Typography>
            <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.objectiveEvidenceQse}</Typography>
          </Stack>
    )},
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        fontWeight: 'bold',
        padding: '4px !important'
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '.9rem !important',
        verticalAlign: 'center'
      }
    },
    renderTopToolbar: true,
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Tabs value={selectedTab} onChange={tabChange}>          
          <Tab label='Total Findings' id={`tab-0`}></Tab>
          <Tab label='Outcome by Program' id={`tab-1`}></Tab>
          <Tab label='Assessor Comments' id={`tab-2`}></Tab>
          {
            showSummaryOfFindings() && <Tab label='Summary of Findings' id={`tab-3`}></Tab>
          }
        </Tabs>   
        <TabPanel selectedTab={selectedTab} thisTab={0}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12} md={8}>
              <ChartJSBarChart
              title={'Total Findings'} 
              barColor={'#FF9900'}
              labelsArray={[
                RATING_LABELS[RATINGS.high_risk_non_conformance],
                RATING_LABELS[RATINGS.non_conformance],
                RATING_LABELS[RATINGS.conditional_non_conformance],
                RATING_LABELS[RATINGS.improvement_opportunity]
              ]}
              dataArray={[
                ratingTotals[RATINGS.high_risk_non_conformance] || 0,
                ratingTotals[RATINGS.non_conformance] || 0, 
                ratingTotals[RATINGS.conditional_non_conformance] || 0, 
                ratingTotals[RATINGS.improvement_opportunity] || 0
              ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer>
                <Table>
                  <TableBody>
                    { Object.keys(ratingTotals).map((r, ridx) => (
                      <TableRow key={ridx}>
                        <TableCell sx={{border: 1, textAlign:'center'}}>{RATING_LABELS[r]}<br/>{ratingTotals[r]}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="total">
                      <TableCell  sx={{border: 1, textAlign:'center', backgroundColor: '#FF9900', fontWeight: 'bold'}}>Total&nbsp;Findings<br/>{Object.values(ratingTotals).reduce((p,c) => p+c, 0)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center' marginTop={2}>          
            <Grid item xs={12} md={8} alignSelf='right' maxHeight={375}>
            <Typography variant='h5'>Area Totals</Typography>
              <ChartJsPieChart 
                title='' 
                dataArray={areaTotals} 
                labelsArray={['Quality & Food Safety', 'Environmental','Safety' ]}
                backgroundColorsArray={['#003366','#99CC33','#CC3333']}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Quality&nbsp;&amp;&nbsp;Food&nbsp;Safety<br/>{areaTotals[0]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Environmental<br/>{areaTotals[1]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Safety<br/>{areaTotals[2]}</TableCell>
                    </TableRow>
                    <TableRow key="total">
                      <TableCell  sx={{border: 1, textAlign:'center', backgroundColor: '#FF9900', fontWeight: 'bold'}}>Total&nbsp;Findings<br/>{Object.values(areaTotals).reduce((p,c) => p+c, 0)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel selectedTab={selectedTab} thisTab={1}>
          <ChartJSBarChart
            title={'Outcome by Program'}
            barColor={'#FFCC00'}
            labelsArray={ outcomeScores.map(s => {
              return `${s.title}, ${Math.round(s.value * 100)/100}%`
            })}
            dataArray={ outcomeScores.map(s => s.value)}
          />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} thisTab={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{whiteSpace: 'pre-wrap'}}>{assessment.assessorComments}</Typography>
            </Grid>
          </Grid>
        </TabPanel>
        { showSummaryOfFindings() &&
        <TabPanel selectedTab={selectedTab} thisTab={3}>
          { assessmentFindings.length > 0 && 
              <MaterialReactTable table={table} />
          }
        </TabPanel>
        }
      </Grid>
    </Grid>
  )
}
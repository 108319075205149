 class Validate {
    
    empty(id, value, options = {}) {
        options = { 
            errorMessage: 'This field is required',
            trimWhiteSpace: true,
            ...options
        }
        let isValid = value === null || value === undefined || value.length === 0 || (options.trimWhiteSpace && value.trim().length === 0)
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    required(id, value, options = {}) {
        options = { 
            errorMessage: 'This field is required',
            trimWhiteSpace: true,
            ...options
        }
        let isValid = value !== null && value !== undefined && value.length > 0 && (!options.trimWhiteSpace || value.trim().length > 0)
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    booleanRequired(id, value, options = {}) {
        options = { 
            errorMessage: 'This field is required',
            trimWhiteSpace: true,
            ...options
        }
        let isValid = value === true || value === false || value === 'true' || value === 'false' || value === 1 || value === 0
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    numberRequired(id, value, options = {}) {
        options = { 
            errorMessage: 'This field is required',
            parser: parseFloat,
            ...options
        }
        let isValid = false
        try {
            const parsed = options.parser(value)
            isValid = isNaN(parsed) ? false : true
        } catch(err) {

        }
        const satisfiesLength = options.length ? options.length === ((value || '') + '').length : true
        isValid = isValid && satisfiesLength
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    email(id, value, options = {}) {
        options = {
            errorMessage: 'Invalid Email',
            emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ...options
        }
        let isValid =  this.required(id, value).isValid && options.emailPattern.test(value)
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    phone(id, value, options = {}) {
        options = {
            errorMessage: 'Invalid Phone',
            phonePattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
            ...options
        }
        let isValid =  this.required(id, value).isValid && options.phonePattern.test(value)
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }

    date(id, value, options = {}) {
        options = {
            errorMessage: 'Invalid Date',
            ...options
        }
        let isValid = false
        try {
            isValid = value !== null && value !== undefined && !isNaN(new Date(value).getDate())
            isValid = isValid || !isNaN(new Date(value?.['$d']).getDate())
        } catch(err) {

        }
        return {
            isValid,
            message: isValid ? options.successMessage : options.errorMessage
        }
    }
}

module.exports = new Validate()
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import React, { useEffect, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { gsap } from "gsap"


export default function MenuItem(props = {}) {
  let submenu = undefined
  let submenuIcon = undefined

  let [isOpen, setIsOpen] = useState(true)
  let [submenuHeight, setSubmenuHeight] = useState(null)
  let [isAnimating, setIsAnimating] = useState(false)  

  useEffect(() => {
    init()
  }, [submenu])

  const init = () => {
    setSubmenuHeightToClientHeight()
    if(submenuIcon) {
      gsap.to(submenuIcon, { rotation: isOpen ? -90 : 0, duration: 0 })
    }
  }

  const toggle = async (event) => {
    props.children && isOpen ? await close(event) : await open(event)
  }

  const setSubmenuHeightToClientHeight = () => {
    if(submenu) {
      if (submenuHeight !== submenu.clientHeight && submenu.clientHeight !== undefined && submenu.clientHeight !== null && submenu.clientHeight !== 0) {
        setSubmenuHeight(submenu.clientHeight)
      }
    }
  }

  const open = async() => {
    if (!isAnimating) {
      setIsAnimating(true)
      setIsOpen(true)
      setSubmenuHeightToClientHeight()
      if(submenu) {
        gsap.fromTo(submenu, { height: 0 }, {height: submenuHeight, onComplete:() => {
          setIsAnimating(false)
          submenu.style.height = null
        }})
        gsap.fromTo(submenuIcon, { rotation:0 }, { rotation:-90 })
      }
    }
  }

  const close = async() => {
    if (!isAnimating) {
      setIsAnimating(true)
      setSubmenuHeightToClientHeight()
      gsap.fromTo(submenuIcon, { rotation:-90 }, { rotation:0 })
      submenu.style.height = submenuHeight
      gsap.fromTo(submenu, {height:submenuHeight}, {height:0, onComplete:() => {
        setIsOpen(false)
        setIsAnimating(false)
      }})
    }
  }

  if (props.children) {
    let className = isOpen ? 'treeview-menu visible' : 'treeview-menu'
    className += " " + props.theme
    submenu = <div ref={(me) => {
      submenu = me
     }} style={{overflow:'hidden'}} ><ul className={className}>{props.children}</ul></div>
    submenuIcon = <ChevronLeftIcon className="submenu-icon" ref={(me) => {
      submenuIcon = me
    }} />
  }
  let link = ('' + props.href).indexOf('#') > -1 ?
      <HashLink to={props.href} onClick={toggle}>

        {props.icon}
        <span>{props.title}</span>
        {submenuIcon}
      </HashLink>
      :
      <Link to={props.href} onClick={async event => { await toggle(event)} }>
        {props.icon}
        <span>{props.title}</span>
        {submenuIcon}
      </Link>
  return (
    <li>
      {link}
      {submenu}
    </li>
  )
}
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend)

export default function ChartJSBarChart (props={title: undefined, labelsArray: [], dataArray: [], barColor: undefined}) {

  const options = {
    indexAxis: 'y',
    padding: 5,
    elements: {
      bar: {
        borderWidth: 1,
        backgroundColor: props.barColor
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: !!props.title,
        text: props.title
      }
    }
  }

  const labels=props.labelsArray

  const data = {
    labels,
    datasets: [
      {
        data: props.dataArray,
        maxBarThickness: 20
      }
    ]
  }

  return (
      <Bar options={options} data={data} />
  );
}
import { default as React, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { NextPlan } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom';
import EditOutlined from '@mui/icons-material/EditOutlined'
import { LoadingButton } from '@mui/lab';

export default function WizardCommandBar (props = {
  showEdit: false,
  onEdit: undefined,
  showCancel: false,
  onCancel: undefined,
  showNext: false,
  onNext: undefined,
  actionButtons: {
    label: '',
    onClick: undefined,
    endIcon: undefined,
    enabled: false,
    show: false
  }
}) {
  const navigate = useNavigate()
  const [nextButtonLabel, setNextButtonLabel] = useState('Next')

  const toggleHelp = () => {
    navigate(props.helpPage, {state: props.helpPageState || {}})
  }

  return (
    <div className="tis-commandbar tis-commandbar-wizard">
      {props.helpPage && <Button  onClick={toggleHelp}><HelpIcon/></Button>}
      {props.actionButtons && props.actionButtons.length &&
        props.actionButtons.reduce((p, b) => {
          if (b.show) {
            p.push(
              <LoadingButton loading={props.loading} variant="contained" onClick={b.onClick} endIcon={b.endIcon || undefined} disabled={!b.enabled}>{b.label}</LoadingButton>
            )
          }
          return p
        },[])
      }
      {(props.showNext === undefined || props.showNext) && 
        <LoadingButton loading={props.loading} variant="contained" onClick={props.onNext} endIcon={<NextPlan />} disabled={!props.enableNext}>{nextButtonLabel}</LoadingButton>
      }
      {(props.showCancel === undefined || props.showCancel) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Cancel</LoadingButton>}
    </div>
  )
}

import * as React from 'react';
import { useEffect, useContext } from 'react';
import HelpPage from '../help/HelpPage';
import qseManagerHelp from '../help/pages/authorization/QseManager.md'
import RoleContext from '../auth/RoleContext'
import { ROLES } from '../auth/role'
import addapproval from '../help/pages/authorization/add-approval.png'
import add from '../help/pages/authorization/add.png'
import approvaldefaults from '../help/pages/authorization/approval-defaults.png'
import authitem from '../help/pages/authorization/auth-item.png'
import authorizationeditor from '../help/pages/authorization/authorization-editor.png'
import back from '../help/pages/authorization/back.png'
import confirmedit from '../help/pages/authorization/confirm-edit.png'
import _confirm from '../help/pages/authorization/confirm.png'
import createnew from '../help/pages/authorization/create-new.png'
import _delete from '../help/pages/authorization/delete.png'
import editdialog from '../help/pages/authorization/edit-dialog.png'
import fileuploaded from '../help/pages/authorization/file-uploaded.png'
import filtered from '../help/pages/authorization/filtered.png'
import historytoggle from '../help/pages/authorization/history-toggle.png'
import letterslist from '../help/pages/authorization/letters-list.png'
import loadhistory from '../help/pages/authorization/load-history.png'
import massedit from '../help/pages/authorization/mass-edit.png'
import next from '../help/pages/authorization/next.png'
import othertype from '../help/pages/authorization/other-type.png'
import previewbutton from '../help/pages/authorization/preview-button.png'
import publish from '../help/pages/authorization/publish.png'
import reviewhasrevision from '../help/pages/authorization/review-has-revision.png'
import revisionhasreview from '../help/pages/authorization/revision-has-review.png'
import selectmfr from '../help/pages/authorization/select-mfr.png'
import showfilters from '../help/pages/authorization/show-filters.png'
import signcommandbar from '../help/pages/authorization/sign-command-bar.png'
import signsignature from '../help/pages/authorization/sign-signature.png'
import sort from '../help/pages/authorization/sort.png'
import undo from '../help/pages/authorization/undo.png'
import uploadfile from '../help/pages/authorization/upload-file.png'
import validationfailure from '../help/pages/authorization/validation-failure.png'
import detailcardletter from '../help/pages/authorization/detail-card-letter.png'
import publish1 from '../help/pages/authorization/publish-1.png'
import fileactions from '../help/pages/authorization/file-actions.png'

const imageMap = [
  { el: addapproval, file: 'add-approval.png' },
  { el: add, file: 'add.png' },
  { el: approvaldefaults, file: 'approval-defaults.png' },
  { el: authitem, file: 'auth-item.png' },
  { el: authorizationeditor, file: 'authorization-editor.png' },
  { el: back, file: 'back.png' },
  { el: confirmedit, file: 'confirm-edit.png' },
  { el: _confirm, file: 'confirm.png' },
  { el: createnew, file: 'create-new.png' },
  { el: _delete, file: 'delete.png' },
  { el: editdialog, file: 'edit-dialog.png' },
  { el: fileuploaded, file: 'file-uploaded.png' },
  { el: filtered, file: 'filtered.png' },
  { el: historytoggle, file: 'history-toggle.png' },
  { el: letterslist, file: 'letters-list.png' },
  { el: loadhistory, file: 'load-history.png' },
  { el: massedit, file: 'mass-edit.png' },
  { el: next, file: 'next.png' },
  { el: othertype, file: 'other-type.png' },
  { el: previewbutton, file: 'preview-button.png' },
  { el: publish, file: 'publish.png' },
  { el: reviewhasrevision, file: 'review-has-revision.png' },
  { el: revisionhasreview, file: 'revision-has-review.png' },
  { el: selectmfr, file: 'select-mfr.png' },
  { el: showfilters, file: 'show-filters.png' },
  { el: signcommandbar, file: 'sign-command-bar.png' },
  { el: signsignature, file: 'sign-signature.png' },
  { el: sort, file: 'sort.png' },
  { el: undo, file: 'undo.png' },
  { el: uploadfile, file: 'upload-file.png' },
  { el: validationfailure, file: 'validation-failure.png' },
  { el: detailcardletter, file: 'detail-card-letter.png' },
  { el: publish1, file: 'publish-1.png' },
  { el: fileactions, file: 'file-actions.png' },
]

export default function AuthorizationHelpPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const isFacilityBottler = roleDetails.hasRole([ROLES.BOTTLER])
  const isCorpBottler = roleDetails.hasRole([ROLES.BOTTLER_CORP])
  const isAdmin = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])

  const helpFile = qseManagerHelp
  const [markdown, setMarkdown] = React.useState('')

  useEffect(() => {
    (async () => {
      const mdResponse = await fetch(helpFile)
      const mdText = await mdResponse.text()
      setMarkdown(mdText)
    })()
  }, [])

  const processMarkdown = (markdown) => {
    return imageMap.reduce((mkd, item) => {
      const re = new RegExp(`src="${item.file}"`, 'g')
      return mkd.replace(re, `src="${item.el}"`)
    }, markdown)
  }
  return (
    <div style={{
      maxWidth: '800px',
    }}>
      <HelpPage
        backTo="/facility-authorizations/list"
        processMarkdown={processMarkdown}
        pageName="Facility Authorization Help"
        markdown={markdown}
        {...props}
      />
    </div>
  )
}
import { default as React, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FactCheck } from '@mui/icons-material'
import ListAssessments from './ListAssessments'
import RoleContext from '../auth/RoleContext'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import ProfileSelectionControl from '../manufacturer/ProfileSelectionControl'
import OwnershipSelectionControl from '../manufacturer/OwnershipSelectionControl'
import { Stack } from '@mui/material'

export default function ListAssessmentsPage (props = {}) {
  const params = useParams()
  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [ownershipId, setOwnershipId] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }
  
  const breadcrumb = <span><FactCheck/>&nbsp;OU&nbsp;Assessments</span>

  const [showFiltersButton, setShowFiltersButton] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAdd, setShowAdd] = useState(true)

  const addRecord = () => {
    navigate(`/assessments/create${mfrProfileId ? '/'+mfrProfileId : ''}`)
  }

  const showAddButton = () => {
    setShowAdd(true)
  }
  const hideAddButton = () => {
    setShowAdd(false)
  }

  const performSelectManufacturer = async (_mfrProfileId) => {
    setMfrProfileId(_mfrProfileId)
    if (! _mfrProfileId) {
      navigate(`/assessments`)
      // setAssessmentRows([])
    } else {
      navigate(`/assessments/${_mfrProfileId}`)
      // await getAssessmentList(_mfrProfileId)
    }
  }
  const performSelectOwnership = async (_ownershipId) => {
    setOwnershipId(_ownershipId)
  }
  
  return (
    <RoleContext.Consumer>
    {roleCtx =>
       <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <ListViewCommandBar
          showFilters={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && showFiltersButton}
          toggleFilters={toggleFilters}
          addRecord={addRecord}
          showAdd={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && showAdd}/>
        }>
        {/* <Button variant="contained" onClick={sendMessage}>Alert</Button> */}
        <Stack spacing={2} direction='row'>
        {roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && 
          <OwnershipSelectionControl defaultOwnershipId={ownershipId} onSelectOwnership={performSelectOwnership} />
        }
        <ProfileSelectionControl forOwnershipId={ownershipId} defaultMfrProfileId={mfrProfileId} onSelectProfile={performSelectManufacturer} />
        </Stack>
        <ListAssessments 
          showFilters={showFilters} 
          showAddRecord={showAddButton} 
          startLoading={startLoading} 
          stopLoading={stopLoading} 
          hideAddRecord={hideAddButton}
          ownershipId={ownershipId}
          mfrProfileId={mfrProfileId}
          setMfrProfileId={setMfrProfileId}
          />
      </Page>
      }
    </RoleContext.Consumer>
  )
}

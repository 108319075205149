import axios from "axios"
import { getRequestConfig, getToken } from "./auth"
import { ApiGateway } from "../config/config"

export const getUserProfile = async () => {
  const token = await getToken()
    const koId = token?.idTokenClaims?.koId
    if (! koId) return undefined

    const basePath = `${ApiGateway.profile}/${koId}?include=manufacturers`
    const options = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const response = await axios.get(basePath, options)
    return {token: token, profile: response.data}
}
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { ToggleButtonGroup, ToggleButton, Box, Grid, Paper, IconButton, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility } from '@mui/icons-material';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import { TroubleshootOutlined, SubdirectoryArrowRight } from '@mui/icons-material';
import { LetterTypes, WorkflowStatuses, getLastPublishedLabel } from './common.js';
import { toPascalCase } from '../utils/TextUtils.js'

export default function ListAuthorizations(props = {}) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const [listLatest, setListLatest] = useState(true);

  const roleDetails = useContext(RoleContext)

  const listColumns = useMemo(() => [
    {
      accessorKey: 'Manufacturer.manufacturerName',
      header: 'Facility',
      // minSize: 220,
      editable: false,
      filterVariant: 'text',
      grow: true,
      Cell: ({ row }) => (
        <Fragment>
          {row.original.subRow ? <SubdirectoryArrowRight style={{ marginTop: '-9px', color: 'gray' }} /> : ''}{row?.original?.Manufacturer?.manufacturerName}
        </Fragment>
      )
    },
    {
      accessorKey: 'manufacturerId',
      header: 'Facility ID',
      size: 140,
      grow: false,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'approvalStatus',
      header: 'Approval',
      size: 140,
      grow: false,
      editable: false,
      filterVariant: 'text',
      Cell: ({ row }) => {
        const tbd = <Typography color='#b6b6b6'>TBD</Typography>
        if (row.original.workflowStatus === WorkflowStatuses.DRAFT) return tbd
        return <Fragment>
          {toPascalCase(row.original.approvalStatus) || tbd}
        </Fragment>
      }
    },
    {
      accessorFn: (row) => {
        if (row.letterType === LetterTypes.NEW && row.issuedDate) return new Date(row.issuedDate)
        if (row.letterType === LetterTypes.NEW && !row.issuedDate) return new Date(row.createdAt)
        if (row.letterType === LetterTypes.REVISION) return new Date(row.revisionDate)
        if (row.letterType === LetterTypes.REVIEW) return new Date(row.reviewedDate)
      },
      header: 'Date',
      editable: false,
      filterVariant: 'date',
      sortingFn: 'datetime',
      size: 200,
      grow: false,
      Cell: (val) => {
        const { cell, row } = val
        if (row.original.workflowStatus === WorkflowStatuses.DRAFT) return <Typography color='#b6b6b6'>TBD</Typography>
        if (!cell.getValue()) return new Date(row.original.updatedAt).toLocaleDateString('en-US', { timeZone: 'UTC' })
        return getLastPublishedLabel(cell.getValue(), row.original)
      }
    },
    {
      accessorFn: (row) => toPascalCase(row.workflowStatus),
      header: 'Status',
      size: 100,
      grow: false,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'actions',
      header: '',
      columnDefType: 'display',
      size: 50,
      grow: false,
      Cell: ({ row }) => {
        const published = row.original.workflowStatus === WorkflowStatuses.PUBLISHED
        const linkTo = `/facility-authorizations/${row.original.id}${published ? '' : '/edit'}`
        return <Box sx={{ display: 'flex', gap: '0rem' }}>
          <Tooltip arrow placement="left" title={published ? "View" : "Edit"}>
            <Link to={linkTo}>
              <IconButton>
                {!published && <Edit />}
                {published && <Visibility />}
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      }
    }
  ])

  const getAuthorizationsList = async () => {
    props.startLoading()

    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.authorizations}/search`
    const search = {
      isLatest: listLatest
    }
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setDataRows(results)
    }

    props.stopLoading()
  }



  useEffect(() => {
    if (isMounted()) {
      getAuthorizationsList()
    }
  }, [listLatest])

  const table = useMaterialReactTable({
    // layoutMode: 'grid-no-grow',
    layoutMode: 'grid',
    // manualSorting: true,
    // manualFiltering: true,
    // manualPagination: true,
    // rowCount: 200,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    // onPaginationChange: setPagination,
    // state: {
    //   sorting,
    //   columnFilters,
    //   pagination
    // },
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    // enableColumnFilterModes: true,
    enableExpanding: false,
    paginateExpandedRows: false,
    getSubRows: listLatest ? (originalRow) => (originalRow.Children || []).map(d => ({ ...d, subRow: true })) : undefined,
    muiTableBodyRowProps: ({ row }) => {
      if (row.original.subRow) {
        return {
          sx: {
            backgroundColor: '#f0f3f5'
          }
        }
      }
    },
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      expanded: true,
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    renderTopToolbar: false,
  });

  
  return (
    <div className='App'>
      <Grid container sx={{width: '98%',}}>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={listLatest ? 'latest' : 'all'}
            exclusive
            onChange={(e) => setListLatest(e.target.value === 'latest')}
            aria-label="Show History"
          >
            <ToggleButton size="small" value="latest">Latest Published</ToggleButton>
            <ToggleButton size="small" value="all">All</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto' }}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}
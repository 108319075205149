import { Box, Button, IconButton, Link, Stack, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { AddRecordModal, addRow, checkColumnError, EditRecordModal } from "../../form/Form";
import { useCallback, useMemo, useState } from "react";
import { MuiTelInput } from "mui-tel-input";
import ConfirmDialog from "../../form/ConfirmDialog";

export default function ContactTable (props = {
  contactsData: undefined,
  handleSaveContact: undefined,
  handleDeleteItem: undefined,
  itemType: 'contact',
  group: '',
  setContactsData: undefined,
  editMode: false
}) {

  const [tempRow, setTempRow] = useState({})
  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [showEditPanel, setShowEditPanel] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const [deleteDialog, setDeleteDialog] = useState(false)

  const hideDeleteDialog = () => {
    setTempRow(undefined)
    setDeleteDialog(false)
  }

  const handleConfirmDelete = async () => {
    console.log(props)
    await props.handleDeleteItem(props.itemType ?? 'contact', {...tempRow, group: props.group}, props.setChildItemsData)    
    hideDeleteDialog()
  }

  const editRow = (row) => {
    console.log(row.original)
    setTempRow(row.original)
    setShowEditPanel(true)
  }

  const deleteRow = (row) => {
    setTempRow(row.original)
    setDeleteDialog(true)
  }

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        variant: "outlined",
        onBlur: (event) => { checkColumnError(cell.id, event.target.value, validationErrors, setValidationErrors)
        },
      };
    },
    [validationErrors],
  )

  const handleSaveRowEdits = async (group, item, rowDataSetter) => {    
    if (!Object.keys(validationErrors).length) {
      await props.handleSaveContact(group, item, rowDataSetter)
    }
  }

  const editColumn = { id: 'edit', size: 80, header: 'Actions', columnDefType: 'display', Cell: (
    ({ cell, row }) => (
    <Stack direction='row' spacing={2}>
      <Link onClick={() => editRow(row)}><EditIcon /></Link>
      <Link onClick={() => deleteRow(row)}><DeleteIcon /></Link>
    </Stack>
    )
    )
  }

  const contactsColumns = useMemo(() => {
    const set = [
    { accessorKey: 'name', header: 'Name', size: 160, editable: true,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
    }),
    },
    { accessorKey: 'jobTitle', header: 'Title/Job Position', size: 160, editable: true, 
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
    }),
    },
    { accessorKey: 'phone', header: 'Phone', size: 120, editable: true, required: false},
    { accessorKey: 'extension', header: 'Ext.', size: 40, editable: true, required: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        })
    },
    { accessorKey: 'email', header: 'Email', size: 160, editable: true, 
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        })
    }
  ]
  if (props.editMode) set.push(editColumn)
  return set
  })

  return (
    <div className='tis-form-table'>
      { deleteDialog && <ConfirmDialog title="Delete this item?" text="This action is immediate and cannot be undone. Do you want to delete this item?" 
        open={deleteDialog} 
        hideDialog={hideDeleteDialog} 
        handleConfirm={handleConfirmDelete}
        yesLabel="Delete"
        yesButtonStyle={{backgroundColor: 'red', color: 'white', fontWeight: 'bold'}}
        yesButtonVariant='filled'
        noLabel="Keep"
        noButtonStyle={{backgroundColor: 'green', color: 'white', fontWeight: 'bold'}}
        noButtonVariant='filled'
        />}
      <MaterialReactTable
        columns={contactsColumns}
        // data={keyContactsRows}
        data={props.contactsData || []}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableEditing={false}        
        muiTableContainerProps={{ sx: { minHeight: 300, maxHeight: 400} }}
        
        renderTopToolbar={({ table }) => <Box>
          { props.editMode &&
            <Button color="primary" size="medium" startIcon={<AddIcon />} 
              onClick={() => setShowCreatePanel(true)} 
            >
              Add record
            </Button>
          }
        </Box>}
      />
      {showCreatePanel && <AddRecordModal
        columns={contactsColumns}
        open={!!showCreatePanel}
        onClose={() => setShowCreatePanel(false)}
        onSubmit={(newItem) => props.handleSaveContact(props.group, newItem, props.setContactsData)}
        addRow={addRow}
        rowSetter={props.setContactsData}
        setDirty={props.setDirty}
      /> }
      {showEditPanel && <EditRecordModal
        columns={contactsColumns}
        open={!!showEditPanel}
        getCurrentValues={() => tempRow}
        onClose={() => setShowEditPanel(false)}
        onSubmit={(item) => handleSaveRowEdits(props.group, item, props.setContactsData)}
        rowSetter={props.setContactsData}
        setDirty={props.setDirty}
      /> }
    </div>
  )
}
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import Question from '../form/Question'
import Guidance from '../form/Guidance'
import models from './models/QualityAndFSModels'
import AssessmentCategoryForm from './assessment-components/AssessmentCategoryForm'


export default (props = {}) => {
  let scores = {}
  const [activeCategoriesList, setActiveCategoriesList] = useState()

  const { refreshItemList, subscribeToProgress, defaultAssessmentType } = props

  const onChangePoints = async (event) => {
    let { target } = event
    let id = target.id
    let { value } = target

    scores = {...scores, [[id]]: (value.earnedPoints / value.maxPoints) * 100 }
  }

  const componentMap = {
    Question: (props) => {
      return useMemo(() => <Question {...props}></Question>, [props])
    },
    Guidance: (props) => {
      return useMemo(() => <Guidance {...props}></Guidance>, [props])
    }
  }

  const componentInclude = (list) => {
    return list.map((comp, compidx) => {
      const CompType = componentMap[comp.type]
      return (
        <CompType assessment={props.assessment} refreshItemList={refreshItemList} {...comp.props} key={comp.props.id}>
          {comp.children && componentInclude(comp.children)}
        </CompType>
        )
    })
  }

  const getActiveCategories = () => {
    const cats = () => {
      if (props.assessment.filter) {
        const assessmentCategories = JSON.parse(props.assessment.filter)
        return models.filter((m) => {
          return assessmentCategories.includes(m.props.id)
        })
      } else {
        return models
      }
    }
    return cats
  }


  useEffect(() => {
    setActiveCategoriesList(getActiveCategories())
    
  },[])

  const [anchorEl, setAnchorEl] = useState()
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'category-select-popover' : undefined;
  return (
    <>
    <Paper sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>    
      <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
        {/* <Grid xs={12} md={12}>
          <Button aria-describedby={popoverId} variant="contained" onClick={(event) => {setAnchorEl(event.currentTarget)}}>Select Categories</Button>
          <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={() => {setAnchorEl(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            
          >
            <FormControl margin="normal" component="fieldset" variant="standard" size='small' sx={{padding: 2}}>
            <FormLabel component="legend">Select Desired Categories</FormLabel>
            <FormGroup row>
              { models.map((m, idx) => {
                return (<FormControlLabel key={idx}
                  control={
                    <Checkbox checked={categoryChoices[m.props.id]} onChange={handleCategorySelection} name={m.props.id} size='small'/>
                  }
                  label={[m.props.id, m.props.title].join(' ')}
                />)
                }) 
              }
            </FormGroup>
            <FormHelperText>Select the viewable categories</FormHelperText>
          </FormControl>
            
          </Popover>
        </Grid> */}
        <Grid xs={12} md={12}>

          { activeCategoriesList && activeCategoriesList.map(
            (categoryModel, idx) => 
              <AssessmentCategoryForm 
                categoryModel={categoryModel} 
                assessment={props.assessment} 
                key={idx} 
                refreshItemList={refreshItemList}
                progressItemsList={props.progressItemsList}
                subscribeToProgress={subscribeToProgress} 
                defaultAssessmentType={defaultAssessmentType} />)}

          </Grid>
      </Grid>
      
    </Paper>
    </>
  )
}

import { default as React, useState } from 'react'
import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom';
import EditOutlined from '@mui/icons-material/EditOutlined'

export default function HelpCommandBar (props = {}) {

  const toggleHelp = () => {
    console.log('help...')
  }

  return (
    <div className="tis-commandbar tis-commandbar-help">
      {(props.showCancel === undefined || props.showCancel) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Close</LoadingButton>}
    </div>
  )
}

import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2'
import { FormControl, TextField, Button } from '@mui/material'
import { DeleteForever, Add } from '@mui/icons-material'

export default function LineControl(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const lines = props.newRecord[props.accessor]
    function AddLineButton(_props) {
        return <Grid item>
            <Button
                style={{ height: "100%" }}
                onClick={props.addLine}
                size="small">
                Add line
                <Add size="small" />
            </Button>
        </Grid>
    }
    return <Grid item {...props.gridItemProps} fullWidth>
        <Grid container>
            {lines && !lines.length && !props.disabled ? <AddLineButton /> : ""}
            {lines && lines.length ? lines.map((l, i) => {
                return <>
                    <Grid item>
                        <FormControl>
                            <TextField
                                disabled={props.disabled}
                                type="number"
                                size='small'
                                value={isNaN(parseFloat(l.value)) ? '' : parseFloat(l.value)}
                                onChange={(e) => {
                                    const _newRecord = { ...props.newRecord }
                                    _newRecord[props.accessor][l.index].value = e.target.value
                                    props.setter(_newRecord)
                                }}
                                label={`Line ${l.index + 1} - ${props.type}`}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    {i === lines.length - 1 && <Grid item>
                        <Button hidden={props.disabled} style={{ height: "100%" }}>
                            <DeleteForever onClick={props.removeLine} />
                        </Button>
                    </Grid>}
                    {i === lines.length - 1 && !props.disabled && <AddLineButton />}
                </>
            }) : ""}
        </Grid>
    </Grid>
}
export const calculateTotalEarnedPoints = (agg) => {
    console.log('calculating agg total earned points using agg: ', agg)
    let totalEarnedPoints = agg ? Object.keys(agg).reduce((total, id) => { 
        return total + (agg[id].included && !isNaN(agg[id].earnedPoints) ? agg[id].earnedPoints : 0) 
    }, 0) : undefined
    // console.log('total earned points calculated to be: ', totalEarnedPoints)
    return totalEarnedPoints
}

export const calculateTotalMaxPoints = (agg) => {
    // console.log('calculating agg total max points using agg: ', agg)
    let totalMaxPoints = agg ? Object.keys(agg).reduce((total, id) => { 
        return total + (agg[id].included ? agg[id].maxPoints : 0) 
    }, 0) : undefined
    // console.log('total max points calculated to be: ', totalMaxPoints)
    return totalMaxPoints
}

export const calculateFindingRating = (agg) => {
    return Math.round(agg.totalEarnedPoints * 10000 / agg.totalMaxPoints) / 100
}

export const getPointsClass = (value) => {
    let className
    if(value !== undefined) {
        if(value >= 100) {
            className = 'no-finding'
        } else if (value > 50) {
            className = 'improvement-opportunity'
        } else if (value > 25) {
            className = 'non-conformance'
        } else if (value > 0) {
            className = 'conditional-non-conformance'
        } else if (value === 0) {
            className = 'high-risk-non-conformance'
        }
    }
    return className
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useState } from 'react';

export default function ConfirmDialog(props={
    open: false, 
    hideDialog: undefined, 
    title: '', 
    text: '',    
    requireInputValue: false, 
    inputValue: undefined,
    inputValueSetter: undefined, 
    noButtonVariant: undefined, 
    noButtonStyle: {}, 
    noLabel: 'No', 
    yesLabel: 'Yes', 
    yesButtonVariant: undefined, 
    yesButtonStyle: {},
    isWorking: false,
    status: undefined
  }) {
    const [inputValue, setInputValue] = useState()
    const [isWorking, setIsWorking] = useState(props.isWorking)
    const isValid = () => {
      return !(props.requireInputValue && (!inputValue || inputValue === ''))
    }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.hideDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          {props.status && <DialogContentText>Status: {props.status}</DialogContentText>}
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
          {props.requireInputValue && 
              <TextField multiline
                inputProps={{maxLength: props.maxLength ?? 255}}
                helperText={`${inputValue?.length ?? 0}/${props.maxLength ?? 255}`}
                value={inputValue}
                fullWidth
                onChange={(event) => {
                  setInputValue(event.target.value)
                }}
              />
            }
        </DialogContent>
        <DialogActions>
          {props.noLabel !== undefined && <Button
            variant={props.noButtonVariant || "outlined"} 
            style={props.noButtonStyle} 
            onClick={() => {
              setInputValue()
              props.hideDialog()
            }}>{props.noLabel}</Button>}
          <LoadingButton 
            loading={isWorking}
            variant={ !isValid() || isWorking ? 'text' : (props.yesButtonVariant || 'contained')} 
            style={ !isValid() || isWorking ? {} : props.yesButtonStyle } 
            onClick={() => {
              (async () => {
                setIsWorking(true)              
                await props.handleConfirm(inputValue)
                setIsWorking(false)
              })()
            }}
            disabled={ !isValid() }>{props.yesLabel}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
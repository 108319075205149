import { default as React, useContext, useEffect, useState } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { Factory } from '@mui/icons-material'
import { checkRequiredError, checkStrictRequiredError, checkNumberError, checkPhoneError, checkDateError, checkEmailError, handleFieldChange, checkEmptyArrayError} from '../form/Form'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Category from '../form/Category';
import { MuiTelInput } from 'mui-tel-input';
import { getRequestConfig } from '../auth/auth';
import axios from 'axios';
import { ApiGateway } from '../config/config';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ContactTable from './profile-components/ContactTable';
import ProductTable from './profile-components/ProductTable';
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import { ASMT_STATUS } from '../utils/constants';

export default function EditManufacturerProfilePage (props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const roleDetails = useContext(RoleContext)

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }
  
  const breadcrumb = <div><span><Factory/>Manufacturer Profile</span></div>

  const onSave = async () => {
    await saveManufacturerProfile()
  }

  const tryAgainSave = async () => {
    hideTryAgainDialog()
    await onSave()
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else if (editMode) {
      setEditMode(false)
    } else handleClose()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/manufacturer/profile/list`)
    } else {
      navigate(-1)
    }
  }

  const [fieldErrors, setFieldErrors] = useState({})
  const setError = (e) => {

  }
  const optionValue = (option, value) => {
    if (option==value){
      return true}
    else if (option && option.value && option.value==value){
      return true}
    else if (option && option.label && option.label==value){
      return true}
    else {
      return false}
  }
  const handleCheckboxChange = (id, event) => {
    // console.log(event)
    let newValue = !manufacturerProfile[id]
    // console.log(newValue)
    manufacturerProfile[id] = newValue
    setManufacturerProfile({...manufacturerProfile}, {[[id]]: newValue})
    // console.log(manufacturerProfile)
  }

  const handleCancel = () => {
    handleClose()
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const [manufacturerProfile, setManufacturerProfile] = useState()
  const facilityCertifications = ["Halal", "ISO", "Juice HAACCP", "Kosher", "Organic", "None"]
  const waterSources = ["Well", "Municipality", "Other"]
  const possibleAllergens = ["Milk", "Eggs", "Fish", "Shellfish - Crustacean", "Tree nuts", "Peanuts", "Wheat", "Soybeans", "Sesame", "Sulfites", "Mustard", "Yellow #5"]

  const contactTypes = {
    KEY_CONTACTS: 'keyContacts',
    INVOLVED_IN_ASSESSMENT: 'involvedInAssessment',
    CORPORATE_INVOLVED: 'corporateInvolved',
    POST_AUDIT_INVOLVED: 'postAuditDist'
  }
  const [keyContactsRows, setKeyContactsRows] = useState();
  const [involvedRows, setInvolvedRows] = useState();
  const [corporateRows, setCorporateRows] = useState();
  const [postAuditRows, setPostAuditRows] = useState();

  const productItemTypes = {
    PROCESS_TYPE_LINE: 'process-type-line',
    RAW_MATERIAL: 'raw-material',
    COMAN_PRODUCT: 'comanufactured-product',
    BACKUP_LOCATION: 'backup-location'
  }
  const [productsRows, setProductsRows] = useState([]);
  const [rawMaterialsRows, setRawMaterialsRows] = useState([]);
  const [comanufacturedRows, setComanufacturedRows] = useState([]);
  const [backupRows, setBackupRows] = useState([])

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save profile due to ${saveUnsuccessfulError}, do you want to try again?`
  }
  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const saveManufacturerProfile = async (_profile) => {
    startLoading()
    const requestConfig = await getRequestConfig()
    
    const basePath = `${ApiGateway.manufacturerProfile}/${manufacturerProfile.id}`

    const payload = _profile ?? manufacturerProfile

    try {
      await axios.post(basePath, payload, requestConfig)
      setClean()
    } catch (error) {
      saveUnsuccessfulError = error
      console.error(error)
      setTryAgainDialog(true)
    } finally {
      stopLoading()
    }
  }

  const saveMfrProfileChildItem = async (itemType, item) => {
    const requestConfig = await getRequestConfig()
    
    const itemTypePath = [itemType, item.id ?? ''].join('/')
    const path = `${ApiGateway.manufacturerProfile}/${manufacturerProfile.id}/${itemTypePath}`

    const result = await axios.post(path, item, requestConfig)
    return result.data
  }

  const createOrUpdateMfrProfileChildItem = async(productItemType, item, itemListSetter) => {
    try {
      const result = await saveMfrProfileChildItem(productItemType, item)
      itemListSetter(prev => {
        prev = prev && Array.isArray(prev) ? prev : []
        let isNew = true
        const res = prev.reduce((p, c) => {
          if (c.id === result.childItem.id) {
            isNew = false
            p.push(result.childItem)
          } else {
            p.push(c)
          }
          return p
        }, [])
        if (isNew) res.push(result.childItem)
        
        return res
      })
     } catch (error) {
       console.error(error)
       return error
     }
  }

  const deleteChildItem = async (childItemType, item, itemListSetter) => {
    try {
      const itemTypePath = [childItemType, item.id ?? ''].join('/')
      const path = `${ApiGateway.manufacturerProfile}/${manufacturerProfile.id}/${itemTypePath}`
      const requestConfig = await getRequestConfig()
      await axios.delete(path, requestConfig)
      itemListSetter(prev => {
          prev = prev && Array.isArray(prev) ? prev : []
          const res = prev.reduce((p, c) => {
            if (c.id !== item.id) {
              p.push(c)
            }
            return p
          }, [])
          
          return res
      })
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const createContact = async (group, contact, contactListSetter) => {
    return await createOrUpdateMfrProfileChildItem('contact', {...contact, group}, contactListSetter)
  }

  

  const loadContacts = async (mfrProfileId, requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }
    
    const itemTypePath = 'contact'
    const path = `${ApiGateway.manufacturerProfile}/${mfrProfileId}/${itemTypePath}/`

    const keyContacts = []
    const involved = []
    const corporate = []
    const postAudit = []
  

    const result = await axios.get(path, requestConfig)
    result.data.results.forEach((contact) => {
      switch (contact.group) {
        case contactTypes.KEY_CONTACTS:
          keyContacts.push(contact)
        break
        case contactTypes.INVOLVED_IN_ASSESSMENT:
          involved.push(contact)
        break
        case contactTypes.CORPORATE_INVOLVED:
          corporate.push(contact)
        break
        case contactTypes.POST_AUDIT_INVOLVED:
          postAudit.push(contact)
        break
        default:
      }
    })
    setKeyContactsRows(keyContacts)
    setInvolvedRows(involved)
    setCorporateRows(corporate)
    setPostAuditRows(postAudit)
  }

  const loadMfrProfileChildItems = async (mfrProfileId, requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }
    const basePath = `${ApiGateway.manufacturerProfile}/${mfrProfileId}`
    const responses = await Promise.all([
      axios.get(`${basePath}/${productItemTypes.PROCESS_TYPE_LINE}/`, requestConfig),
      axios.get(`${basePath}/${productItemTypes.RAW_MATERIAL}/`, requestConfig),
      axios.get(`${basePath}/${productItemTypes.COMAN_PRODUCT}/`, requestConfig),
      axios.get(`${basePath}/${productItemTypes.BACKUP_LOCATION}/`, requestConfig)
    ])

    if (responses[0].data) setProductsRows(responses[0].data.results)
    if (responses[1].data) setRawMaterialsRows(responses[1].data.results)
    if (responses[2].data) setComanufacturedRows(responses[2].data.results)
    if (responses[3].data) setBackupRows(responses[3].data.results)
  }

  const loadManufacturerProfile = async (_mfrProfileId, requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }
      
    const basePath = `${ApiGateway.manufacturerProfile}/search`
    let search = {}
    if (_mfrProfileId) {
      search = { id: _mfrProfileId }
    }
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setManufacturerProfile(results[0])
    }
  }

  const loadOpenAssessment = async (_mfrProfileId, requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }

    const basePath = `${ApiGateway.assessments}/search`
    const search = { 
        mfrProfileId: _mfrProfileId,
        status: [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].join(',')
      }
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setOpenAssessment(results[0])
    }
  }

  const initializePage = async () => {
    startLoading()
    const requestConfig = await getRequestConfig()
    await Promise.all([
      loadManufacturerProfile(params.mfrProfileId, requestConfig),
      loadContacts(params.mfrProfileId, requestConfig),
      loadMfrProfileChildItems(params.mfrProfileId, requestConfig),
      loadOpenAssessment(params.mfrProfileId, requestConfig)]
    )
    stopLoading()
  }
  
  const [editMode, setEditMode] = useState(false)
  const [openAssessment, setOpenAssessment] = useState()

  const onEdit = () => {
    setEditMode(true)
  }

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    if (
      roleDetails.hasRole([ROLES.BOTTLER])
      && openAssessment
      ) {
        return true
      }
    return false
  }
  

  useEffect(() => {
    initializePage()
  }, [])
  
  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <FormViewCommandBar 
          onSave={onSave}
          onEdit={onEdit}
          onCancel={confirmCancel}
          onClose={handleClose}
          enableSave={isDirty}
          showClose={ !editMode }
          showCancel={ editMode }
          showEdit={ !editMode && isEditable()}
          showSave={ editMode && isEditable()}
        />
      }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page"/>
      <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={tryAgainSave} yesLabel="Try Again" noLabel="Cancel"/>
      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto'}}
      >
        { manufacturerProfile && 
        <Grid container>
            <Grid xs={12} md={12}>
              <Category category="facilityDetailsPanel" title="Facility Details" hideCollapse={true}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} padding={1}>
                    <Grid xs={12} md={6} padding={1}>
                      <Stack spacing={2}>
                      <TextField size='small' className='tis-form-field' fullWidth id="manufacturer"
                       variant='standard'
                       label="Manufacturer" 
                       value={manufacturerProfile.Manufacturer.manufacturerName} disabled/>
                        {/* <TextField size='small' className='tis-form-field' fullWidth id="ownership" label="Bottler/Ownership" value={manufacturerProfile.ownership} disabled/> */}
                        <Stack direction='row' spacing={2} >
                        <TextField size='small' className='tis-form-field' fullWidth id="addressLine1" 
                        variant='standard'
                        label="Address" 
                        value={manufacturerProfile.Manufacturer.manufacturerAddr} disabled/>

                        <TextField size='small' className='tis-form-field' fullWidth id="city" 
                        variant='standard'
                        label="City" 
                        value={manufacturerProfile.Manufacturer.manufacturerCity} disabled/>
                        </Stack>
                        <Stack direction='row' spacing={2} >
                        <TextField size='small' className='tis-form-field' fullWidth id="state" 
                        variant='standard'
                        label="State"
                        value={manufacturerProfile.Manufacturer.manufacturerState} disabled/>
                      
                        <TextField size='small' className='tis-form-field' fullWidth id="zipCode" 
                        variant='standard'
                        label="Zip Code" 
                        value={manufacturerProfile.Manufacturer.manufacturerZip} disabled/>
                      
                        <TextField size='small' className='tis-form-field' fullWidth id="country" 
                        variant='standard'
                        label="Country" 
                        value={manufacturerProfile.Manufacturer.country} disabled/>
                        </Stack>
                        <Stack direction='row' spacing={2} >
                        <TextField size='small' className='tis-form-field' 
                        variant='standard'
                        label="Region" 
                        value={manufacturerProfile.Manufacturer.manufacturerRegionId} disabled/>
                      
                        <TextField size='small' className='tis-form-field'
                        variant='standard'
                        label="Facility Code" 
                        value={manufacturerProfile.Manufacturer.productionCode} disabled/>

                      </Stack>
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={6} padding={1}>
                      <Stack spacing={2}>
                      <MuiTelInput className='tis-form-field'
                        disabled={!editMode}
                        size='small'
                        defaultCountry="US"
                        disableDropdown
                        forceCallingCode 
                        value={manufacturerProfile.manufacturerPhone} 
                        onChange={(event)=>{
                          handleFieldChange("manufacturerPhone", event, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event, "manufacturerPhone", checkPhoneError)
                        }}
                        onBlur={()=>setError(manufacturerProfile.manufacturerPhone, "manufacturerPhone", checkPhoneError)}
                        label="Phone"
                        placeholder="Phone"
                        error={fieldErrors.manufacturerPhone}
                        helperText={fieldErrors.manufacturerPhone ? "Not a valid phone" : false}
                      />
                      <MuiTelInput className='tis-form-field'
                        disabled={!editMode}
                        size='small'
                        defaultCountry="US"
                        disableDropdown
                        forceCallingCode 
                        value={manufacturerProfile.manufacturerFax} 
                        onChange={(event)=>{
                          handleFieldChange("manufacturerFax", event, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event, "manufacturerFax", checkPhoneError)
                        }}
                        onBlur={()=>setError(manufacturerProfile.manufacturerFax, "manufacturerFax", checkPhoneError)}
                        label="Fax"
                        placeholder="Fax"
                        error={fieldErrors.manufacturerFax}
                        helperText={fieldErrors.manufacturerFax ? "Not a valid fax" : false}
                      />
                      <TextField size='small' className='tis-form-field' fullWidth 
                      disabled={!editMode}
                      id="plantManagerName" 
                      label="Plant Manager" 
                      defaultValue={manufacturerProfile.plantManagerName} 
                      onBlur={(event)=>handleFieldChange("plantManagerName", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)} />

                      <TextField size='small' className='tis-form-field' fullWidth 
                      disabled={!editMode}
                      id="plantManagerEmail" label="Plant Manager Email" 
                      error={fieldErrors.plantManagerEmail} 
                      onBlur={(event) => { 
                        handleFieldChange("plantManagerEmail", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                        setError(event.target.value, "plantManagerEmail", checkEmailError)
                      }} 
                      defaultValue={manufacturerProfile.plantManagerEmail} 
                      helperText={fieldErrors.plantManagerEmail ? "Invalid Email" : ""}/>

                      <MuiTelInput className='tis-form-field'
                        disabled={!editMode}
                        size='small'
                        defaultCountry="US"
                        disableDropdown
                        forceCallingCode 
                        value={manufacturerProfile.plantManagerPhone} 
                        onChange={(event)=>{
                          handleFieldChange("plantManagerPhone", event, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event, "plantManagerPhone", checkPhoneError)
                        }}
                        onBlur={()=>setError(manufacturerProfile.plantManagerPhone, "plantManagerPhone", checkPhoneError)}
                        label="Plant Manager Phone"
                        placeholder="Plant Manager Phone"
                        error={fieldErrors.plantManagerPhone}
                        helperText={fieldErrors.plantManagerPhone ? "Not a valid phone" : false}
                      />

                      <TextField size='small' className='tis-form-field' fullWidth 
                      disabled={!editMode}
                      id="qaManagerName" 
                      required
                      label="Corrective Action Facilitator (Name)" 
                      defaultValue={manufacturerProfile.qaManagerName} 
                      onBlur={(event)=>handleFieldChange("qaManagerName", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)} />

                      <TextField size='small' className='tis-form-field' fullWidth 
                      disabled={!editMode}
                      required
                      id="qaManagerEmail" label="Corrective Action Facilitator Email" 
                      error={fieldErrors.qaManagerEmail} 
                      onBlur={(event) => { 
                        handleFieldChange("qaManagerEmail", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                        setError(event.target.value, "qaManagerEmail", checkEmailError)
                      }} 
                      defaultValue={manufacturerProfile.qaManagerEmail} 
                      helperText={fieldErrors.qaManagerEmail ? "Invalid Email" : ""}/>

                      <MuiTelInput className='tis-form-field'
                        disabled={!editMode}
                        size='small'
                        defaultCountry="US"
                        disableDropdown
                        forceCallingCode 
                        value={manufacturerProfile.qaManagerPhone} 
                        onChange={(event)=>{
                          handleFieldChange("qaManagerPhone", event, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event, "qaManagerPhone", checkPhoneError)
                        }}
                        onBlur={()=>setError(manufacturerProfile.qaManagerPhone, "qaManagerPhone", checkPhoneError)}
                        label="Corrective Action Facilitator Phone"
                        placeholder="Corrective Action Facilitator Phone"
                        error={fieldErrors.qaManagerPhone}
                        helperText={fieldErrors.qaManagerPhone ? "Not a valid phone" : false}
                      />
                    
                      
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={12}>
                      
                    </Grid>
                  </Grid>
                </Box> 
              </Category>

              <Category category="ContactsPanel" title="Manufacturer Contacts" hideCollapse={true} defaultExpanded={false}>
              <Grid xs={12} md={12}><hr/></Grid>
              <Grid xs={12} md={12}>
                <Typography className='tis-form-title'>Key Contacts</Typography>
                <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ContactTable 
                      contactsData={keyContactsRows || []}
                      handleSaveContact={createContact}
                      handleDeleteItem={deleteChildItem}
                      group={contactTypes.KEY_CONTACTS}
                      setContactsData={setKeyContactsRows}
                      editMode={editMode}
                    />
                  </div>
                </Box>
                </Grid>
                <Grid xs={12} md={12}><hr/></Grid>
                <Grid xs={12} md={12}>
                  <Typography className='tis-form-title'>Involved In Assessment Process</Typography>
                  <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ContactTable 
                      contactsData={involvedRows || []}
                      handleSaveContact={createContact}
                      handleDeleteItem={deleteChildItem}
                      group={contactTypes.INVOLVED_IN_ASSESSMENT}
                      setContactsData={setInvolvedRows}
                      editMode={editMode}
                    />
                  </div>
                  </Box>  
                </Grid>
                <Grid xs={12} md={12}><hr/></Grid>
                <Grid xs={12} md={12}>
                  <Typography className='tis-form-title'>Corporate Office Involved In Assessment Process</Typography>
                  <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ContactTable 
                      contactsData={corporateRows || []}
                      handleSaveContact={createContact}
                      handleDeleteItem={deleteChildItem}
                      group={contactTypes.CORPORATE_INVOLVED}
                      setContactsData={setCorporateRows}
                      editMode={editMode}
                    />
                  </div>
                  </Box>  
                </Grid>
                <Grid xs={12} md={12}><hr/></Grid>
                <Grid xs={12} md={12}>
                  <Typography className='tis-form-title'>Post-Audit Distribution Communications Stakeholders</Typography>
                  <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ContactTable 
                      contactsData={postAuditRows || []}
                      handleSaveContact={createContact}
                      handleDeleteItem={deleteChildItem}
                      group={contactTypes.POST_AUDIT_INVOLVED}
                      setContactsData={setPostAuditRows}
                      editMode={editMode}
                    />
                  </div>
                  </Box>  
                </Grid>

              </Category>
              
              
              
              <Category category="additionalFacilityInfoPanel" title="Additional Facility Information" hideCollapse={true}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid xs={12} md={12}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        required
                        id="fdaRegistrationNumber" 
                        label="FDA Registration Number (Bioterrorism)" 
                        defaultValue={manufacturerProfile.fdaRegistrationNumber}
                        onBlur={(event) => handleFieldChange("fdaRegistrationNumber", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                        />
                    </Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Do you have ISO 9001 Certification?*</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormControl size='small' className='tis-form-field' fullWidth error={fieldErrors.has9001}>
                        <InputLabel id="has9001"></InputLabel>
                        <Select
                          id="has9001"
                          disabled={!editMode}
                          value={manufacturerProfile.has9001}
                          onChange={(event) => handleFieldChange("has9001", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.has9001, "has9001")}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={2}>Not Applicable</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.has9001} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div hidden={manufacturerProfile.has9001 !== 1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field'
                        format="MM/DD/YYYY" id="iso9001LastCertificationDate" 
                        label="Date Certified" 
                        disabled={!editMode}
                        value={dayjs(manufacturerProfile.iso9001LastCertificationDate)}                         
                          slotProps={{
                            textField: {
                              size: 'small',
                              required: true,
                              error: (manufacturerProfile.has9001 === 1 && fieldErrors.iso9001LastCertificationDate),
                              helperText: ((manufacturerProfile.has9001 === 1 && fieldErrors.iso9001LastCertificationDate) ? "This field is required" : false),
                              onBlur: (event) => {
                                handleFieldChange("iso9001LastCertificationDate", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                                setError(dayjs(event.target.value), "iso9001LastCertificationDate", checkDateError)
                              }
                            },
                          }}
                          onChange={(value) => {
                            handleFieldChange("iso9001LastCertificationDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                            setError(value, "iso9001LastCertificationDate", checkDateError)
                          }}
                        />
                      </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Do you have FSSC 22000 v4.1 Certification?*</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormControl size='small' className='tis-form-field' fullWidth error={fieldErrors.has22000}>
                        <InputLabel id="has22000"></InputLabel>
                        <Select
                          id="has22000"
                          disabled={!editMode}
                          value={manufacturerProfile.has22000}
                          onChange={(event) => handleFieldChange("has22000", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.has22000, "has22000")}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.has22000} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div hidden={!manufacturerProfile.has22000}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field' 
                          disabled={!editMode}
                          format="MM/DD/YYYY" 
                          id="fssc22000LastCertificationDate" 
                          label="Date Certified" 
                          value={dayjs(manufacturerProfile.fssc22000LastCertificationDate)} 
                          slotProps={{
                            textField: {
                              size: 'small',
                              required: true,
                              error: (manufacturerProfile.has22000 && fieldErrors.fssc22000LastCertificationDate),
                              helperText: ((manufacturerProfile.has22000 && fieldErrors.fssc22000LastCertificationDate) ? "This field is required" : false),
                              onBlur: (event) => {
                                handleFieldChange("fssc22000LastCertificationDate", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                                setError(dayjs(event.target.value), "fssc22000LastCertificationDate", checkDateError)
                              }
                            },
                          }}
                          onChange={(value) => {
                            handleFieldChange("fssc22000LastCertificationDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                            setError(value, "fssc22000LastCertificationDate", checkDateError)
                          }}
                        />
                      </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Do you have ISO 14001 Certification?*</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormControl size='small' className='tis-form-field' fullWidth error={fieldErrors.has14001}
                        >
                        <InputLabel id="has14001"></InputLabel>
                        <Select
                          id="has14001"
                          disabled={!editMode}
                          value={manufacturerProfile.has14001}
                          onChange={(event) => handleFieldChange("has14001", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.has14001, "has14001")}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={2}>Not Applicable</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.has14001} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div hidden={manufacturerProfile.has14001 !== 1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field' 
                        disabled={!editMode}
                        format="MM/DD/YYYY" 
                        id="iso14001LastCertificationDate" 
                        label="Date Certified" 
                        value={dayjs(manufacturerProfile.iso14001LastCertificationDate)} 
                          slotProps={{
                            textField: {
                              size: 'small',
                              required: true,
                              error: (manufacturerProfile.has22000 === 1 && fieldErrors.iso14001LastCertificationDate),
                              helperText: ((manufacturerProfile.has22000 === 1 && fieldErrors.iso14001LastCertificationDate) ? "This field is required" : false),
                              onBlur: (event) => {
                                handleFieldChange("iso14001LastCertificationDate", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                                setError(dayjs(event.target.value), "iso14001LastCertificationDate", checkDateError)
                              }
                            },
                          }}
                          onChange={(value) => {
                            handleFieldChange("iso14001LastCertificationDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                            setError(value, "iso14001LastCertificationDate", checkDateError)
                          }}
                        />
                      </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Do you have ISO 45001 (OHSAS 18001) Certification?*</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormControl size='small' className='tis-form-field' fullWidth error={fieldErrors.has18001}>
                        <InputLabel id="has18001"></InputLabel>
                        <Select
                          id="has18001"
                          disabled={!editMode}
                          value={manufacturerProfile.has18001}
                          onChange={(event) => handleFieldChange("has18001", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.has18001, "has18001")}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={2}>Not Applicable</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.has18001} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div hidden={manufacturerProfile.has18001 !== 1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field' 
                          disabled={!editMode}
                          format="MM/DD/YYYY" 
                          id="ohsas18001LastCertificationDate" 
                          label="Date Certified" 
                          value={dayjs(manufacturerProfile.ohsas18001LastCertificationDate)} 
                          slotProps={{
                            textField: {
                              size: 'small',
                              required: true,
                              error: (manufacturerProfile.has18001 === 1 && fieldErrors.ohsas18001LastCertificationDate),
                              helperText: ((manufacturerProfile.has18001 === 1 && fieldErrors.ohsas18001LastCertificationDate) ? "This field is required" : false),
                              onBlur: (event) => {
                                handleFieldChange("ohsas18001LastCertificationDate", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                                setError(dayjs(event.target.value), "ohsas18001LastCertificationDate", checkDateError)
                              }
                            },
                          }}
                          onChange={(value) => {
                            handleFieldChange("ohsas18001LastCertificationDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                            setError(value, "ohsas18001LastCertificationDate", checkDateError)
                          }}
                        />
                      </LocalizationProvider>
                      </div>
                    </Grid>


                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Other Third Party Audits</Typography></Grid>
                    <Grid xs={12} md={12}>
                      <TextField size='small' className='tis-form-field'
                      disabled={!editMode}
                        fullWidth 
                        id="otherThirdPartyAudits" 
                        placeholder="e.g. AIB, GMA-SAFE, Silliker" 
                        onBlur={(event)=>handleFieldChange("otherThirdPartyAudits", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)} 
                        defaultValue={manufacturerProfile.otherThirdPartyAudits}/>
                    </Grid>

                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Last GAO Q1 Audit</Typography></Grid>
                    <Grid xs={12} md={6}>                    
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field' 
                        disabled={!editMode}
                        format="MM/DD/YYYY" 
                        required
                        id="lastGaoQ1AuditDate" label="Date" 
                        slotProps={{
                          textField: {
                            size: 'small'
                          }
                        }}
                        value={dayjs(manufacturerProfile.lastGaoQ1AuditDate)} 
                        onChange={(value) => {
                          handleFieldChange("lastGaoQ1AuditDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(value, "lastGaoQ1AuditDate", checkDateError)
                        }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Last GAO E1, S1 Audit</Typography></Grid>
                    <Grid xs={12} md={6}>                    
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='tis-form-field' 
                        disabled={!editMode}
                        format="MM/DD/YYYY" 
                        required
                        id="lastGaoE1S1AuditDate" label="Date" 
                        slotProps={{
                          textField: {
                            size: 'small'
                          }
                        }}
                        value={dayjs(manufacturerProfile.lastGaoE1S1AuditDate)}
                        onChange={(value) => {
                          handleFieldChange("lastGaoE1S1AuditDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(value, "lastGaoE1S1AuditDate", checkDateError)
                        }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}>
                    <Autocomplete className='tis-form-field'
                        isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                        disabled={!editMode}
                        multiple
                        id="manufacturerCertificates"
                        size='small'
                        options={facilityCertifications}
                        //getOptionLabel={(option) => option.label}
                        openOnFocus
                        value={manufacturerProfile.manufacturerCertificates ? manufacturerProfile.manufacturerCertificates.split(',') : []}
                        onChange={(_event, values) => { 
                          handleFieldChange("manufacturerCertificates", values.join(','), manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(values, "manufacturerCertificates", checkRequiredError)
                        }}
                        onClose={() => setError(manufacturerProfile.manufacturerCertificates, "manufacturerCertificates", checkEmptyArrayError)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Facility certifications"
                            error={fieldErrors.manufacturerCertificates}
                            helperText={(fieldErrors.manufacturerCertificates ? "This field is required" : false)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={12}>
                      <Stack spacing={2}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="keyPositionsOpen" 
                        label="Key plant positions open" 
                        onBlur={(event) => { 
                          handleFieldChange("keyPositionsOpen", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event.target.value, "keyPositionsOpen", checkNumberError)
                        }} 
                        defaultValue={manufacturerProfile.keyPositionsOpen}
                        error={!!fieldErrors.keyPositionsOpen}
                        helperText={fieldErrors.keyPositionsOpen}
                        type="number" />
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="keyPositionsOpen1Year" 
                        label="Key plant positions <1yr" 
                        onBlur={(event) => { 
                          handleFieldChange("keyPositionsOpen1Year", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event.target.value, "keyPositionsOpen1Year", checkNumberError)
                        }} 
                        defaultValue={manufacturerProfile.keyPositionsOpen1Year}
                        type="number" 
                        error={!!fieldErrors.keyPositionsOpen1Year}
                        helperText={fieldErrors.keyPositionsOpen1Year}
                      />
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Process Water</Typography></Grid>
                    <Grid xs={12} md={12}>
                      <Autocomplete className='tis-form-field'
                        disabled={!editMode}
                        isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                        multiple
                        size='small'
                        openOnFocus
                        id="processWater"
                        onChange={(_event, values) => { 
                          handleFieldChange("processWater", values.join(','), manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(values, "processWater", checkStrictRequiredError)
                        }}
                        onClose={() => setError(manufacturerProfile.processWater, "processWater", checkStrictRequiredError)}
                        options={waterSources} 
                        value={manufacturerProfile.processWater ? manufacturerProfile.processWater.split(',') : []} 
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Source of process water"
                            error={fieldErrors.processWater}
                            helperText={(fieldErrors.processWater ? "This field is required" : false)}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid xs={12} md={6}></Grid> */}
                    <Grid xs={12} md={12}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="effluentTreatment" 
                        label="Describe the effluent treatment and destination" 
                        onBlur={(event) => { 
                          handleFieldChange("effluentTreatment", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                        }}
                        defaultValue={manufacturerProfile.effluentTreatment}
                        />
                    </Grid>
                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-title'>Other Processes</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormGroup className='tis-form-field' id="co2Cylinder">
                        <FormControlLabel control={
                          <Checkbox 
                            checked={manufacturerProfile.co2Cylinder} 
                            disabled={!editMode}
                            onChange={(event) => handleCheckboxChange("co2Cylinder", event)}
                            />} 
                            label="CO2 Cylinder Filling" />
                      </FormGroup>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField size='small' className='tis-form-field'
                        fullWidth 
                        id="co2CylinderLine" 
                        hidden={!manufacturerProfile.co2Cylinder}
                        disabled={!editMode}
                        label="Line" 
                        defaultValue={manufacturerProfile.co2CylinderLine}
                        onBlur={(event) => { 
                          handleFieldChange("co2CylinderLine", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event.target.value, "co2CylinderLine")
                        }}
                        error={manufacturerProfile.co2Cylinder && fieldErrors.co2CylinderLine}
                        helperText={
                          (manufacturerProfile.co2Cylinder && fieldErrors.co2CylinderLine) ? "This field is required" : false
                          }
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <FormGroup className='tis-form-field' id="ilbm">
                        <FormControlLabel control={
                          <Checkbox 
                            checked={manufacturerProfile.ilbm} 
                            disabled={!editMode}
                            onChange={(event) => handleCheckboxChange("ilbm", event)}
                            />} 
                            label="In Line Blow Molding (ILBM)" />
                      </FormGroup>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        hidden={!manufacturerProfile.ilbm}
                        fullWidth 
                        id="ilbmLine" 
                        label="Line" 
                        defaultValue={manufacturerProfile.ilbmLine}
                        onBlur={(event) => { 
                          handleFieldChange("ilbmLine", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event.target.value, "ilbmLine")
                        }}
                        error={manufacturerProfile.ilbm && fieldErrors.ilbmLine}
                        helperText={
                          (manufacturerProfile.ilbm && fieldErrors.ilbmLine) ? "This field is required" : false
                          }
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <FormGroup className='tis-form-field' id="ionizedAirRinsing">
                        <FormControlLabel control={
                          <Checkbox 
                            disabled={!editMode}
                            checked={manufacturerProfile.ionizedAirRinsing} 
                            onChange={(event) => handleCheckboxChange("ionizedAirRinsing", event)}
                            />} 
                            label="Ionized Air Rinsing" />
                      </FormGroup>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        hidden={!manufacturerProfile.ionizedAirRinsing}
                        fullWidth 
                        id="ionizedAirRinsingLine" 
                        label="Line" 
                        defaultValue={manufacturerProfile.ionizedAirRinsingLine}
                        onBlur={(event) => { 
                          handleFieldChange("ionizedAirRinsingLine", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(event.target.value, "ionizedAirRinsingLine")
                        }}
                        error={manufacturerProfile.ionizedAirRinsing && fieldErrors.ionizedAirRinsingLine}
                        helperText={
                          (manufacturerProfile.ionizedAirRinsing && fieldErrors.ionizedAirRinsingLine) ? "This field is required" : false
                          }
                        />
                    </Grid>
                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-title'>Food Allergens and Sensitivities</Typography></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Are allergens or allergen containing products manufactured in this facility?</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <FormControl size='small' className='tis-form-field'/*sx={{ width: '10ch' }}*/ fullWidth error={fieldErrors.allergensManufactured}>
                        <InputLabel id="allergensManufactured"></InputLabel>
                        <Select
                          id="allergensManufactured"
                          disabled={!editMode}
                          value={manufacturerProfile.allergensManufactured}
                          onChange={(event) => handleFieldChange("allergensManufactured", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.allergensManufactured, "allergensManufactured")}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.allergensManufactured} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                    </Grid>
                    <Grid xs={12} md={12} hidden={!manufacturerProfile.allergensManufactured}>
                      <Autocomplete className='tis-form-field'
                        isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                        disabled={!editMode}
                        multiple
                        size='small'
                        id="allergens"
                        options={possibleAllergens}
                        value={manufacturerProfile.allergens ? manufacturerProfile.allergens.split(',') : []}
                        onChange={(_event, values) => { 
                          handleFieldChange("allergens", values.join(','), manufacturerProfile, setManufacturerProfile, setDirty)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="List of allergens in produced products"
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={12} hidden={ !manufacturerProfile.allergensManufactured }>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="allergenLines" 
                        label="Which lines are allergen containing products produced on?" 
                        onBlur={(event)=>handleFieldChange("allergenLines", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)} 
                        defaultValue={manufacturerProfile.allergenLines}
                        />
                    </Grid>
                    <Grid xs={12} md={12} hidden={ !manufacturerProfile.allergensManufactured }>
                      <Autocomplete className='tis-form-field'
                        disabled={!editMode}
                        isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                        multiple
                        size='small'
                        id="allergensPresentInDist"
                        options={possibleAllergens}
                        value={manufacturerProfile.allergensPresentInDist ? manufacturerProfile.allergensPresentInDist.split(',') : []}
                        onChange={(_event, values) => { 
                          handleFieldChange("allergensPresentInDist", values.join(','), manufacturerProfile, setManufacturerProfile, setDirty)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Allergens that could be present in distributed products at this facility"
                          />
                        )}
                      /> 
                    </Grid>
                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-title'>Supplier Guiding Principles</Typography></Grid>
                    <Grid xs={12} md={12}>
                      <Stack direction='row' spacing='2'>
                        <FormControl size='small' className='tis-form-field'
                          // sx={{ width: '25ch' }}
                          error={fieldErrors.status}
                          >
                          <InputLabel id="status">Status</InputLabel>
                          <Select
                            id="status"
                            disabled={!editMode}
                            value={manufacturerProfile.supplierGuidingPrinciplesStatus}
                            onChange={(event) => handleFieldChange("supplierGuidingPrinciplesStatus", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                            onClose={()=>setError(manufacturerProfile.supplierGuidingPrinciplesStatus, "supplierGuidingPrinciplesStatus")}
                          >
                            <MenuItem value={"Not Scheduled"}>Not Scheduled</MenuItem>
                            <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                          </Select>
                          <FormHelperText hidden={fieldErrors.supplierGuidingPrinciplesStatus} >This field is required</FormHelperText>
                        </FormControl>
                      {(manufacturerProfile.supplierGuidingPrinciplesStatus==="Scheduled" || manufacturerProfile.supplierGuidingPrinciplesStatus==="Completed") && 
                        
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker className='tis-form-field' 
                              disabled={!editMode}
                              format="MM/DD/YYYY" 
                              id="supplierGuidingPrinciplesDate" 
                              label="Date Scheduled or Completed" 
                              value={dayjs(manufacturerProfile.supplierGuidingPrinciplesDate)}  
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  required: true,
                                  error: ((manufacturerProfile.supplierGuidingPrinciplesStatus === "Scheduled" || manufacturerProfile.supplierGuidingPrinciplesStatus === "Completed") && fieldErrors.supplierGuidingPrinciplesDate),
                                  helperText: (((manufacturerProfile.supplierGuidingPrinciplesStatus === "Scheduled" || manufacturerProfile.supplierGuidingPrinciplesStatus === "Completed") && fieldErrors.supplierGuidingPrinciplesDate) ? "This field is required" : false),
                                  onBlur: (event) => {
                                    handleFieldChange("supplierGuidingPrinciplesDate", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                                    setError(dayjs(event.target.value), "supplierGuidingPrinciplesDate", checkDateError)
                                  }
                                },
                              }}
                              onChange={(value) => {
                                handleFieldChange("supplierGuidingPrinciplesDate", value, manufacturerProfile, setManufacturerProfile, setDirty)
                                setError(value, "supplierGuidingPrinciplesDate", checkDateError)
                              }}
                            />
                          </LocalizationProvider>
                        }
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={6} hidden={!(manufacturerProfile.supplierGuidingPrinciplesStatus==="Scheduled" || manufacturerProfile.supplierGuidingPrinciplesStatus==="Completed")} >
                      <FormControl size='small' className='tis-form-field'
                        fullWidth error={fieldErrors.supplierGuidingPrinciplesRating}
                        >
                        <InputLabel id="supplierGuidingPrinciplesRating">Rating</InputLabel>
                        <Select
                          id="supplierGuidingPrinciplesRating"
                          disabled={!editMode}
                          value={manufacturerProfile.supplierGuidingPrinciplesRating}
                          onChange={(event) => handleFieldChange("supplierGuidingPrinciplesRating", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                          onClose={()=>setError(manufacturerProfile.supplierGuidingPrinciplesRating, "supplierGuidingPrinciplesRating")}
                        >
                          <MenuItem value={"Pass"}>Pass</MenuItem>
                          <MenuItem value={"Fail"}>Fail</MenuItem>
                        </Select>
                        <FormHelperText hidden={!fieldErrors.supplierGuidingPrinciplesRating} >This field is required</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6} hidden={!(manufacturerProfile.status==="Scheduled" || manufacturerProfile.status==="Completed")} >
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="thirdPartyAuditor" 
                        label="Third Party Auditor" 
                        onBlur={(event)=>handleFieldChange("thirdPartyAuditor", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)}
                        defaultValue={manufacturerProfile.thirdPartyAuditor}
                        />
                    </Grid>
                    <Grid xs={12} md={12}><hr/></Grid>
                    <Grid xs={12} md={12}><Typography className='tis-form-title'>For CoMan</Typography>
                    <Grid xs={12} md={12}><Typography className='tis-form-text'>Relative to other customers of this facility, the volume shipped to the Coca-Cola system is:</Typography></Grid>
                    <Grid xs={12} md={6}>
                      <TextField size='small' className='tis-form-field'
                        disabled={!editMode}
                        fullWidth 
                        id="volumeShipped" 
                        label="Volume shipped" 
                        onBlur={(event)=>{
                          handleFieldChange("volumeShipped", event.target.value, manufacturerProfile, setManufacturerProfile, setDirty)
                          setError(manufacturerProfile.volumeShipped, "volumeShipped", checkNumberError)
                        }}
                        defaultValue={manufacturerProfile.volumeShipped}
                        type="number" 
                        error={!!fieldErrors.volumeShipped}
                        helperText={fieldErrors.volumeShipped}
                        />
                    </Grid>
                    </Grid>
                  </Grid>
                </Box> 
              </Category>
              <Category category="productsProcessTypesPanel" title="Products and Process Types currently manufactured in this location" hideCollapse={true}>
                <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ProductTable 
                      columnSet='productsColumns'
                      productsData={productsRows || []}
                      handleSaveItem={createOrUpdateMfrProfileChildItem}
                      handleDeleteItem={deleteChildItem}
                      itemType={productItemTypes.PROCESS_TYPE_LINE}
                      setProductsData={setProductsRows}
                      editMode={editMode}
                    />
                  </div>
                </Box>
              </Category>
              <Category category="rawMaterialsPanel" title="Raw Materials" hideCollapse={true}>
                <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ProductTable 
                      columnSet='rawMaterialsColumns'
                      productsData={rawMaterialsRows || []}
                      handleSaveItem={createOrUpdateMfrProfileChildItem}
                      handleDeleteItem={deleteChildItem}
                      itemType={productItemTypes.RAW_MATERIAL}
                      setProductsData={setRawMaterialsRows}
                      editMode={editMode}
                    />
                  </div>
                </Box>
              </Category>
              <Category category="comanufacturedPanel" title="Products co-manufactured on the same lines as products produced for The Coca-Cola Company" hideCollapse={true}>
                <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ProductTable 
                      columnSet='comanufacturedColumns'
                      productsData={comanufacturedRows || []}
                      handleSaveItem={createOrUpdateMfrProfileChildItem}
                      handleDeleteItem={deleteChildItem}
                      itemType={productItemTypes.COMAN_PRODUCT}
                      setProductsData={setComanufacturedRows}
                      editMode={editMode}
                    />
                  </div>
                </Box>
              </Category>
              <Category category="backupLocationsPanel" title="Business Continuity: List backup locations" hideCollapse={true}>
                <Box sx={{ mt: 0, minHeight: 300}}>
                  <div className='tis-form-table'>
                    <ProductTable 
                      columnSet='backupColumns'
                      productsData={backupRows || []}
                      handleSaveItem={createOrUpdateMfrProfileChildItem}
                      handleDeleteItem={deleteChildItem}
                      itemType={productItemTypes.BACKUP_LOCATION}
                      setProductsData={setBackupRows}
                      editMode={editMode}
                    />
                  </div>
                </Box>
              </Category>
          </Grid>
        </Grid> 
        }
      </Paper>
    </Page>
  )
}
export default function Footer(props = {}) {
  
  let fullScreenStyle = {
    marginLeft: "0px"
  }

  return (
    <footer style={props.fullScreen ? fullScreenStyle : {}} className="main-footer navbar navbar-default navbar-fixed-bottom">
      <div className="pull-right hidden-xs">
        {props.version && <b>Version</b>} {props.version}
      </div>
      <strong>Copyright &copy; {new Date().getFullYear()} <img className="ml-0_5em" src={require('../img/coca-cola-xs.png')} alt="Coca Cola"/></strong> All rights reserved.
    </footer> 
  )
}
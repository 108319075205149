import { Box, Button, Dialog, DialogActions, DialogContent, Slider } from "@mui/material"

import React, { useState } from "react"

export default function HelpDialog(props={ linkText: 'help', startIcon: undefined, endIcon: undefined, supportZoom: false, fullScreen: undefined, fullWidth: undefined }) {
  const [clicked, setClicked] = useState(null)
  const [zoomRatio, setZoomRatio] = useState('100%')

  const handleHelp = (e) => {
    setClicked(e.currentTarget)
  }

  const handleClose = () => {
    setClicked(null)
  }
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        zoomRatio: zoomRatio,
      })
    })
  };

  const isOpen = Boolean(clicked)

  return (
    <>
    <Button onClick={handleHelp} variant="text" className="help-button" startIcon={props.startIcon} endIcon={props.endIcon}>{props.linkText}</Button>
    <Dialog className="help-dialog"
    // sx={{ pointerEvents: 'visible', zIndex: 9001, maxWidth: '100%' }}
    fullScreen={props.fullScreen}
    fullWidth={props.fullWidth}
    maxWidth='xl'
    open={isOpen}
    anchorEl={clicked}
    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
    transformOrigin={{ vertical: 'center', horizontal: 'left' }}
    onClose={handleClose}
    disableRestoreFocus
    >
      <DialogActions>
        {props.supportZoom && 
          <Slider
            size="small"
            min={50}
            defaultValue={100}
            max={200}
            aria-label="Small"
            valueLabelDisplay="off"
            marks={[{value:50, label:'50'},{value:100, label:'100'},{value:200, label:'200'}]}
            sx={{marginLeft: '30px', marginRight: '30px'}}
            onChange={(e, v) => setZoomRatio(`${v}%`)}
          />
        }
        <Button variant='contained' onClick={handleClose}>Close</Button>
      </DialogActions>
      <DialogContent dividers={true} >
        <Box padding='10px'>
            { props.supportZoom && renderChildren() }
            { !props.supportZoom && props.children }
        </Box>
      </DialogContent>
    </Dialog>
    </>
  )
}
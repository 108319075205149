import Page from '../layout/Page'
import { Help } from '@mui/icons-material'
import HelpCommandBar from '../commandBar/HelpCommandBar'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import markdownit from 'markdown-it'

export default function HelpPage(props = {}) {
    const md = markdownit({html: true});
    const navigate = useNavigate()
    const location = useLocation();

    // props
    // onCancel
    // pageName
    // markdown
    // processMarkdown

    const handleCancel = () => {
        console.log('location?.state', location?.state)
        navigate(props.backTo || location?.state?.backTo || -1, {state: location?.state || {}})
    }

    const breadcrumb = <span><Help />&nbsp;{props.pageName}</span>
    const commandBar = <HelpCommandBar onCancel={handleCancel} />

    const markdown = md.render(props.markdown || "")
    const processedMarkdown = props.processMarkdown ? props.processMarkdown(markdown) : markdown

    return (
        <Page {...props} breadcrumb={breadcrumb} commandBar={commandBar}>
            <div className='help-section' dangerouslySetInnerHTML={{ __html: processedMarkdown }}></div>
        </Page>
    )
}

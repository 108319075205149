import { default as React } from 'react'
import { LoadingButton } from '@mui/lab';
import { HistoryEdu, ArrowBack, Publish } from '@mui/icons-material'

export default function AuthorizationPreviewCommandBar(props = {
  loading: undefined,
  signed: undefined,
  onSign: undefined,
  onPublish: undefined,
  onBack: undefined,
}) {

  return (
    <div className="tis-commandbar tis-commandbar-formview">
      {!props.signed && <LoadingButton loading={props.loading} variant="contained" onClick={props.onSign} endIcon={<HistoryEdu />}>Sign</LoadingButton>}
      {props.signed && <LoadingButton loading={props.loading} variant="contained" onClick={props.onPublish} endIcon={<Publish />}>Publish</LoadingButton>}
      <LoadingButton loading={props.loading} variant="outlined" onClick={props.onBack} endIcon={<ArrowBack />} >Back</LoadingButton>
    </div>
  )
}

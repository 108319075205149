import { useEffect } from 'react'
import Footer from "./Footer"
import Header from "./Header"

import AuthenicatedLayout from './AuthenticatedLayout'

export default function Layout(props = {}) {
  useEffect(() => {

  })

  return (
    <div>
      <Header
        userFullName={props.userFullName}
        appName={<div style={{
          height: '46px',
          padding: '5px'
          }}>
            <span className="logo-lg">N.A.&nbsp;QSE&nbsp;Portal</span>
          </div>}
        />
        <AuthenicatedLayout />
      <Footer version = "1.0.0" />
    </div>
  )
}
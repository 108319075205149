import { default as React, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HealthAndSafety } from '@mui/icons-material'
import ListIMLogs from './ListIMLogs'
import RoleContext from '../auth/RoleContext'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import { Button, Grid } from '@mui/material';

import incidentMgmtImage from '../img/incident-mgmt.png'
import { Alert } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import TemporaryAlert from './TemporaryAlert'

export default function ListIMLogsPage(props = {}) {
  const params = useParams()
  const { state } = useLocation() || {};
  const { submissionSuccessful, submissionError, submissionMessage } = state || {}
  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [manufacturerId, setManufacturerId] = useState(params.manufacturerId || 0)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const qseBreadcrumb = <span><HealthAndSafety />&nbsp;IM Logs</span>
  const bottlerBreadcrumb = <span><HealthAndSafety />&nbsp;Incident Management</span>

  const [showFiltersButton, setShowFiltersButton] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAdd, setShowAdd] = useState(true)

  const addRecord = () => {
    navigate(`/incident-management/create${mfrProfileId ? '/' + mfrProfileId : ''}`)
  }

  const showAddButton = () => {
    setShowAdd(true)
  }
  const hideAddButton = () => {
    setShowAdd(false)
  }

  return (
    <RoleContext.Consumer>
      {roleCtx =>
        <Page {...props} breadcrumb={roleCtx.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) ? bottlerBreadcrumb : qseBreadcrumb} isLoading={isLoading} commandBar={
          <ListViewCommandBar
            loading={isLoading}
            helpPage="/incident-management/help"
            helpPageState={{
              backTo: '/incident-management/create',
            }}
            showFilters={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR, ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && showFiltersButton}
            toggleFilters={toggleFilters}
            addRecord={addRecord}
            showAdd={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && showAdd} />
        }>
          {submissionSuccessful === true && <TemporaryAlert severity="success" title={submissionMessage || "Incident submitted successfully!"} />}
          {submissionSuccessful === false && <TemporaryAlert severity="error" title={submissionMessage || `Oops, something went wrong! Your submission was not saved. ${JSON.stringify(submissionError)}`} />}
          {roleCtx.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid style={{ maxWidth: "150px" }} item xs={12}>
              <img style={{ width: "100%" }} alt="NAOU Incident Management" src={incidentMgmtImage} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={addRecord} variant="contained" color="primary">Log New Incident</Button>
            </Grid>
          </Grid>}
          {!roleCtx.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && <ListIMLogs
            showFilters={showFilters}
            showAddRecord={showAddButton}
            startLoading={startLoading}
            stopLoading={stopLoading}
            hideAddRecord={hideAddButton}
            mfrProfileId={mfrProfileId}
            setMfrProfileId={setMfrProfileId}
          />}
        </Page>
      }
    </RoleContext.Consumer>
  )
}

import { useEffect, useState } from 'react'
import Page from '../layout/Page'
import { useRole } from './role'
import { getHostUrl } from './auth'

export default function LogoutPage (props={}) {
  const [isLoading, setIsLoading] = useState(true)
  const { cleanup } = useRole()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    cleanup()
    setTimeout(() => {
      window.location = getHostUrl()
    }, 100);
  })

  return (
    <Page { ...props} isLoading={isLoading} onLoad>
        Goodbye!
    </Page>
  )

}
import A from './json/A_QualityAndFoodSafetySystems.json'
import B from './json/B_CleaningAndSanitationProgram.json'
import C from './json/C_WaterTreatment.json'
import D from './json/D_IncomingMaterialsAndBlending.json'
import E from './json/E_ProcessingAndFilling.json'
import F from './json/F_GoodManufacturingPractices.json'
import G from './json/G_GoodLaboratoryPractices.json'
import H from './json/H_Security.json'
import I from './json/I_PreventiveAndPredicticeMaintenance.json'
import J from './json/J_WarehouseStorageAndDistribution.json'
import K from './json/K_ChangeControlManagementProcess.json'
import L from './json/L_SupplierGuidingPrinciples.json'
import M from './json/M_AssessorAccess.json'

const models = [].concat(A,B,C,D,E,F,G,H,I,J,K,L,M)

export default models
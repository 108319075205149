export const removeAllLocalStorageItemsStartingWith = (prefix) => {
  for (const key in localStorage) {
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key)
    }
  }
}
export const getCountOfLocalstorageItemsStartingWith = (prefix) => {
  let count = 0
  for (const key in localStorage) {
    if (key.startsWith(prefix)) {
      count++
    }
  }
  return count
}
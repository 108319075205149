import Grid from '@mui/material/Unstable_Grid2'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ASSESSMENT_TYPES, ASSESSMENT_TYPE_LABELS, ITEM_PROPS, RATINGS, RATING_LABELS, applyPoints } from '../../form/formUtils'
import { getRequestConfig } from '../../auth/auth'
import { ApiGateway } from '../../config/config'
import axios from 'axios'
import { handleFieldChange, handleSelectChange } from '../../form/Form'
import FileListControl from '../../form/FileListControl'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role.js'
import { ASMT_STATUS } from '../../utils/constants'
import { default as bus } from '../../utils/EventBus'
import Event from '../../utils/EventNames.js'
import { Box } from '@mui/system'
import PropTypes from 'prop-types';

function TabPanel (props) {
    const { children, selectedTab, thisTab, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={selectedTab !== thisTab}
        id={`tabpanel-${thisTab}`}
        aria-labelledby={`oe-tab-${thisTab}`}
        {...other}
      >
        {selectedTab === thisTab && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
children: PropTypes.node,
thisTab: PropTypes.number.isRequired,
selectedTab: PropTypes.number.isRequired,
};

export default function AssessmentItem(props = {}) {
    const roleDetails = useContext(RoleContext)
    const { refreshItemList, defaultAssessmentType } = props
    const calculatePointsClass = (value) => {
        return ('' + value).toLowerCase().replace('_', '-')
    }
    const defaultItem = useMemo(() => {
        return {
        [ITEM_PROPS.findingRating]: '',
        [ITEM_PROPS.earnedPoints]: undefined,
        [ITEM_PROPS.assessmentType]: defaultAssessmentType ?? '',
        [ITEM_PROPS.isRepeat]: false,
        [ITEM_PROPS.areaFinding]: '',
        [ITEM_PROPS.findingObservations]: '',
        [ITEM_PROPS.objectiveEvidence]: '',
        [ITEM_PROPS.objectiveEvidenceQse]: ''
    }}, [defaultAssessmentType])

    const [item, setItem] = useState()
    const [itemId, setItemId] = useState(props.id)
    const [assessmentId, setAssessmentId] = useState(props.assessment.id)

    const [itemFiles, setItemFiles] = useState([])
    const [lastItemState, setLastItemState] = useState()
    const [earnedPoints, setEarnedPoints] = useState()
    const [included, setIncluded] = useState(true)
    const [pointsClass, setPointsClass] = useState()
    const [selectedOETab, setSelectedOETab] = useState(0)

    

    const initItem = (it) => {
        if (it && it[ITEM_PROPS.findingRating] && it[ITEM_PROPS.findingRating] !== '') {
            const ep = applyPoints[it[ITEM_PROPS.findingRating]](props)
            setEarnedPoints(ep)
            setIncluded(ep !== undefined)
            setPointsClass(calculatePointsClass(it[ITEM_PROPS.findingRating]))
        }
    }
    const oeTabChange = (event, tab) => {
        setSelectedOETab(tab)
    }

    const onChangeFindingRating = (event) => {
        // console.log('item.onChangeFindingRating: ', event)        
        const { target } = event
        const findingRating = target.value
        const points = applyPoints[findingRating](props)
        // console.log(points)
        const included = points !== undefined
        const _pointsClass = calculatePointsClass(findingRating)

        const _item = {
            ...item,
            [ITEM_PROPS.findingRating]: findingRating,
            [ITEM_PROPS.earnedPoints]: points
        }
        hardSetItem(_item)
        setIncluded(included)
        setEarnedPoints(points >= 0 ? points : '--')
        setPointsClass(_pointsClass)

        if (props.onChangePoints instanceof Function) {
            let event = {
                target: {
                    id: `${props.id}`,
                    value: {
                        earnedPoints: points,
                        maxPoints: props.maxPoints,
                        included: included
                    }
                }
            }
            props.onChangePoints(event)
        }
    }

    const hardSetItem = (_item) => {
        const changes = Object.keys(lastItemState).some((k) => {
            return lastItemState[k] !== _item[k]
        })
        const filterChanges = Object.entries(_item).filter(([k,v]) => {
            if (k === 'assessmentType' && defaultAssessmentType !== '') {
                return true
            }
            return v !== lastItemState[k]
        })

        setItem(_item)
        if (changes) {
            setLastItemState(_item)
            saveItem(_item, filterChanges)
        }
    }

    const getItem = async () => {
        const basePath = `${ApiGateway.assessments}/${props.assessment.id}/item/${props.id}`
        const requestConfig = await getRequestConfig()
        const responses = await Promise.all([
            axios.get(basePath, requestConfig),
            loadItemFiles(requestConfig)
        ])
        const _item = responses[0].data.item ?? defaultItem
        setItem(_item)
        initItem(_item)
        setLastItemState(_item)
    }

    const loadItemFiles = async (requestConfig) => {
        if (!requestConfig) {
            requestConfig = await getRequestConfig()
        }
        const basePath = `${ApiGateway.assessments}/${props.assessment.id}/item/${props.id}`
        const response = await axios.get(`${basePath}/files`, requestConfig)
        setItemFiles(response.data ?? [])
    }

    const saveItem = async (_item, changes) => {
        bus.emit(Event.ALERT, { "text": 'Saving...' })
        const basePath = `${ApiGateway.assessments}/${props.assessment.id}/item/${props.id}`
        const requestConfig = await getRequestConfig()
        const payload = (changes && changes.length) ? Object.fromEntries(changes) : _item
        const response = await axios.post(basePath, payload, requestConfig)
        bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": 1000 })
        refreshItemList()
    }

    const isEditableforRoles = (roles) => {
        return isAssessmentEditable() && roleDetails.hasRole([...roles])
    }

    const isAssessmentEditable = () => {
        const isEditable = (
            roleDetails.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER])
            && [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].includes(props.assessment.status)
        ) || (
                roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])
                && [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE, ASMT_STATUS.COMPLETED].includes(props.assessment.status)
            )
        // console.log(`isAssessmentEditable: `, isEditable)
        return isEditable
    }
    const isQSEViewable = () => {
        return roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])
    }

    const isBottlerViewable = () => {
        return roleDetails.hasRole([ROLES.BOTTLER_CORP, ROLES.BOTTLER]) && [ASMT_STATUS.COMPLETED, ASMT_STATUS.FINALIZED].includes(props.assessment.status)
    }

    useEffect(() => {
        getItem()
    }, [])

    return (
        <Grid key={props.id} id={props.id} container rowSpacing={3} columnSpacing={12} className={`tis-quality-and-fs-item `}>
            {!item &&
                <Grid item xs={12}>
                    <Typography variant='h5' align='center'>Loading...</Typography>
                </Grid>
            }
            {item &&
                <>
                    <Grid item xs={1.5} className="assessment-item-score-box">
                        <div className="tis-quality-and-fs-item-id">
                            {props.id}
                            {included && <div className="points-label">Score:</div>}
                            {included && <div className={`points ${pointsClass}`}>
                                <div className="earned-points">{earnedPoints === undefined ? '--' : earnedPoints}</div><div className="max-points">{props.maxPoints}</div>
                            </div>}
                        </div>
                    </Grid>
                    <Grid xs={10.5} md={10.5}>
                        <div className="title">{props.title}</div>
                        <span className="tis-quality-and-fs-questions" >{props.children}</span>
                    </Grid>
                    <Grid container xs={12} md={12}>
                        {(isBottlerViewable() || (isQSEViewable() && !isAssessmentEditable())) &&
                        <>
                        <Grid item xs={12}>
                            <Stack direction={'row'} spacing={2}>
                                <Typography><strong>Finding Rating:</strong> {RATING_LABELS[item[ITEM_PROPS.findingRating]]}</Typography>
                                <Typography><strong>Repeat:</strong> {item[ITEM_PROPS.isRepeat] ? 'Yes' : 'No'}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Typography><strong>Area Finding: Observed/Location:</strong></Typography>
                                <Typography sx={{whiteSpace: 'pre-wrap'}}>{item[ITEM_PROPS.areaFinding]}</Typography>
                                <Typography><strong>Finding: Gaps/Observations:</strong></Typography>
                                <Typography sx={{whiteSpace: 'pre-wrap'}}>{item[ITEM_PROPS.findingObservations]}</Typography>
                                <Typography><strong>Objective Evidence:</strong></Typography>
                                <Typography sx={{whiteSpace: 'pre-wrap'}}>{item[ITEM_PROPS.objectiveEvidence]}</Typography>
                                <Typography><strong>QSE Objective Evidence:</strong></Typography>
                                <Typography sx={{whiteSpace: 'pre-wrap'}}>{item[ITEM_PROPS.objectiveEvidenceQse]}</Typography>
                            </Stack>
                        </Grid>
                        </>
                        }
                        {(roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && isAssessmentEditable()) && (
                            <>
                                <Grid item xs={3} sm={3}>
                                    <Stack spacing={2}>
                                        <FormControl size='small'>
                                            <InputLabel>Finding Rating</InputLabel>
                                            <Select size='small' fullWidth id={`${props.id}_findingRating`}
                                                value={item[ITEM_PROPS.findingRating] || ''}
                                                disabled={!isAssessmentEditable() || isBottlerViewable()}
                                                onChange={onChangeFindingRating}>
                                                {Object.keys(RATINGS).map((rating, idx) => {
                                                    return <MenuItem key={idx} value={RATINGS[rating]}>{RATING_LABELS[rating]}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                        <FormControl size='small'>
                                            <InputLabel>Assessment Type</InputLabel>
                                            <Select size='small' fullWidth id={`${props.id}_assessmentType`}
                                                value={item[ITEM_PROPS.assessmentType] || ''}
                                                disabled={!isAssessmentEditable() || isBottlerViewable()}
                                                onChange={(event) => handleSelectChange(ITEM_PROPS.assessmentType, event, item, hardSetItem)}>
                                                {Object.keys(ASSESSMENT_TYPES).map((aType, idx) => {
                                                    return <MenuItem key={idx} value={ASSESSMENT_TYPES[aType]}>{ASSESSMENT_TYPE_LABELS[aType]}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                        <FormControl size='small'>
                                            <InputLabel>Repeat (Yes/No)</InputLabel>
                                            <Select size='small' fullWidth id={`${props.id}_isRepeat`}
                                                value={`${item[ITEM_PROPS.isRepeat]}`}
                                                disabled={!isAssessmentEditable() || isBottlerViewable()}
                                                onChange={(event) => handleSelectChange(ITEM_PROPS.isRepeat, event, item, hardSetItem)}>
                                                <MenuItem value={1}>Yes</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Stack>
                                </Grid>
                                <Grid item xs={4.5} sm={4.5}>
                                    <TextField label="Area Finding: Observed/Location" fullWidth
                                        inputProps={{ maxLength: 500 }}
                                        variant='outlined'
                                        id={`${props.id}_areaFinding`}
                                        value={item[ITEM_PROPS.areaFinding]}
                                        helperText={`${(item[ITEM_PROPS.areaFinding] || '').length}/500`}
                                        disabled={!isAssessmentEditable() || isBottlerViewable()}
                                        multiline rows={5}
                                        onChange={(event) => handleFieldChange(ITEM_PROPS.areaFinding, event.target.value, item, setItem)}
                                        onBlur={(event) => handleFieldChange(ITEM_PROPS.areaFinding, event.target.value, item, hardSetItem)}
                                    />
                                </Grid>
                                <Grid item xs={4.5} sm={4.5}>
                                    <TextField label="Finding: Gaps/Observations" fullWidth
                                        inputProps={{ maxLength: 500 }}
                                        variant='outlined'
                                        id={`${props.id}_findingObservations`}
                                        value={item[ITEM_PROPS.findingObservations]}
                                        helperText={`${(item[ITEM_PROPS.findingObservations] || '').length}/500`}
                                        disabled={!isAssessmentEditable() || isBottlerViewable()}
                                        multiline rows={5}
                                        onChange={(event) => handleFieldChange(ITEM_PROPS.findingObservations, event.target.value, item, setItem)}
                                        onBlur={(event) => handleFieldChange(ITEM_PROPS.findingObservations, event.target.value, item, hardSetItem)}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={(isBottlerViewable() || (isQSEViewable() && !isAssessmentEditable())) ? 12 : 6}>                        
                        
                        { isEditableforRoles([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && (
                            <>
                            <Typography fontWeight='bold'>Objective Evidence</Typography>
                            <TextField placeholder="Objective Evidence"
                                inputProps={{ maxLength: 1000 }}
                                fullWidth
                                variant='outlined'
                                id={`${props.id}_objectiveEvidence`}
                                value={item[ITEM_PROPS.objectiveEvidence]}
                                helperText={`${(item[ITEM_PROPS.objectiveEvidence] || '').length}/1000`}
                                disabled={!isAssessmentEditable()}
                                multiline rows={6}
                                onChange={(event) => handleFieldChange(ITEM_PROPS.objectiveEvidence, event.target.value, item, setItem)}
                                onBlur={(event) => handleFieldChange(ITEM_PROPS.objectiveEvidence, event.target.value, item, hardSetItem)}
                            />
                            </>
                        )}
                        { isEditableforRoles([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && (
                            <>
                            <Typography fontWeight='bold'>Objective Evidence</Typography>
                            <Tabs value={selectedOETab} onChange={oeTabChange} >
                                <Tab id={`oe-tab-0`} label='QSE Evidence' sx={{ textTransform: 'none'}}></Tab>
                                <Tab id={`oe-tab-1`} label={`Facility Evidence`}  sx={{ textTransform: 'none'}}></Tab>
                          </Tabs>
                          <TabPanel selectedTab={selectedOETab} thisTab={0}>
                            <TextField 
                                inputProps={{ maxLength: 1000 }}
                                placeholder='Objective Evidence'
                                fullWidth
                                variant='outlined'
                                id={`${props.id}_objectiveEvidenceQse`}
                                value={item[ITEM_PROPS.objectiveEvidenceQse]}
                                helperText={`${(item[ITEM_PROPS.objectiveEvidenceQse] || '').length}/1000`}
                                disabled={!isAssessmentEditable()}
                                multiline rows={6}
                                onChange={(event) => handleFieldChange(ITEM_PROPS.objectiveEvidenceQse, event.target.value, item, setItem)}
                                onBlur={(event) => handleFieldChange(ITEM_PROPS.objectiveEvidenceQse, event.target.value, item, hardSetItem)}
                            />
                          </TabPanel>
                          <TabPanel selectedTab={selectedOETab} thisTab={1}>
                            { item[ITEM_PROPS.objectiveEvidence] && item[ITEM_PROPS.objectiveEvidence] !== '' &&
                            <Typography 
                                sx={{whiteSpace: 'pre-wrap', border: '1px dashed silver', padding: '3px', borderRadius: '5px', minHeight: '25px'}}
                            >{item[ITEM_PROPS.objectiveEvidence]}</Typography>
                            }
                            { (!item[ITEM_PROPS.objectiveEvidence] || item[ITEM_PROPS.objectiveEvidence] === '') &&
                                <Typography 
                                sx={{border: '1px dashed silver', padding: '3px', borderRadius: '5px', fontStyle: 'italic', minHeight: '50px'}}
                            >No Objective Evidence Found</Typography>
                            }
                          </TabPanel>
                        </>
                        )}
                        </Grid>
                        <Grid xs={12} md={(isBottlerViewable() || (isQSEViewable() && !isAssessmentEditable())) ? 12 : 6}>
                            <Stack spacing={1}>
                                <Typography><strong>Supporting Files</strong></Typography>
                                <FileListControl
                                    rowData={itemFiles}
                                    showLastModified={true}
                                    showActions={roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])}
                                    enableUpload={isAssessmentEditable()}
                                    enableDownload={true}
                                    getFileUrlServiceAddr={`${ApiGateway.assessments}/${assessmentId}/item/${itemId}/files/`}
                                    getPutFileUrlServiceAddr={`${ApiGateway.assessments}/${assessmentId}/item/${itemId}/new-file-url`}
                                    onFileUploaded={() => loadItemFiles()}
                                    removeFileUrl={`${ApiGateway.assessments}/${assessmentId}/item/${itemId}/files`}
                                    onFileRemoved={() => loadItemFiles()}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <div className='separator'>&nbsp;</div>
                </>
            }
        </Grid>
    )
}
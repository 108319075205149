import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import TextField from '@mui/material/TextField';

let propsOnChange = () => { }
let propsId = ''

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value, el) => {
        propsOnChange({ target: { id: propsId, value: el.unmaskedValue } })
        onChange({ target: { name: props.name, value } })
      }}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PhoneInput(props = {}) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value)
    }
  }, [props.value])

  propsOnChange = props.onChange
  propsId = props.id
  const handleChange = (event) => {
    setValue(event.target.value)
  };

  return (
    <TextField
      label={props.label}
      value={value}
      size={props.size || 'small'}
      onChange={handleChange}
      id={props.id}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
    />
  );
}
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils'
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table'
import { Box, Grid, Paper, IconButton, Button} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import { TroubleshootOutlined, SubdirectoryArrowRight } from '@mui/icons-material';

export default function LisComplaintTrends(props = {searchFilter: undefined, rowsPerPage: 100, pageIndex: 0 }) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  
  const roleDetails = useContext(RoleContext)

  const listColumns = useMemo(() => [
    {
      accessorKey: 'trendNumber',
      header: 'Trend',
      size: 110,
      editable: false,
      filterVariant: 'text',
      grow: false,
      Cell: ({row}) => {
        const complaintTrendId = row.original.id
        return (
          <Link to={ `/complaint-trends/${complaintTrendId}` }>
            {row.original.trendNumber} - {row.original.plantCode}
          </Link>
        )
      }
    },
    {
      accessorKey: 'trendDate',
      header: 'Date',
      editable: false,
      filterVariant: 'date',
      sortingFn: 'datetime',
      size: 90,
      grow: false,
      Cell: ({ cell, row }) => {
        const d = row.original.trendDate
        if (d && d !== null) {
          return `${new Date(d).toLocaleDateString('en-US', {timeZone: 'UTC'})}`
        }
      }
    },
    {
      accessorKey: 'trendIssue',
      header: 'Trend Issue(s)',
      size: 120,
      grow: true,
      editable: false,
      filterVariant: 'text',
      Cell: ({ cell, row }) => {
        return `${(cell.getValue() ?? '').split('~').join(', ')}`
      }
    },
    {
      accessorKey: 'Manufacturer.manufacturerName',
      header: 'Facility',
      grow: true,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'Manufacturer.Ownership.ownershipName',
      header: 'Ownership',
      size: 140,
      grow: true,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'Manager.firstName',
      header: 'Manager',
      editable: false,
      grow: true,
      maxSize: 150,
      filterVariant: 'text',
      Cell: ({row}) => (
          <span style={{textTransform: 'capitalize'}}>{([row.original.Manager?.firstName ?? '', row.original.Manager?.lastName ?? ''].join(' ')).trim().toLowerCase()}</span>
          )
    }
  ])

  const getComplaintTrendsList = async() => {
    props.startLoading()

    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.complaintTrends}/search`
    const search = props.searchFilter ?? {}
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setDataRows(results)
    }
      
    props.stopLoading()
  }

  

  useEffect(() => {
    if (isMounted()) {
      getComplaintTrendsList()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 100
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });


  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>            
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}
export function serializeFilterToQueryString(filter = { items: [] }) {
  let queryString = [];

  const logicOperator = filter.logicOperator || filter.linkOperator || 'and';

  // Serialize items
  filter.items.forEach(item => {
    const value = item.value;
    
    const field = item.field || item.columnField;
    const operator = item.operator || item.operatorValue || 'equals';
    if((value === undefined || value === null || value === '') && operator !== 'isEmpty' && operator !== 'isNotEmpty') return;

    if (field) {
      queryString.push(`filter-field=${encodeURIComponent(field)}`);
    }
    if (operator) {
      queryString.push(`filter-op=${encodeURIComponent(operator)}`);
    }
    if (item.value) {
      queryString.push(`filter-value=${encodeURIComponent(Array.isArray(item.value) ? item.value.join(',') : item.value)}`);
    } else {
      queryString.push(`filter-value=${encodeURIComponent('')}`);
    }
  });

  // Serialize logicOperator
  if (logicOperator && filter?.items?.length > 0) {
    queryString.push(`filter-logic-op=${encodeURIComponent(logicOperator)}`);
  }

  if (queryString.length) return `&${queryString.join('&')}`
  return ''
}

export function serializeSortToQueryString(sortRules = []) {
  let queryString = [];

  sortRules.forEach(rule => {
    if (rule.field && rule.sort) {
      queryString.push(`sort-field=${encodeURIComponent(rule.field)}`);
      queryString.push(`sort-direction=${encodeURIComponent(rule.sort)}`);
    }
  });

  if (queryString.length) return `&${queryString.join('&')}`
  return ''
}
import { useState } from 'react'
import Page from '../layout/Page'
import { useNavigate } from 'react-router-dom'
import { Approval } from '@mui/icons-material'
import ListViewCommandBar from '../commandBar/ListViewCommandBar.js'
import ListCAPs from './ListCAPs.js'

export default function MyCAPsPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const breadcrumb = <span><Approval />&nbsp;My Corrective Action Plans</span>

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
      commandBar={<ListViewCommandBar 
        showFilters={true}
        toggleFilters={toggleFilters}
        showAdd={false}
      />}
    >
      
    <div className='App'>
      <ListCAPs setIsLoading={setIsLoading} showFilters={showFilters} />
    </div>
    </Page>
  )
}
import { useMemo, useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import HelpDialog from '../../HelpDialog'
const pdfFile = require('./ComplaintTrendsOverview.pdf')


export default function ComplaintTrendsHelp() {
  const file = useMemo(() => pdfFile, [pdfFile])
  

  return (
    <>
    <HelpDialog linkText='Help' startIcon={<HelpIcon />} fullScreen={true}>
        <iframe src={file} width={'100%'} style={{ aspectRatio: '100%', minHeight: '80vh'}}></iframe>
    </HelpDialog>
    </>
  )
}
export const ASSESSMENT_TYPES = {
  virtual: 'virtual',
  onsite: 'onsite',
  combination: 'combination',
  technical: 'technical'
}
export const ASSESSMENT_TYPE_LABELS = {
  [ASSESSMENT_TYPES.virtual]: 'Virtual Assessment',
  [ASSESSMENT_TYPES.onsite]: 'Onsite Assessment',
  [ASSESSMENT_TYPES.combination]: 'Combination: Virtual/Onsite',
  [ASSESSMENT_TYPES.technical]: 'Technical Visit'
}

export const RATINGS = {
  na: 'na',
  no_finding: 'no_finding',
  improvement_opportunity: 'improvement_opportunity',
  non_conformance: 'non_conformance',
  conditional_non_conformance: 'conditional_non_conformance',
  high_risk_non_conformance: 'high_risk_non_conformance',
  not_assessed: 'not_assessed'
}

export const RATING_LABELS = {
  [RATINGS.na]: 'N/A',
  [RATINGS.no_finding]: 'No Finding',
  [RATINGS.improvement_opportunity]: 'Improvement Opportunity',
  [RATINGS.non_conformance]: 'Non-Conformance',
  [RATINGS.conditional_non_conformance]: 'Conditional Non-Conformance',
  [RATINGS.high_risk_non_conformance]: 'High Risk Non-Conformance',
  [RATINGS.not_assessed]: 'Not Assessed'
}

export const ITEM_PROPS = {
  id: 'id',
  earnedPoints: 'earnedPoints',
  pointsClass: 'pointsClass',
  findingRating: 'findingRating',
  assessmentType: 'assessmentType',
  isRepeat: 'isRepeat',
  areaFinding: 'areaFinding',
  findingObservations: 'findingObservations',
  objectiveEvidence: 'objectiveEvidence',
  objectiveEvidenceQse: 'objectiveEvidenceQse'
}

export const CAP_STATUSES = {
  not_started: 'not_started',
  in_process: 'in_process',
  completed: 'completed',
  review: 'review',
  approved: 'approved',
  finalized: 'finalized'
}

export const CAP_STATUS_LABELS = {
  [CAP_STATUSES.not_started]: 'Not Started',
  [CAP_STATUSES.review]: 'Review',
  [CAP_STATUSES.approved]: 'Approved',
  [CAP_STATUSES.in_process]: 'In Process',
  [CAP_STATUSES.completed]: 'Completed',
  [CAP_STATUSES.finalized]: 'Finalized'
}

export const applyPoints = {
  [RATINGS.na]: () => { return undefined },
  [RATINGS.no_finding]: (props) => {  return props.maxPoints },
  [RATINGS.improvement_opportunity]: (props) => { return parseFloat(props.maxPoints * .9).toFixed(1) },
  [RATINGS.non_conformance]: (props) => { return parseFloat(props.maxPoints * .5).toFixed(1) },
  [RATINGS.conditional_non_conformance]: (props) => { return parseFloat(props.maxPoints * .25).toFixed(1) },
  [RATINGS.high_risk_non_conformance]: () => { return 0 },
  [RATINGS.not_assessed]: () => { return undefined }
}
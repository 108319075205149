import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function StatusTransitionDialog(props={
    open: false,
    title: '',
    text: '',
    nextStatus: '',
    handleConfirm: undefined,
    hideDialog: undefined,
    noButtonVariant: undefined, 
    noButtonStyle: {}, 
    noLabel: 'No', 
    yesLabel: 'Yes', 
    yesButtonVariant: undefined, 
    yesButtonStyle: {},
    isWorking: false,
    status: undefined,
    configurations: [
      { id: '', label: '', value: undefined, type: 'text|date', required: false, maxLength: -1}
    ]
  }) {
    const [values, setValues] = useState(props.configurations && props.configurations.length ? props.configurations.reduce((p,c) => {p[c.id]=c.value; return p},{}) : {})
    const [isWorking, setIsWorking] = useState(props.isWorking)
    const isValid = () => {
      const hasConfigs = props.configurations && Array.isArray(props.configurations) && props.configurations.length
      const hasInvalid = hasConfigs && props.configurations.some((config) => {
        if (config.required) {
          if ( !values ) {
            // console.log('Values are not found')
            return true
          }
          if ( !Object.keys(values).includes(config.id) || !values[config.id] ) { 
            // console.log(`Value not found for ${config.id}`)
            return true 
          }
          if ( ['text','textarea'].includes(config.type) && values[config.id].toString().length === 0) {
            // console.log(`Empty value for required field ${config.id}`)
            return true
          }
          if ( config.type === 'date' ) {
            const d = values[config.id]
            if (typeof d === 'string' && d.length === 0) {
              // console.log(`date config type but empty string value for ${config.id}`)
              return true
            }
            if (d instanceof Date && isNaN(d)) {
              // console.log(`Invalid date ${config.id} = ${d}`)
              return true
            }
          }
        }
        return false
      })
      return !hasInvalid
    }

    const getEditComponent = (config) => {
      // config: { type: 'text' | 'date', id: string, label: string, required: boolean, maxLength?: number}

      switch (config.type) {
        case 'text':
          return <TextField 
            value={values && values[config.id] ? values[config.id] : ''}
            label={config.label}
            required={config.required} 
            onChange={(e) => setValues({ ...values, [config.id]: e.target.value })} 
            helperText={config.required ? 'This field is required' : undefined}
            size='small'
            inputProps={{maxLength: config.maxLength ?? -1}}
            />
          case 'textarea':
            const helperText = config.maxLength ? `${(values[config.id] ?? '').length ?? 0}/${config.maxLength}` : undefined
            return <TextField 
              multiline={true}
              rows={10}
              value={values && values[config.id] ? values[config.id] : ''}
              label={config.label}
              required={config.required} 
              onChange={(e) => setValues({ ...values, [config.id]: e.target.value })} 
              helperText={config.required ? `${helperText ?? ''} This field is required` : helperText}
              size='small'
              inputProps={{maxLength: config.maxLength ?? -1}}
              />
        case 'date':
          return <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MM/DD/YYYY" id={config.id}
                    label={config.label}
                    value={values && values[config.id] ? dayjs(values[config.id]) : undefined}
                    slotProps={{                      
                      textField: {
                        required: config.required,
                        fullWidth: true,
                        size: 'small',
                        helperText: config.required ? 'This field is required' : undefined,
                        onBlur: (event) => {
                        }
                      },
                    }}
                    onChange={(value, context) => {
                      if (context.validationError == null) {
                        setValues({ ...values, [config.id]: value})
                      }
                    }}
                    />
                  </LocalizationProvider>
        default:
      }

      return
    }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.hideDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
        <Stack spacing={2}>
          {props.status && <DialogContentText>Current status: {props.status}</DialogContentText>}
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
          
          {props.configurations?.length && props.configurations.map((config) => getEditComponent(config))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button 
            variant={props.noButtonVariant || "outlined"} 
            style={props.noButtonStyle} 
            onClick={() => {
              setValues()
              props.hideDialog()
            }}>{props.noLabel}</Button>
          <LoadingButton 
            loading={isWorking}
            variant={ !isValid() || isWorking ? 'text' : (props.yesButtonVariant || 'contained')} 
            style={ !isValid() || isWorking ? {} : props.yesButtonStyle } 
            onClick={() => {
              (async () => {
                setIsWorking(true)              
                try {
                  await props.handleConfirm(values)
                  props.hideDialog()
                } finally {
                  setIsWorking(false)
                }
              })()
            }}
            disabled={ !isValid() }>{props.yesLabel}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
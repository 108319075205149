import { useState } from 'react'
import { Box, Slider } from '@mui/material'
const riskMatrixImg = require('../img/RiskMatrix.png')


export default function RiskMatrix(props={zoomRatio: '100%'}) {
  const [ratio, setRatio] = useState('100%')

  return (
    <>
      <Box>
        <img src={riskMatrixImg} style={{ maxWidth: props.zoomRatio }} alt="Not Available" />
      </Box>
    </>
  )
}
import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { getRequestConfig } from "../auth/auth";
import axios from "axios";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import UploadIcon from '@mui/icons-material/UploadFile'


export default function FileListControl(props={
  rowData: [], 
  showLastModified: false, 
  showActions: false, 
  enableUpload: false,
  enableDownload: false, 
  listFilesUrl: undefined,
  getFileUrlServiceAddr: undefined, 
  getFileUrlServiceQueryParams: undefined,
  getPutFileUrlServiceAddr: undefined,
  getPutFileUrlServiceQueryParams: undefined,
  removeFileUrl: undefined,
  removeFileUrlQueryParams: undefined,
  onFileUploaded: undefined,
  onFileRemoved: undefined,
  altFileNameColumnLabel: undefined
  }) {
  
  const [rowData, setRowData] = useState(props.rowData || [])
  const [targetFile, setTargetFile] = useState()
  const [targetFileName, setTargetFileName] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const [clickTarget, setClickTarget] = useState()
  const [isGetting, setIsGetting] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  const selectFile = ((event) => {
    const _targetFile = event.target.files[0]
    if(_targetFile) {
      setTargetFile(_targetFile)
      setTargetFileName(_targetFile.name)
    }
  })

  const clearFile = () => {
    setTargetFile()
    setTargetFileName('')
  }

  const listFiles = async () => {
    setErrorMessage('')
    if (props.listFilesUrl) {
      const requestConfig = await getRequestConfig()
      const basePath = props.listFilesUrl
      try {
        const response = await axios.get(`${basePath}`, requestConfig)
        setRowData(response.data ?? [])
      } catch (error) {
        setErrorMessage(error.response?.data ?? error.message ?? error)
      }
    } else {
      setRowData(props.rowData || [])
    }
  }

  const onRequestUploadFile = async () => {
    setErrorMessage('')
    setIsUploading(true)

    const requestConfig = await getRequestConfig()
    const fileName = targetFile.name

    const serviceUrl = [      
      props.getPutFileUrlServiceAddr,
      `?fileName=${fileName}`
    ]
    if (props.getPutFileUrlServiceQueryParams) {
      serviceUrl.push(`&${props.getPutFileUrlServiceQueryParams}`)
    }
    try {
      const response = await axios.get(serviceUrl.join(''), requestConfig)
      if (response.data) {
        const targetUrl = response.data
        await axios.put(targetUrl, targetFile)
        clearFile()
        await listFiles()
        if (props.onFileUploaded) {
          if (props.onFileUploaded.constructor.name === 'AsyncFunction') 
            await props.onFileUploaded()
          else
            props.onFileUploaded()
        }
      }
    } catch (error) {
      setErrorMessage(error.response?.data ?? error.message ?? error)
    } finally {
      setIsUploading(false)
      clearFile()
    }
  }
  const downloadFile = async (fileName) => {
    setErrorMessage('')
    setClickTarget(fileName)
    setIsGetting(true)

    const requestConfig = await getRequestConfig()

    const serviceUrl = [      
      props.getFileUrlServiceAddr,
      fileName
    ]
    if (props.getFileUrlServiceQueryParams) {
      serviceUrl.push(`?${props.getFileUrlServiceQueryParams}`)
    }

    try {
    const response = await axios.get(serviceUrl.join(''), requestConfig)
      if (response.data) {
        const fileUrl = response.data
        const tempLink = document.createElement('a')
        tempLink.href = fileUrl
        tempLink.download = fileName
        tempLink.target = '_blank'
        tempLink.click()
      }
    } catch (error) {
      setErrorMessage(error.response?.data ?? error.message ?? error)
    } finally {
      setIsGetting(false)
      setClickTarget()
    }
  }

  const removeFile = async (fileName) => {
    setErrorMessage('')
    setClickTarget(fileName)
    setIsRemoving(true)
    const requestConfig = await getRequestConfig()

    const serviceUrl = [      
      props.removeFileUrl,
      `?fileName=${fileName}`
    ]
    if (props.removeFileUrlQueryParams) {
      serviceUrl.push(`&${props.removeFileUrlQueryParams}`)
    }

    try {
      await axios.delete(serviceUrl.join(''), requestConfig)
      await listFiles()
      if (props.onFileRemoved) props.onFileRemoved()
    } catch (error) {
      setErrorMessage(error.response?.data ?? error.message ?? error)
    }

    setTimeout(() => {
      setClickTarget()
      setIsRemoving(false)
    }, 3000)
  }

  useEffect(() => {
    listFiles()
  }, [props.rowData, props.listFilesUrl])

  return (
    <Stack rowGap={1}>
      {props.enableUpload && 
      <Stack direction='row' spacing={2}>        
        <TextField 
                    // className='tis-form-field'
                      //variant="standard"          
          type="text"
          size='small'
          placeholder="Select a File"
          disabled
          value={targetFileName}
          InputProps={{
            endAdornment: (
              <IconButton component="label" size="small">
                <UploadIcon />
                <input 
                  styles={{display:"none"}} 
                  hidden 
                  type="file" 
                  onChange={selectFile}
                  size='small'
                  />                
              </IconButton>
            ),
          }}
        />
        
        {targetFile && <LoadingButton variant='outlined' loading={isUploading} onClick={onRequestUploadFile}>Upload File</LoadingButton>}
      </Stack>
      }
      {(props.showFileList === undefined || props.showFileList === true) && <>
        { !!(errorMessage.length) && 
          <Box item sm={12} md={12} paddingBottom={3}>
            <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '5px'}} >{errorMessage}</Typography>
          </Box>
        }
        { ( !rowData || !rowData.length) &&
          <Typography>No files available</Typography>
        }
        { !!(rowData.length) && <TableContainer component={Paper} variant="outlined">        
          <Table sx={{width: '100%' }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>File&nbsp;Name</TableCell>
                { props.altFileNameColumnLabel && 
                <TableCell>{ props.altFileNameColumnLabel}</TableCell>}
                { props.showLastModified && 
                <TableCell>Last&nbsp;Modified</TableCell>}
                { props.showActions && 
                <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map(( row ) => (
                  <TableRow
                    key={row.fileName}
                  >
                    <TableCell align="left">
                      { props.enableDownload && props.getFileUrlServiceAddr &&
                        <LoadingButton 
                          loading={isGetting && clickTarget == row.fileName} 
                          variant="text" 
                          onClick={() => downloadFile(row.fileName)}
                          sx={{ textTransform: 'none', textAlign: 'left', padding: 0 }}>
                          {row.fileName}
                        </LoadingButton>
                      }
                      {(!props.enableDownload || !props.getFileUrlServiceAddr) && row.fileName}
                      </TableCell>
                      { props.altFileNameColumnLabel &&
                      <TableCell>
                        { row.altFileName }
                      </TableCell>
                      }
                    { props.showLastModified && 
                      <TableCell>{new Date(row.lastModified).toLocaleString().split(',').join('')}</TableCell>}
                    { props.showActions &&
                      <TableCell>
                        <LoadingButton 
                          loading={isRemoving && clickTarget == row.fileName} 
                          variant='text' 
                          onClick={() => removeFile(row.fileName )}
                          sx={{ textTransform: 'none' }}
                          >Remove</LoadingButton>
                      </TableCell>}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>}
      </>}
    </Stack>
  )
}
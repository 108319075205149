import RoleContext from '../auth/RoleContext'
import { useRole } from "../auth/role"
import Body from "./Body"
import Sidebar from "./Sidebar"
import SidebarMenu from "./SidebarMenu.js"

export default function AuthenicatedLayout(props = {}) {
  const { role, hasRole, hasAnyRole } = useRole()

  return (
    <RoleContext.Provider value={{role, hasRole, hasAnyRole }}>
      {role && <>
        <Sidebar
            menu={SidebarMenu}
          />
        <Body {...props} />
      </>}
    </RoleContext.Provider>
  )
}
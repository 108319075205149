import N from './json/N_EOSHManagement.json'
import O from './json/O_WasteManagement.json'
import P from './json/P_SourceWaterWastewaterQualityAndStormwater.json'
import Q from './json/Q_GuardingAndSecuring.json'
import R from './json/R_ManagingHazardousMaterials.json'
import S from './json/S_FleetAndDistributionSafety.json'
import T from './json/T_OccupationalSafetyAndHealth.json'

const models = [].concat(N,O,P,Q,R,S,T)

export default models
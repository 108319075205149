import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import Question from '../form/Question'
import Guidance from '../form/Guidance'
import models from './models/EnvironmentalAndSafetyModels'
import AssessmentCategoryForm from './assessment-components/AssessmentCategoryForm'

export default (props = {}) => {
  const [activeCategoriesList, setActiveCategoriesList] = useState()
  const { refreshItemList, subscribeToProgress, defaultAssessmentType } = props
  
  let scores = {}

  const onChangePoints = async (event) => {
    let { target } = event
    let id = target.id
    let { value } = target

    scores = {...scores, [[id]]: (value.earnedPoints / value.maxPoints) * 100 }
    // console.log('newScores: ', newScores)
  }  

  const componentMap = {
    Question: (props) => {
      return <Question {...props}></Question>
    },
    Guidance: (props) => {
      return <Guidance {...props}></Guidance>
    }
  }

  const componentInclude = (list) => {
    return list.map((comp, compidx) => {
      const CompType = componentMap[comp.type]
      return (
        <CompType assessment={props.assessment} {...comp.props} key={comp.props.id}>
          {comp.children && componentInclude(comp.children)}
        </CompType>
        )
    })
  }

  const getActiveCategories = () => {
    if (props.assessment.filter) {
      const assessmentCategories = JSON.parse(props.assessment.filter)
      return models.filter((m) => {
        return assessmentCategories.includes(m.props.id)
      })
    } else {
      return models
    }
  }

  useEffect(() => {
    setActiveCategoriesList(getActiveCategories())
  },[])

  return (
    <Paper sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>    
      <Grid container rowSpacing={2} columnSpacing={{ md: 12 }}>
        <Grid xs={12} md={12}>

        { activeCategoriesList && activeCategoriesList.map(
            (categoryModel, idx) => 
              <AssessmentCategoryForm 
                categoryModel={categoryModel} 
                assessment={props.assessment} 
                key={idx} 
                refreshItemList={refreshItemList}
                progressItemsList={props.progressItemsList}
                subscribeToProgress={subscribeToProgress}
                defaultAssessmentType={defaultAssessmentType} />)}

        </Grid>
      </Grid>
    </Paper>
  )
}
import { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Healing } from '@mui/icons-material'
import qfsModels from '../assessments/models/QualityAndFSModels'
import esModels from '../assessments/models/EnvironmentalAndSafetyModels'
import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import CAPItemListForm from './cap-components/CAPItemListForm'
import { Grid, Paper } from '@mui/material'
import { calculateProgress, loadAssessmentItems } from '../assessments/context/AssessmentContext'


const assessmentModels = [...qfsModels, ...esModels]
let itemsList = []
let itemsProgress = []

export default function CAPPage (props = {}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [isDirty, setIsDirty] = useState(false)

  const [plan, setPlan] = useState()
  const [planModels, setPlanModels] = useState()
  const [assessmentItemList, setAssessmentItemList] = useState()
  const [progressAvailable, setProgressAvailable] = useState(false)

  const QUALITY_FS = 'quality-and-food-safety-assessments'
  const ENV_SAFETY = 'environmental-and-safety-assessments'
  
  const titles = {
      [QUALITY_FS]: "Quality and Food Safety",
      [ENV_SAFETY]: "Environmental and Safety"
  }

  const planId = params.capId
  const assessmentId = params.assessmentId

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleCancel()
  }

  const handleCancel = () => {    
    if (location.key === 'default') {
      navigate(`/corrective-action-plan/my-list`)
    } else {
      navigate(-1)
    }
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }  

  const setItemsList = (_list, _planModels) => {
    setAssessmentItemList(_list)
    const prog = _planModels.reduce((p,m) => {
      const [a,b] = calculateProgress(m, _list)
      p.push(a, ...b)
      return p
    },[])
    itemsProgress = prog
    setProgressAvailable(true)
    publishProgress(prog)
  }
  
  const itemsProgressSubscribers = []
  const subscribeToProgress = (fn) => {
    itemsProgressSubscribers.push(fn)
  }
  const publishProgress = (_itemsProgress) => {
    itemsProgressSubscribers.forEach((fn) => {
      fn(_itemsProgress)
    })
  }

  const initializePage = async () => {
    setIsLoading(true)
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.correctiveAction}/plan/${planId}`

    const responses = await Promise.all([
      axios.get(basePath, requestConfig),
      loadAssessmentItems(assessmentId, requestConfig)
    ])
    const result = responses[0]
    if (result.data) {
      const _plan = result.data
      setPlan(_plan)
      
      const includedCategories = _plan.CorrectiveActionItems.reduce((p, i) => {
        const cat = i.itemId.split('.')[0]
        if ( !p.includes(cat)) {
          p.push(cat)
        }
        return p
      },[])
      const _planModels = assessmentModels.filter((m) => includedCategories.includes(m.props.id))
      setPlanModels(_planModels)      
      setItemsList(responses[1], _planModels)
    }

    setIsLoading(false)
  }

  const getBreadCrumb = () => (<div><span><Healing />&nbsp;Corrective&nbsp;Action&nbsp;Items&nbsp;:&nbsp;{
    plan ? 
    [ plan.ManufacturerProfile?.Manufacturer?.manufacturerId,
      plan.ManufacturerProfile?.Manufacturer?.manufacturerName
    ].join(' - ') : ''
    }</span></div>)
  useEffect(() => {
    initializePage()
  },[])

  return (
    <Page { ...props} breadcrumb={getBreadCrumb()} isLoading={isLoading} commandBar={
      <FormViewCommandBar  onClose={confirmCancel} showSave={false} showCancel={false}/>}>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
      <Grid container rowSpacing={2} >
        <Grid item xs={12} md={12}>
          {plan && assessmentItemList && 
            planModels.map((categoryModel, idx) => 
              <CAPItemListForm
                assessmentId={assessmentId}
                correctiveActionPlan={plan}
                categoryModel={categoryModel}
                assessmentItemList={assessmentItemList}
                progressItemsList={itemsProgress}
                subscribeToProgress={subscribeToProgress}
                key={idx}
              />
            )
          }
          </Grid>
      </Grid>
    </Paper>    
    </Page>
  )
}
import { memo, useEffect, useMemo, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { FactCheck } from '@mui/icons-material'
import EnvironmentalAndSafetyAssessmentForm from './EnvironmentalAndSafetyAssessmentForm'
import QualityAndFSAssessmentForm from './QualityAndFSAssessmentForm'
import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { QUALITY_FS, ENV_SAFETY, titles } from '../utils/constants'
import { getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { calculateProgress, loadAssessmentItems } from './context/AssessmentContext'
import qfsModels from './models/QualityAndFSModels'
import envModels from './models/EnvironmentalAndSafetyModels'
import { ASSESSMENT_TYPES, ASSESSMENT_TYPE_LABELS } from '../form/formUtils'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import EventBus from '../utils/EventBus'

let itemsList = []
let itemsProgress = []

export default function EditAssessmentPage (props = {}) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const params = useParams()
  const [type, setType] = useState(params.type)
  const [title, setTitle] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const [assessment, setAssessment] = useState()
  const [assessmentId, setAssessmentId] = useState(params.assessmentId)
  const [manufacturerProfileId, setManufacturerProfileId] = useState()
  const [progressAvailable, setProgressAvailable] = useState(false)
  const [activeCategoriesList, setActiveCategoriesList] = useState()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  
  const setItemsList = (_list) => {
    itemsList = _list
    const prog = [...qfsModels, ...envModels].reduce((p,m) => {
      const [a,b] = calculateProgress(m, _list)
      p.push(a, ...b)
      return p
    },[])
    itemsProgress = prog
    setProgressAvailable(true)
    publishProgress(prog)
  }
  const refreshItemList = async () => {
    const _itemsList = await loadAssessmentItems(assessment.id)
    setItemsList(_itemsList)
  }
  // const itemsProgressSubscribers = []
  // const subscribeToProgress = (fn) => {
  //   console.log('subscribing')
  //   itemsProgressSubscribers.push(fn)
  // }
  const publishProgress = (_itemsProgress) => {
    EventBus.emit('PROGRESS', _itemsProgress)
    // itemsProgressSubscribers.forEach((fn) => {
    //   fn(_itemsProgress)
    // })
  }

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
  }

  const onSave = () => {
    console.log("InternalAssessmentPage onSave")
    setTimeout(() => {
      setClean()
    }, 1000)
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleClose()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/my-assessments`)
    } else {
      navigate(-1)
    }
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const [defaultAssessmentType, setDefaultAssessmentType] = useState('')

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const getAssessment = async () => {
    startLoading()
    const token = await getToken()
    const requestConfig = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const basePath = `${ApiGateway.assessments}/${assessmentId}`
    const responses = await Promise.all([
      axios.get(basePath, requestConfig),
      loadAssessmentItems(assessmentId, requestConfig)
    ])
    const assessmentResponse = responses[0]
    const itemsResponse = responses[1]
    const result = assessmentResponse.data
    if (result) {
      setManufacturerProfileId(result.ManufacturerProfile.id)
      const _assessment = structuredClone(result)
      delete _assessment.ManufacturerProfile
      setAssessment(_assessment)
      setItemsList(itemsResponse)
    }
    stopLoading()
  }
  
  const breadcrumb = <div><span><FactCheck />&nbsp;{titles[type]}&nbsp;Assessment</span></div>

  const AssessmentForm = memo(function AssessmentForm({assessment}) {    
    if (params.type === QUALITY_FS)
    return (<QualityAndFSAssessmentForm 
      assessment={assessment} 
      refreshItemList={refreshItemList} 
      progressItemsList={itemsProgress}
      // subscribeToProgress={subscribeToProgress}
      activeCategoriesList={activeCategoriesList}
      setActiveCategoriesList={setActiveCategoriesList}
      defaultAssessmentType={defaultAssessmentType}
      />)
    if (params.type === ENV_SAFETY)
    return (<EnvironmentalAndSafetyAssessmentForm 
      assessment={assessment} 
      refreshItemList={refreshItemList}
      progressItemsList={itemsProgress}
      // subscribeToProgress={subscribeToProgress}
      activeCategoriesList={activeCategoriesList}
      setActiveCategoriesList={setActiveCategoriesList}
      defaultAssessmentType={defaultAssessmentType}
      />)
  })

  useEffect(() => {
    getAssessment(assessmentId)
  }, [assessmentId])

  const defItemAssessmentTypeControl = (
    <></>
  //   <FormControl size='small' style={{ verticalAlign: 'bottom'}}>
  //     { defaultAssessmentType === '' && <InputLabel>Default Item Assessment Type</InputLabel>}
  //     <Select size='small' sx={{minWidth: '250px'}} id={`default_assessmentType`}
  //       placeholder='Default Item Assessment Type'
        
  //       value={defaultAssessmentType}
  //       onChange={(event) => {
  //         setDefaultAssessmentType(event.target.value)
  //       }}>
  //       {Object.keys(ASSESSMENT_TYPES).map((aType, idx) => {
  //           return <MenuItem key={idx} value={ASSESSMENT_TYPES[aType]}>{ASSESSMENT_TYPE_LABELS[aType]}</MenuItem>
  //       })}
  //     </Select>
  // </FormControl>
  )

  return (
      <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
          <FormViewCommandBar leftJsx={defItemAssessmentTypeControl} onClose={confirmCancel} showCancel={false} showSave={false}  />}>
            { (assessment && progressAvailable) && 
              <AssessmentForm assessment={assessment} />
            }
        </Page>
  )
}
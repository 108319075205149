import React from 'react'
import { Skeleton, TextareaAutosize, Autocomplete, Card, Box, Checkbox, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'

export default function FormElement(props = {}) {
    if (!props.show) return <></>
    if (props.loading) {
        return <Grid {...props.gridItemProps} >
            <Skeleton
                variant="rounded"
                height={props.height || props.size === 'small' ? 36 : 54}
            />
        </Grid>
    }
    return <Grid {...props.gridItemProps} >
        <FormControl size={props.size || 'small'} fullWidth>
            {props.children}
        </FormControl>
    </Grid>
}
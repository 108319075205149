
export default function AssessmentGuidelines() {
  const content = `
  <style>
    .guildelines   { border: 2px solid silver; }
    .guidelines td { padding-left: 5px; padding-right: 5px; }
  </style>
  <table class="guidelines" cellspacing="0" style="width:100%;">
	<tbody>
		<tr>
			<td colspan="8" style="background-color:#c0504d; border-bottom:1px solid black; border-left:none; border-right:none; border-top:none; height:169px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:white"><strong><em><span style="font-family:Cambria,serif">The following are Guidelines and are Not all inclusive or a replacement for the professional judgment of a QSE Auditor.&nbsp;<br />
			If you see an activity, process, practice, or situation that is an immediate hazard to people, product, or the environment, the Auditor can shut down the process and escalate to Facility/Ownership and QSE leadership immediately.&nbsp; Examples would be:<br />
			&bull; Bypassed guards<br />
			&bull; Contamination of package or product<br />
			&bull; Leaks into storm drains<br />
			&bull; Speeding forklifts<br />
			&bull; Any nonconformance you are considering a high risk rating</span></em></strong></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fabf8f; border-bottom:none; border-left:none; border-right:none; border-top:1px solid black; height:24px; text-align:left; vertical-align:middle; white-space:normal"><span style="font-size:19px"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">A. Program Status: (A Non-Statistical Sampling Method will be used to assess the status of an area or program)</span></strong></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fde9d9; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:none; height:88px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:11pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Not Applicable (NA) </span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> Area or section does not apply to the operations being assessed or the area was not assessed at the time of the activity taking place.&nbsp;<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> Does not affect overall section score. No points will be gained or deducted for this area. (-0pts, +0pts)&nbsp;</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fde9d9; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:none; height:83px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Meets (M)</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> The program is in compliance or conforms to all CCNA, Regulatory, and Statutory requirements and specifications and/or the overall program meets the intent of the establish requirements.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will not issue a finding and facility will receive full points for the section/area being assessed (+30pts, -0pts)</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fde9d9; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:101px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Observation</span></u></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif"> (Obs)</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> An identified opportunity to improve a process or better meet a requirement.&nbsp; Areas of opportunity identified will have an insignificant impact to Quality, Food Safety, or the Environment, and is unlikely to damage Property, Equipment, or result in an injury<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will issue the finding as an Observation. Each observations will result in a 5 pt. deduction from the score. (+25pts, -5pts)&nbsp;</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fde9d9; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:77px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Partially Meets (PM)</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> Area is not in full compliance or does not conform to all CCNA, Regulatory, and Statutory requirements and specifications and/or partially meets the intent of the established requirements.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will use the Rating Findings guide (Section B) and Risk Matrix [Table&nbsp; I] below to assess and determine rating for the findings identified. Each section identified as a PM will results in a 15pt deduction from the score. (+15pts, -15pts)</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#fde9d9; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:127px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Does Not Meet (DM)</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> Area is not in compliance or does not conform to all CCNA, Regulatory, and Statutory requirements and specifications and/or does not&nbsp; meet the full intent of the established requirements. Findings can have a significant impact to Quality, Food Safety, or the Environment, and is likely to damage Property, Equipment, or result in an injury.&nbsp;<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will use the Rating Findings guide (Section B) and Risk Matrix [Table&nbsp; I] below to assess and determine rating for the findings identified. Each section identified as a DM will results in a 30pt deduction (full point deduction) from the score. (+0pts, -30pts)</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#92cddc; border-bottom:none; border-left:none; border-right:none; border-top:1px solid black; height:35px; text-align:left; vertical-align:middle; white-space:normal"><span style="font-size:19px"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">B. Rating Findings:&nbsp;</span></strong></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#daeef3; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:none; height:137px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Observations</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> An identified opportunity to improve a process or better meet a requirement, that will have an insignificant impact to Quality, Food Safety, or the Environment, and is unlikely to damage Property, Equipment, or result in an injury.&nbsp;<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Triggers:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> See Risk tool [Table I] for triggers leading to a finding being rated as a nonconformance.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will issue the finding as an observation.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Cadences:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> The facility is expected to provide corrections for the IO but is not expected to submit a formal RCA/CAP.</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#daeef3; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:137px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:15px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Nonconformance</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> An identified nonconformance to KORE, NA, Federal or State Regulatory Requirements that will have an minor impact to Quality, Food Safety,&nbsp; the Environment, or slight damage Property, Equipment, or may result in a minor injury.&nbsp;<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Triggers:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> See Risk tool [Table I] for triggers leading to a finding being rated as a nonconformance.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will issue the finding as a nonconformance.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Cadences:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> The facility is expected to submit a RCA/CAP within 30 days of the audit and at least quarterly thereafter until the finding is closed.&nbsp; Failure to take action or close the finding in a reasonable timeframe may result in a repeat or conditional nonconformance and impact the authorization status.&nbsp;&nbsp;</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#daeef3; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:136px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:16px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">Conditional Nonconformance</span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> An identified nonconformance to KORE, NA, Federal or State Regulatory Requirements that is likely to result in a moderate to major impact to Quality, Food Safety, or the Environment, or likely to result in a moderate injury, and moderate to major loss of process and damage to property.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Triggers:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> See Risk tool [Table I] for triggers leading to a finding being rated as a conditional nonconformance.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will issue the finding as a conditional nonconformance.&nbsp; The QSE Auditor may consult with peers to calibrate on the finding.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Cadences:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> The facility is expected to submit a RCA/CAP within 30 days of the audit and at least quarterly thereafter until the finding is closed.&nbsp; Failure to take action or close the finding in a reasonable timeframe may result in a repeat or high risk nonconformance and impact the authorization status with an escalation to leadership.</span></span></span></span></span></span></td>
		</tr>
		<tr>
			<td colspan="8" style="background-color:#daeef3; border-bottom:1px solid gray; border-left:none; border-right:none; border-top:1px solid gray; height:160px; text-align:left; vertical-align:middle; white-space:normal; width:100%"><span style="font-size:16px"><span style="color:#215967"><span style="font-family:Cambria,serif"><span style="font-size:12pt"><span style="color:#215967"><strong><u><span style="font-family:Cambria,serif">High Risk / Critical Risk Nonconformance </span></u></strong></span></span><br />
			<span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif">&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Definition:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> An identified nonconformance to KORE, NA, Federal or State Regulatory Requirements that is likely to result in high or severe impact to Quality, Food Safety, and the Environment, likely to result in a major injury or death, and major to catastrophic loss of process and damage to property.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Triggers:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> See Risk tool [Table I] for triggers leading to a finding being rated as a high risk nonconformance.<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Protocols:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> QSE Auditor will consult with peers and QSE leadership to calibrate on the finding prior to issuing the rating to the facility.&nbsp; Immediate actions may include shut down of the process or operation.&nbsp; A Top to Top will be held with the Ownership and Facility Leadership Immediately.&nbsp;<br />
			&bull; </span></span></span><span style="font-size:11pt"><span style="color:#215967"><strong><span style="font-family:Cambria,serif">Cadences:</span></strong></span></span><span style="font-size:11pt"><span style="color:#215967"><span style="font-family:Cambria,serif"> Facility will be expected to submit a RCA in 7 days and a CAP in 14 days.&nbsp; Status of the finding will be tracked at least monthly until closed.&nbsp; Failure to take immediate action or close the finding in a reasonable time frame may result in limited or loss of production authorization.</span></span></span></span></span></span></td>
		</tr>
	</tbody>
</table>
  `
  return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
  )
}
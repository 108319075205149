import { Typography } from "@mui/material"
import { Grid, Skeleton } from '@mui/material'
export const LetterTypes = {
  'NEW': 'NEW',
  'REVIEW': 'REVIEW',
  'REVISION': 'REVISION',
  'EXISTING': 'EXISTING',
}

export const approvalStatuses = {
  FULL: 'FULL',
  CONDITIONAL: 'CONDITIONAL',
  PROVISIONAL: 'PROVISIONAL',
  DEAUTHORIZED: 'DEAUTHORIZED',
}

export const WorkflowStatuses = {
  'PUBLISHED': 'PUBLISHED',
  'DRAFT': 'DRAFT',
}

export const getLastPublishedLabel = (_date, authorization) => {
  let type = ''
  if (authorization.letterType === LetterTypes.NEW && authorization.issuedDate) type = ' Issued'
  if (authorization.letterType === LetterTypes.NEW && !authorization.issuedDate) type = ''
  if (authorization.letterType === LetterTypes.REVISION) type = ` Revision ${authorization.revisionNumber}`
  if (authorization.letterType === LetterTypes.REVIEW) type = ' Reviewed'
  return `${toFormattedDate(_date)}${type}`
}

export const SIGNATURE_ATTESTATION = `By typing your name and clicking "SIGN", you agree to adopt this signature and digitally sign this Facility Authorization letter.`

export function DataItem(props = {}) {
  const { label, value, layout = 'vertical', loading, fontWeight, sx = {} } = props
  if (layout === 'horizontal') {
    return <Grid container columnGap={0} rowGap={0} spacing={0} sx={sx}>
      <Grid item xs={6} sx={{
        textAlign: 'right',
        paddingRight: '18px'
      }}>
        <Typography fontWeight={fontWeight || 'regular'} color="text.secondary">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        {loading && <Skeleton variant="rounded" fullWidth />}
        {!loading && <Typography fontWeight='regular'> {value}</Typography>}
      </Grid>
    </Grid >
  }
  if (layout === 'vertical') {
    return <Grid sx={{ marginLeft: '2px', ...sx }} container columnGap={1} rowGap={0} spacing={1}>
      <Grid item xs={12}>
        <Typography fontWeight='bold' color="text.secondary">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        {loading && <Skeleton fullWidth sx={{ height: '30px' }} />}
        {!loading && <Typography fontWeight='regular'> {value}</Typography>}
      </Grid>
    </Grid>
  }
}

export const HIGHLIGHT_HEX = '#ffff79'

export const toFormattedDate = (date) => {
  const d = new Date(date)
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  options.timeZone = "UTC";
  return d.toLocaleDateString('en-US', options);
};
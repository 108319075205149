import { default as React, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Science } from '@mui/icons-material'
import ListAssessments from './ListCPKSubmissions'
import RoleContext from '../auth/RoleContext'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'

export default function ListCPKSubmissionsPage (props = {}) {
  const params = useParams()
  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [manufacturerId, setManufacturerId] = useState(params.manufacturerId || 0)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }
  
  const breadcrumb = <span><Science/>Process Capability Submissions</span>

  const [showFiltersButton, setShowFiltersButton] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAdd, setShowAdd] = useState(true)

  const addRecord = () => {
    navigate(`/process-capability-submissions/create${mfrProfileId ? '/'+mfrProfileId : ''}`)
  }

  const showAddButton = () => {
    setShowAdd(true)
  }
  const hideAddButton = () => {
    setShowAdd(false)
  }
  
  return (
    <RoleContext.Consumer>
    {roleCtx =>
       <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <ListViewCommandBar
          loading={isLoading}
          helpPage="/process-capability-submissions/help"
          helpPageState={{
            backTo: '/process-capability-submissions/list',
          }}
          showFilters={false}
          toggleFilters={toggleFilters}
          addRecord={addRecord}
          showAdd={roleCtx.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR, ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && showAdd}/>
        }>
        {/* <Button variant="contained" onClick={sendMessage}>Alert</Button> */}
        <ListAssessments 
          showFilters={showFilters} 
          showAddRecord={showAddButton} 
          startLoading={startLoading} 
          stopLoading={stopLoading} 
          hideAddRecord={hideAddButton} 
          mfrProfileId={mfrProfileId}
          setMfrProfileId={setMfrProfileId}
          />
      </Page>
      }
    </RoleContext.Consumer>
  )
}

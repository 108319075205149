import { useState, Fragment } from 'react'
import Grid from '@mui/material/Unstable_Grid2'

function Bar(props={}) {

    const [showLabel, setShowLabel] = useState(props.label !== null && props.label !== undefined && props.label.length)

    let color = props.color || '#333'
    let width = props.value || 0
    width = Math.round(width * 100) / 100
    width = width + '%'
    let label = `${props.label}, ${width}`
    let style = { width }
    if(props.bgColor) {
        style.backgroundColor = props.bgColor
    }
    return (
        <Grid container>
            {showLabel && 
                <Fragment>
                    <Grid xs={12} md={6}>
                        <div className="tis-bar-label" style={{color}}>{label}</div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={`tis-bar ${props.className}`}>
                            <div className="tis-inner-bar" style={style}/>
                        </div>
                    </Grid>
                </Fragment>}
            {!showLabel &&               
                <Grid xs={12} md={12}>  
                    <div className={`tis-bar ${props.className}`}>
                        <div className="tis-inner-bar" style={style}/>
                    </div>
                </Grid>
            }
        </Grid>
    )
}
export default Bar
export default function Sidebar (props = {}) {
  
  return (
    <aside className="main-sidebar">
      <div className="sidebar">
        <props.menu hasRoleName={props.hasRoleName} />
      </div>
    </aside>
  )
}
